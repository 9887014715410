import { Button, Card, Tab, Table } from "react-bootstrap";
import React from "react";
import { Component } from "react";
import { Accordion, Col, Row, Tabs } from "react-bootstrap";
import {
  getProvisionLevel,
  patientGetSurveyTimeWindows,
  getCurrentSurveyTimeWindow,
  getSurvey,
  getPatientReports,
} from "../../services/getFunctions";
import NavigationBar from "../navigation/NavigationBar";
import SidebarPatient from "../sidebars/SidebarPatient";
import ErrorBoundary from "../../ErrorBoundary";
import Footer from "../Footer";
import { FiDownload } from "react-icons/fi";
import TimeoutSession from "../../helper/TimeoutSession";
import { reportGetProvisionLevelError } from "../../helper/alertBoxes";
import {
  reportGetStwsError,
  reportGetPatientSurveyItemsError,
  reportGetPatientReportsError,
} from "../../helper/alertBoxes";

/**
 * Dashboard component of patient.
 */
class HomePatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "patient",
      provisionLevel: "",
      content: "",
      stws: [],
      items: [], //for legacy reasons this does currently not contain the items but the whole survey! Needs a refactor
      reports: [],
      currentSTW: "",
      today: new Date().toISOString().split("T")[0],
      clinic_id: "",
      clinic_name: "",
      username: "",
      accordionCurrent: "0",
      accordionInformation: "-1",
      accordionSurvey: "-1",
      accordionReport: "-1",
      cntStws: [],
      modalOpen: false,
      remainingTime: 0,
    };
    this.openSurvey = this.openSurvey.bind(this);
  }

  /**Method for loading the stws and the current stw from backend API. */
  componentDidMount() {
    console.log("Prov: ", this.props.location.state);
    if (this.props.location.state === undefined) {
      ErrorBoundary.getDerivedStateFromError(Error);
    } else {
      this.setState({
        clinic_id: this.props.location.state.clinic_id,
        clinic_name: this.props.location.state.clinic_name,
        username: this.props.location.state.username,
        provisionLevel: this.props.location.state.provisionLevel,
      });
      if (this.props.location.state.accordionCurrent) {
        this.setState({
          accordionCurrent: this.props.location.state.accordionCurrent,
          accordionInformation: this.props.location.state.accordionInformation,
          accordionReport: this.props.location.state.accordionReport,
          accordionSurvey: this.props.location.state.accordionSurvey,
        });
      }
    }

    if (this.state.provisionLevel === "") {
      try {
        getProvisionLevel()
          .then((provisionLevel) => {
            console.info("Provision-Level: ", provisionLevel);
            this.setState({ provisionLevel: provisionLevel });
          })
          .catch((error) => {
            reportGetProvisionLevelError(error);
          });
      } catch (error) {}
    }

    try {
      patientGetSurveyTimeWindows()
        .then((surveyTimeWindows) => {
          console.log("STWs: ", surveyTimeWindows.stws);
          this.setState({ stws: surveyTimeWindows.stws });
          this.cntStws();
        })
        .catch((error) => {
          reportGetStwsError(error);
        });
    } catch (error) {}
    try {
      getCurrentSurveyTimeWindow()
        .then((surveyTimeWindow) => {
          console.log("Aktuelle STW: ", surveyTimeWindow);
          this.setState({ currentSTW: surveyTimeWindow });
        })
        .catch((error) => {
          reportGetStwsError(error);
        });
    } catch (error) {}
    try {
      getSurvey()
        .then((surveyResponse) => {
          console.log("Aktuelle Items: ", surveyResponse.survey);
          this.setState({ items: surveyResponse.survey });
        })
        .catch((error) => {
          reportGetPatientSurveyItemsError(error);
        });
    } catch (error) {}

    try {
      getPatientReports()
        .then((reports) => {
          console.log("Reports List: ", reports.report_entries);
          this.setState({ reports: reports.report_entries });
        })
        .catch((error) => {
          reportGetPatientReportsError(error);
        });
    } catch (error) {}
  }

  cntStws() {
    var current = 0;
    var completed = 0;
    var planned = 0;
    let stws = [...this.state.stws];

    for (var i = 0; i < this.state.stws.length; i++) {
      var start = this.state.stws[i].start;
      var end = this.state.stws[i].end;
      var start_date = new Date(start).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      var end_date = new Date(end).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      console.log(
        "Start: ",
        new Date(start).toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        "Heute: ",
        this.state.today
      );

      stws[i] = {
        ...this.state.stws[i],
        start_show: start_date,
        end_show: end_date,
      };
    }
    this.setState({ stws: stws });

    for (var j = 0; j < this.state.stws.length; j++) {
      if (
        this.state.stws[j].start <= this.state.today &&
        this.state.stws[j].end >= this.state.today
      ) {
        current++;
      } else if (this.state.stws[j].end < this.state.today) {
        completed++;
      } else if (this.state.stws[j].start > this.state.today) {
        planned++;
      }
    }
    this.setState({
      cntStws: { current: current, completed: completed, planned: planned },
    });
    console.log("Anzahl STWS: ", this.state.cntStws);
    console.log("Neue STWS: ", this.state.stws);
  }

  /**Open patients survey. */
  openSurvey = () => {
    this.props.history.push({
      pathname: "/patients/survey",
      state: {
        clinic_id: this.state.value,
        clinic_name: this.state.clinic_name,
        username: this.state.username,
      },
    });
  };

  /**Open patients report. */
  openReport = (event) => {
    event.preventDefault();
    console.log("Event: ", event.target.parentNode.parentElement.id);
    var index = event.target.parentNode.parentElement.id;
    var started_time = this.state.reports[index].started_time;
    var started_time_date = started_time.split("T");
    var started_time_split = started_time_date[0].split("-");
    var started_time_format =
      started_time_split[2] +
      "." +
      started_time_split[1] +
      "." +
      started_time_split[0];
    console.log("Datum: ", started_time_format);
    this.props.history.push({
      pathname: "/patients/report",
      state: {
        clinic_id: this.state.value,
        clinic_name: this.state.clinic_name,
        username: this.state.username,
        survey_session_id: this.state.reports[index].survey_session_id,
        // birthday: birthday,
        // gender: gender,
        stw_type: this.state.reports[index].stw_type,
        started_time: started_time_format,
        provisionLevel: this.state.provisionLevel,
        user_type: this.state.user_type,
      },
    });
  };

  handleAccordion = (input) => {
    if (input === "current") {
      this.state.accordionCurrent === "-1"
        ? this.setState({ accordionCurrent: "0" })
        : this.setState({ accordionCurrent: "-1" });
      this.setState({
        accordionInformation: "-1",
        accordionSurvey: "-1",
        accordionReport: "-1",
      });
    } else if (input === "info") {
      this.state.accordionInformation === "-1"
        ? this.setState({ accordionInformation: "1" })
        : this.setState({ accordionInformation: "-1" });
      this.setState({
        accordionCurrent: "-1",
        accordionSurvey: "-1",
        accordionReport: "-1",
      });
    } else if (input === "survey") {
      this.state.accordionSurvey === "-1"
        ? this.setState({ accordionSurvey: "2" })
        : this.setState({ accordionSurvey: "-1" });
      this.setState({
        accordionCurrent: "-1",
        accordionInformation: "-1",
        accordionReport: "-1",
      });
    } else if (input === "report") {
      this.state.accordionReport === "-1"
        ? this.setState({ accordionReport: "3" })
        : this.setState({ accordionReport: "-1" });
      this.setState({
        accordionCurrent: "-1",
        accordionInformation: "-1",
        accordionSurvey: "-1",
      });
    }
  };

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <NavigationBar>
          {this.state.clinic_id}
          {this.state.clinic_name}
          {this.state.username}
          {this.state.user_type}
          {this.state.provisionLevel}
        </NavigationBar>
        <div className="page-wrapper-new">
          <div className="wrapper">
            <SidebarPatient
              handleAccordion={this.handleAccordion}
              state={this.state}
            />
            <div className="content-wrapper">
              <div className="content">
                <Row>
                  <Col>
                    <Accordion
                      defaultActiveKey="0"
                      activeKey={this.state.accordionCurrent}
                      className="card-default"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header
                          onClick={() => this.handleAccordion("current")}
                        >
                          Aktuelles
                        </Accordion.Header>
                        <Accordion.Body>
                          {this.state.cntStws.current === 0
                            ? "Keine Befragung ausstehend. Die bisherigen Befragungen, abgelaufenen Befragungen und geplanten Befragungen werden Ihnen unter Befragungen angezeigt."
                            : this.state.stws &&
                              this.state.stws.length > 0 &&
                              this.state.stws.map((stw, index) => {
                                return stw.start <= this.state.today &&
                                  stw.end >= this.state.today ? (
                                  <>
                                    {this.state.items ? (
                                      <Card
                                        key={stw.type}
                                        border="primary"
                                        // style={{ width: "19rem" }}
                                      >
                                        <Card.Body>
                                          {(() => {
                                            switch (stw.type) {
                                              case "PRE":
                                                return (
                                                  <Card.Title>
                                                    Aktuelle Befragung: Aufnahme
                                                  </Card.Title>
                                                );
                                              case "POST":
                                                return (
                                                  <Card.Title>
                                                    Aktuelle Befragung:
                                                    Entlassung
                                                  </Card.Title>
                                                );
                                              case "FOLLOW_UP_1":
                                                return (
                                                  <Card.Title>
                                                    Aktuelle Befragung:
                                                    Nachsorge 6 Monate
                                                  </Card.Title>
                                                );
                                              case "FOLLOW_UP_2":
                                                return (
                                                  <Card.Title>
                                                    Aktuelle Befragung:
                                                    Nachsorge 12 Monate
                                                  </Card.Title>
                                                );

                                              default:
                                                return null;
                                            }
                                          })()}
                                          <Card.Text>
                                            Die Befragung muss im Zeitraum von{" "}
                                            <b>{stw.start_show}</b> bis{" "}
                                            <b>{stw.end_show}</b> ausgefüllt
                                            werden.
                                          </Card.Text>
                                          {this.state.items ? (
                                            <Button onClick={this.openSurvey}>
                                              Befragung öffnen
                                            </Button>
                                          ) : (
                                            <Button disabled>
                                              Befragung abgeschlossen
                                            </Button>
                                          )}
                                        </Card.Body>
                                      </Card>
                                    ) : (
                                      "Die aktuelle Befragung wurde bereits ausgefüllt."
                                    )}
                                  </>
                                ) : null;
                              })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col className="col-8">
                    <Accordion
                      activeKey={this.state.accordionInformation}
                      className="card-default"
                    >
                      <Accordion.Item eventKey="1">
                        <Accordion.Header
                          onClick={() => this.handleAccordion("info")}
                        >
                          Profil
                        </Accordion.Header>
                        <Accordion.Body>
                          <Table>
                            <tbody>
                              <tr>
                                <td className="table-without-top">Reha-ID</td>
                                <td className="table-without-top">
                                  {this.state.username}
                                </td>
                              </tr>
                              <tr>
                                <td className="table-without-bottom">Klinik</td>
                                <td className="table-without-bottom">
                                  {this.state.clinic_name}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
                <Accordion
                  activeKey={this.state.accordionSurvey}
                  className="card-default"
                >
                  <Accordion.Item eventKey="2">
                    <Accordion.Header
                      onClick={() => this.handleAccordion("survey")}
                    >
                      Befragungen
                    </Accordion.Header>
                    <Accordion.Body>
                      <Tabs defaultActiveKey="current" id="" className="mb-3">
                        <Tab eventKey="current" title="Aktuelle Befragung">
                          <Row xs={1} md={2} lg={3}>
                            {this.state.cntStws.current !== 0
                              ? this.state.stws &&
                                this.state.stws.length > 0 &&
                                this.state.stws.map((stw, index) => {
                                  return stw.start <= this.state.today &&
                                    stw.end >= this.state.today ? (
                                    <Card
                                      key={index}
                                      border="primary"
                                      style={{ width: "19rem" }}
                                    >
                                      <Card.Body>
                                        {(() => {
                                          switch (stw.type) {
                                            case "PRE":
                                              return (
                                                <Card.Title>
                                                  Aktuelle Befragung: Aufnahme
                                                </Card.Title>
                                              );
                                            case "POST":
                                              return (
                                                <Card.Title>
                                                  Aktuelle Befragung: Entlassung
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_1":
                                              return (
                                                <Card.Title>
                                                  Aktuelle Befragung: Nachsorge
                                                  6 Monate
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_2":
                                              return (
                                                <Card.Title>
                                                  Aktuelle Befragung: Nachsorge
                                                  12 Monate
                                                </Card.Title>
                                              );

                                            default:
                                              return null;
                                          }
                                        })()}
                                        <Card.Text>
                                          Die Befragung muss im Zeitraum von{" "}
                                          <b>{stw.start_show}</b> bis{" "}
                                          <b>{stw.end_show}</b> ausgefüllt
                                          werden.
                                        </Card.Text>
                                        {this.state.items ? (
                                          <Button onClick={this.openSurvey}>
                                            Befragung öffnen
                                          </Button>
                                        ) : (
                                          <Button disabled>
                                            Befragung abgeschlossen
                                          </Button>
                                        )}
                                      </Card.Body>
                                    </Card>
                                  ) : null;
                                })
                              : "Es gibt momentan keine aktuelle Befragung."}{" "}
                          </Row>
                        </Tab>

                        <Tab
                          eventKey="completed"
                          title="Abgelaufene Befragungen"
                        >
                          <Row xs={1} md={2} lg={3}>
                            {this.state.cntStws.completed !== 0
                              ? this.state.stws &&
                                this.state.stws.length > 0 &&
                                this.state.stws.map((stw, index) => {
                                  return stw.end < this.state.today ? (
                                    <Card
                                      key={index}
                                      border="primary"
                                      style={{ width: "19rem" }}
                                    >
                                      <Card.Body>
                                        {(() => {
                                          switch (stw.type) {
                                            case "PRE":
                                              return (
                                                <Card.Title>
                                                  Aufnahme
                                                </Card.Title>
                                              );
                                            case "POST":
                                              return (
                                                <Card.Title>
                                                  Entlassung
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_1":
                                              return (
                                                <Card.Title>
                                                  Nachsorge 6 Monate
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_2":
                                              return (
                                                <Card.Title>
                                                  Nachsorge 12 Monate
                                                </Card.Title>
                                              );

                                            default:
                                              return null;
                                          }
                                        })()}
                                        <Card.Text>
                                          Die Befragung war im Zeitraum vom{" "}
                                          <b>{stw.start_show}</b> bis{" "}
                                          <b>{stw.end_show}</b> verfügbar.
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  ) : null;
                                })
                              : "Es gibt momentan keine abgelaufenen Befragungen."}{" "}
                          </Row>
                        </Tab>
                        <Tab eventKey="planned" title="Geplante Befragungen">
                          <Row xs={1} md={2} lg={3}>
                            {this.state.cntStws.planned !== 0
                              ? this.state.stws &&
                                this.state.stws.length > 0 &&
                                this.state.stws.map((stw, index) => {
                                  return stw.start > this.state.today ? (
                                    <Card
                                      key={index}
                                      border="primary"
                                      style={{
                                        width: "19rem",
                                      }}
                                    >
                                      <Card.Body>
                                        {(() => {
                                          switch (stw.type) {
                                            case "PRE":
                                              return (
                                                <Card.Title>
                                                  Aufnahme
                                                </Card.Title>
                                              );
                                            case "POST":
                                              return (
                                                <Card.Title>
                                                  Entlassung
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_1":
                                              return (
                                                <Card.Title>
                                                  Nachsorge 6 Monate
                                                </Card.Title>
                                              );
                                            case "FOLLOW_UP_2":
                                              return (
                                                <Card.Title>
                                                  Nachsorge 12 Monate
                                                </Card.Title>
                                              );

                                            default:
                                              return null;
                                          }
                                        })()}
                                        <Card.Text>
                                          Die Befragung wird im Zeitraum von{" "}
                                          <b>{stw.start_show}</b> bis{" "}
                                          <b>{stw.end_show}</b> aktiv sein.
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  ) : null;
                                })
                              : "Es gibt momentan keine geplanten Befragungen."}
                          </Row>
                        </Tab>
                      </Tabs>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {this.state.provisionLevel === "PLUS" ? (
                  <Accordion
                    className="card-default"
                    activeKey={this.state.accordionReport}
                  >
                    <Accordion.Item eventKey="3">
                      <Accordion.Header
                        onClick={() => this.handleAccordion("report")}
                      >
                        Berichte
                      </Accordion.Header>
                      <Accordion.Body>
                        {this.state.reports.length === 0
                          ? "Momentan sind keine Berichte verfügbar."
                          : null}
                        {this.state.reports &&
                          this.state.reports.length > 0 &&
                          this.state.reports.map((report, index) => {
                            return report !== null ? (
                              <Card
                                key={index}
                                id={index}
                                border="primary"
                                style={{
                                  width: "19rem",
                                }}
                              >
                                <Card.Body>
                                  {(() => {
                                    switch (report.stw_type) {
                                      case "FOLLOW_UP_1":
                                        return (
                                          <Card.Title>
                                            Bericht: Nachsorge 6 Monate
                                          </Card.Title>
                                        );
                                      case "FOLLOW_UP_2":
                                        return (
                                          <Card.Title>
                                            Bericht: Nachsorge 12 Monate
                                          </Card.Title>
                                        );

                                      default:
                                        return null;
                                    }
                                  })()}
                                  <Card.Text>
                                    Sie können Ihren Bericht hier einsehen.
                                  </Card.Text>
                                  <Button onClick={this.openReport}>
                                    Bericht öffnen
                                  </Button>
                                </Card.Body>
                              </Card>
                            ) : null;
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ) : null}
                <Row>
                  {this.state.provisionLevel === "PLUS" ? (
                    <Card className="card-default card-custom">
                      <Card.Body>
                        <Card.Title>Anleitung</Card.Title>
                        <Card.Text>
                          Hier finden Sie eine Anleitung zur Benutzung der
                          RehaCAT-Plattform.
                        </Card.Text>

                        <a
                          href={`${process.env.PUBLIC_URL}/files/RehaCAT+_Anleitung.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="primary">
                            <FiDownload /> Anleitung herunterladen
                          </Button>
                        </a>
                      </Card.Body>
                    </Card>
                  ) : (
                    <Card className="card-default card-custom">
                      <Card.Body>
                        <Card.Title>Anleitung</Card.Title>
                        <Card.Text>
                          Hier finden Sie eine Anleitung zur Benutzung der
                          RehaCAT-Plattform.
                        </Card.Text>

                        <a
                          href={`${process.env.PUBLIC_URL}/files/RehaCAT_Anleitung.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="primary">
                            <FiDownload /> Anleitung herunterladen
                          </Button>
                        </a>
                      </Card.Body>
                    </Card>
                  )}
                  {this.state.provisionLevel === "PLUS" ? (
                    <Card className="card-default card-custom">
                      <Card.Body>
                        <Card.Title>
                          Hilfreiche Anlaufstellen bei psychischer Belastung
                        </Card.Title>
                        <Card.Text>
                          Hier finden Sie eine Sammlung an hilfreichen
                          Anlaufstellen bis hin zu Selbsthilfeangeboten.
                        </Card.Text>

                        <a
                          href={`${process.env.PUBLIC_URL}/files/Hilfreiche_Anlaufstellen.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="primary">
                            <FiDownload /> Liste herunterladen
                          </Button>
                        </a>
                      </Card.Body>
                    </Card>
                  ) : null}{" "}
                </Row>
              </div>
            </div>{" "}
            <Footer>
              {this.state.clinic_id}
              {this.state.clinic_name}
              {this.state.username}
              {this.state.user_type}
              {this.state.provisionLevel}
            </Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePatient;
