import { PubSub } from "./pubSub";
/**
 * Shows an error Modal. The title and closeButtonText are optional. If not provided, default values are used.
 * @param {*} text
 * @param {*} title
 * @param {*} closeButtonText
 */
export const showErrorModal = (text, title, closeButtonText) => {
  title = title || "Fehler";
  closeButtonText = closeButtonText || "Schließen";

  const pubSub = PubSub.getInstance();

  pubSub.publishToTopic("alert", {
    title,
    text,
    closeButtonText,
    show: true,
  });
};

export const showAlertModal = (text, title, closeButtonText) => {
  title = title || "Hinweis";
  closeButtonText = closeButtonText || "Schließen";

  const pubSub = PubSub.getInstance();

  pubSub.publishToTopic("alert", {
    title,
    text,
    closeButtonText,
    show: true,
  });
};

/**
 * Reports a generic error. This function is primarily to reduce code duplication
 * @param {*} error
 * @param {*} attemptedAction
 */
export const reportGenericError = (error, attemptedAction) => {
  if (error.message === "Network Error") {
    showErrorModal(
      "Beim " +
        attemptedAction +
        " ist ein Netzwerkfehler aufgetreten. Entweder Sie haben keine Internetverbindung oder der Server ist nicht erreichbar."
    );
  } else if (!error.response) {
    showErrorModal(
      "Beim " +
        attemptedAction +
        " ist ein unbekannter Fehler aufgetreten. Es ist kein HTTP Statuscode vorhanden."
    );
  } else if (error.response.status === 401) {
    //unauthorized
    showErrorModal(
      "Beim " +
        attemptedAction +
        " ist ein Fehler aufgetreten: Sie sind nicht (mehr) eingeloggt. Bitte loggen Sie sich erneut ein."
    );
  } else if (error.response.status === 500) {
    //internal server error
    showErrorModal(
      "Beim " +
        attemptedAction +
        " ist ein Fehler aufgetreten: Interner Server Fehler"
    );
  } else {
    showErrorModal(
      "Beim " +
        attemptedAction +
        " ist ein unbekannter Fehler aufgetreten. HTTP Statuscode: " +
        error.response.status
    );
  }
};

/**
 * Reports an error which occured when loading the provision level (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetProvisionLevelError = (error) => {
  showErrorModal(
    "Etwas ist schief gelaufen. Sie können die Seite normal nutzen, ggf. werden jedoch einige Funktionen bis zum Neuladen der Seite nicht verfügbar sein. "
  );
  console.warn("Provision-Level Fail: ", error);
};

/**
 * Reports an error which occured when loading the patient list (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetPatientListError = (error) => {
  reportGenericError(error, "Abrufen der Patientenliste");
  console.error("Patientenliste Fail: ", error);
};

/**
 * Reports an error which occured when loading the clinician list (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetClinicianListError = (error) => {
  reportGenericError(error, "Abrufen der Clinician Liste");
  console.error("Clinician list Fail: ", error);
};

/**
 * Reports an error which occured when loading the diagnoses list (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetDiagnosesListError = (error) => {
  reportGenericError(error, "Abrufen der Diagnosen Liste");
  console.error("Diagnoses list Fail: ", error);
};

/**
 * Reports an error which occured when setting a new password (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportNewPasswordError = (error) => {
  reportGenericError(error, "Setzen eines neuen Passworts");
  console.error("New password fail: ", error);
};

/**
 * Reports an error which occured when enabling/disabling a clinician (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportDisableEnableClinicianError = (error) => {
  reportGenericError(error, "Aktivieren/Deaktivieren des Clinician");
  console.error("New password fail: ", error);
};

/**
 * Reports an error which occured when getting the clinician reports (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetClinicianReportsError = (error) => {
  reportGenericError(error, "Laden der Berichte");
  console.error("Reports fail: ", error);
};

/**
 * Reports an error which occured when setting a new password (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetClinicListError = (error) => {
  reportGenericError(error, "Laden der Klinik Liste");
  console.error("Klinikliste fail: ", error);
};

/**
 * Reports an error which occured when loading the survey session report (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetSurveySessionReportError = (error) => {
  reportGenericError(error, "Laden der Befragungssitzung");
  console.error("Report fail: ", error);
};

/**
 * Reports an error which occured when loading stw (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetStwsError = (error) => {
  reportGenericError(error, "Laden der Survey Time Windows");
  console.error("STWS fail: ", error);
};

/**
 * Reports an error which occured when the survey session report (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetPatientSurveyItemsError = (error) => {
  reportGenericError(error, "Laden der Umfrageelemente");
  console.error("Get Patient survey items fail: ", error);
};

/**
 * Reports an error which occured when loading the patient reports (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetPatientReportsError = (error) => {
  reportGenericError(error, "Laden der Patientenberichte");
  console.error("Get Patient reports fail: ", error);
};

/**
 * Reports an error which occured when loading survey (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportGetSurveyError = (error) => {
  reportGenericError(error, "Laden der Umfrage");
  console.error("Get Patient reports fail: ", error);
};

/**
 * Reports an error which occured when registering (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportRegistPatientError = (error) => {
  const attemptedAction = "Registrieren";
  if (error.response && error.response.status === 409) {
    showErrorModal(
      "Beim " +
        attemptedAction +
        " ist ein Fehler aufgetreten: Der Patientencode ist schon vergeben"
    );
  } else {
    reportGenericError(error, attemptedAction);
  }
  console.error("Regist fail: ", error);
};

/**
 * Reports an error which occured when registering (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportRegistClinicianError = (error) => {
  const attemptedAction = "Registrieren";
  if (error.response && error.response.status === 409) {
    showErrorModal(
      "Beim " +
        attemptedAction +
        " ist ein Fehler aufgetreten: Die Mitarbeitenden-ID ist schon vergeben"
    );
  } else {
    reportGenericError(error, attemptedAction);
  }
  console.error("Regist fail: ", error);
};

/**
 * Reports an error which occured when registering an admin (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportRegistAdminError = (error) => {
  const attemptedAction = "Admin Registrieren ";
  if (error.response && error.response.status === 409) {
    showErrorModal(
      "Beim " +
        attemptedAction +
        " ist ein Fehler aufgetreten: In dieser Klinik existiert bereits ein Admin"
    );
  } else {
    reportGenericError(error, attemptedAction);
  }
  console.error("Regist admin fail: ", error);
};

/**
 * Reports an error which occured when transfering a patient (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportTransferPatientError = (error) => {
  if (error.response?.status === 403) {
    showErrorModal("Error 403 ");
  } else {
    reportGenericError(error, "Transferieren des Patienten");
  }
  console.error("Transfer patient fail: ", error);
};

/**
 * Reports an error which occured when editing a clinician (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportEditClinicianError = (error) => {
  if (error.response?.status === 409) {
    showErrorModal("Der Benutzername ist bereits vergeben. ");
  } else {
    reportGenericError(error, "Editieren des Clinician");
  }
  console.error("Edit Clinician fail: ", error);
};

/**
 * Reports an error which occured when assigning a clinician (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportClinicianAssignError = (error) => {
  reportGenericError(error, "Zuweisen");
  console.error("Assign Clinician fail: ", error);
};

/**
 * Reports an error which occured when editing a patient (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportEditPatientError = (error) => {
  reportGenericError(error, "Editieren des Clinician");
  console.error("Edit Patient fail: ", error);
};

/**
 * Reports an error which occured when submitting the survey (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportSubmitSurveyError = (error) => {
  reportGenericError(error, "Übermitteln der Umfrage");
  console.error("Submit survey fail: ", error);
};

/**
 * Reports an error which occured when validating the csv (shows an alert box and logs the error to console)
 * @param {*} error
 */
export const reportCsvValidationError = (error) => {
  if (error.response?.status === 400) {
    showErrorModal(
      "Bei der CSV Validation ist ein Fehler aufgetreten. Die CSV Datei besitzt nicht die korrekte Zeichenkodierung. Sie muss UTF-8 kodiert sein."
    );
  } else if (error.response?.status === 406) {
    showErrorModal(
      "Bei der CSV Validation ist ein Fehler aufgetreten. Die CSV Datei darf nicht UTF-8 BOM kodiert sein. Sie muss UTF-8 kodiert sein."
    );
  } else if (error.response?.status === 415) {
    showErrorModal(
      "Bei der Validierung ist ein Fehler aufgetreten. Die Datei hat das falsche Format. Das Format muss CSV sein."
    );
  } else {
    reportGenericError(error, "Validieren der CSV Datei");
  }
  console.error("Validation fail: ", error);
};

export const showPasswordModal = (password) => {
  PubSub.getInstance().publishToTopic("alert", {
    title: "Passwort",
    text:
      "Das Passwort wurde erfolgreich zurückgesetzt. Das neue Passwort lautet: " +
      password +
      "\nBitte geben Sie das Passwort an den Benutzer weiter. \nSie können das Passwort zu einem späteren Zeitpunkt nicht mehr einsehen.",
    closeButtonText: " Schließen",
    show: true,
  });
};

export const showNewClinicianPasswordModal = (password) => {
  PubSub.getInstance().publishToTopic("alert", {
    title: "Passwort",
    text:
      "Das Passwort lautet: <br>" +
      password +
      "<br>\nBitte geben Sie das Passwort an den Benutzer weiter. \nSie können das Passwort zu einem späteren Zeitpunkt nicht mehr einsehen.",
    closeButtonText: " Schließen",
    textAsHtml: true,
    show: true,
  });
};

/**
 * Shows an alertbox-like dialog to let the user know about an error during login as a patient
 * @param {string} authResponse  one of 'success' | 'credentials_wrong' | 'no_permissions' | 'error'
 */
export const reportPatientLoginError = (authResponse) => {
  if (authResponse === "credentials_wrong") {
    showErrorModal(
      "Ihre Anmeldedaten sind fehlerhaft. Bitte überprüfen Sie diese nochmal."
    );
  } else if (authResponse === "no_permissions") {
    showErrorModal("Sie haben für diese Seite keine Berechtigung.");
  } else if ("success") {
    return;
  } else {
    showErrorModal("Beim Login ist ein Fehler aufgetreten.");
  }
};

export const reportNoClinicSelected = (error) => {
  showErrorModal("Keine Klinik ausgewählt");
};
