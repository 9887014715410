/* eslint no-eval: 0 */
import React, { Component } from "react";
import {
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Modal,
  ModalBody,
  ProgressBar,
} from "react-bootstrap";
import AuthService from "../../services/AuthService";
import { getSurvey } from "../../services/getFunctions";
import NavigationBar from "../navigation/NavigationBar";
import DatePicker from "react-datepicker";
import TimeoutSession from "../../helper/TimeoutSession";
import {
  reportGetSurveyError,
  reportSubmitSurveyError,
  showAlertModal,
} from "../../helper/alertBoxes";
import { setSurvey } from "../../services/postFunctions";

/**Component for running the surveys

 */
let progressInterval;
class RunSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic_id: "",
      clinic_name: "",
      username: "",
      provisionLevel: "",
      items: [],
      itemGroups: [],
      iGroups: [],
      title: "",
      paragraph: "",
      itemResponses: [],
      date: new Date(),
      content: "",
      endOfSurvey: false,
      loadingSpinner: false,
      waitModalOpen: false,
      progressBarStatus: 0,
      highlightItemIndex: -1,
    };
    this.handleQuestionSelect = this.handleQuestionSelect.bind(this);
    this.handleSurveyResponses = this.handleSurveyResponses.bind(this);
    this.handleTextInput = this.handleTextInput.bind(this);
    this.handleRadioBtn = this.handleRadioBtn.bind(this);
    this.handleDate = this.handleDate.bind(this);
  }

  /**Loads survey items from backend api. */
  componentDidMount() {
    window.scrollTo(0, 0);
    console.log("Responses: ", this.state.itemResponses);
    this.setState({
      clinic_id: this.props.location.state.clinic_id,
      clinic_name: this.props.location.state.clinic_name,
      username: this.props.location.state.username,
      provisionLevel: this.props.location.state.provisionLevel,
      itemResponses: [],
      itemGroups: [],
    });

    try {
      getSurvey()
        .then((surveyResponse) => {
          this.setState({ loadingSpinner: false });

          if (surveyResponse.statusCode === 204) {
            this.setState({ endOfSurvey: true });
            this.props.history.push({
              pathname: "/patients",
              state: {
                clinic_id: this.state.value,
                clinic_name: this.state.clinic_name,
                username: this.state.username,
                provisionLevel: this.state.provisionLevel,
              },
            });
          } else {
            console.log("Survey Items: ", surveyResponse.survey.items);
            this.setState({
              items: surveyResponse.survey.items,
              title: surveyResponse.survey.dimension.title,
              paragraph: surveyResponse.survey.dimension.paragraph,
            });
            this.initialEmptyResponses();
            this.checkGroupOptions();
            this.extractScript();
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            showAlertModal("Entschuldigung. Da ist etwas schiefgelaufen.");
            this.props.history.push({
              pathname: "/patients",
              state: {
                clinic_id: this.state.value,
                clinic_name: this.state.clinic_name,
                username: this.state.username,
                provisionLevel: this.state.provisionLevel,
              },
            });
          }
          reportGetSurveyError(error);
          console.error("Items Fail: ", error);
        });
    } catch (error) {}
  }

  /**Before component will be destroyed, an info-alert would be shown. */
  componentWillUnmount() {
    if (this.state.endOfSurvey === false) {
      showAlertModal(
        "Sie verlassen die Befragung und kehren zur Übersicht zurück. Sie können die Befragung jederzeit fortsetzen."
      );
    }
  }

  /**Method to set waitModalOpen on true and start the progress bar status. */
  openModal = () => {
    this.setState({ waitModalOpen: true });

    let countdown = 0;
    progressInterval = setInterval(() => {
      if (countdown < 98) {
        countdown++;
        this.setState({ progressBarStatus: countdown });
      }
    }, 80);
  };

  /**Set waitModalOpen on false and clear the progressInterval. */
  closeModal = () => {
    this.setState({ waitModalOpen: false });
    clearInterval(progressInterval);
  };

  /**Set initial state for optional items, email items and info items.
   * If item type is INFO_AUTOSUBMIT send response immediatly.
   */
  initialEmptyResponses() {
    for (var i = 0; i < this.state.items.length; i++) {
      if (
        this.state.items[i].type === "INFO" ||
        this.state.items[i].type === "INFO_AUTOSUBMIT" ||
        this.state.items[i].type === "QUESTION_TEXT_OPTIONAL" ||
        this.state.items[i].type === "QUESTION_TEXT_AREA_OPTIONAL" ||
        this.state.items[i].type === "EMAIL_NOTIFICATION_SENSING_STUDY" ||
        this.state.items[i].type === "EMAIL_NOTIFICATION_FOLLOW_UP_1" ||
        this.state.items[i].type === "EMAIL_NOTIFICATION_FOLLOW_UP"
      ) {
        let responses = [...this.state.itemResponses];
        responses[i] = {
          id: this.state.items[i].id,
          label: "",
          time_taken: 0,
          value: "",
        };
        this.setState({ itemResponses: responses });
      } else {
      }
      if (this.state.items[i].type === "INFO_AUTOSUBMIT") {
        // this.setState({ loadingSpinner: true, progressBarStatus: 50 });
        this.openModal();
        try {
          setSurvey(this.state.itemResponses)
            .then((response) => {
              this.endingSurvey();
              console.log("Auto Submit Response: ", response);
            })
            .catch((error) => {
              reportSubmitSurveyError(error);
            });
        } catch (e) {
          console.error("Catch", e);
        }
      }
    }
    console.log("Antwort optional: ", this.state.itemResponses);
  }

  /**To set the "finished" attribute of a survey in the db, a request must be made to check the items. */
  endingSurvey() {
    try {
      getSurvey()
        .then((surveyResponse) => {
          if (surveyResponse.statusCode === 204) {
            //this means that the survey has finished
            this.setState({
              endOfSurvey: true,
              loadingSpinner: false,
              progressBarStatus: 100,
            });
            this.closeModal();
            console.log("Keine weiteren Items. Ende der Befragung.");
          } else {
            console.log("AutoSubmit Item ", surveyResponse.survey);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 500) {
            showAlertModal("Entschuldigung. Da ist etwas schiefgelaufen.");
            this.props.history.push({
              pathname: "/patients",
              state: {
                clinic_id: this.state.value,
                clinic_name: this.state.clinic_name,
                username: this.state.username,
                provisionLevel: this.state.provisionLevel,
              },
            });
          }
          console.error("AutoSubmit Items Fail: ", error);
        });
    } catch (error) {}
  }

  /**Extract scripts in html-response from item "INFO" and "INFO_AUTOSUBMIT".
   * Run clean javascript-code with window.eval().
   */
  extractScript() {
    for (var i = 0; i < this.state.items.length; i++) {
      if (
        this.state.items[i].type === "INFO" ||
        this.state.items[i].type === "INFO_AUTOSUBMIT"
      ) {
        var content = this.state.items[i].content;
        if (/<script\s*.*>[\s\S]*<\/script>/gi.exec(content) != null) {
          var extract = /<script\s*.*>[\s\S]*<\/script>/gi.exec(content)[0];
          console.log("Script: ", extract);
          this.setState({ content: extract });
        }
      }
      if (this.state.content !== "") {
        var runScript = this.state.content;
        if (runScript !== undefined) {
          let cleanScript = runScript.replace(/<\/?script\s*.*>/gm, "");
          console.log("Clean: ", cleanScript);
          window.eval(cleanScript);
        }
      }
    }
  }

  /**Check if Items with type "QUESTION_HORIZONTAL_RADIO" have the same response_options.
   * Save them in state list itemGroups with parameter group: true||false.
   * The first item in group needs the parameter head: true.
   */
  checkGroupOptions() {
    var items = this.state.items;
    if (items.length === 1 && items[0].type === "QUESTION_HORIZONTAL_RADIO") {
      let groups = [...this.state.iGroups];
      groups[0] = {
        id: items[0].id,
        group: false,
      };
      this.setState({ iGroups: groups });
    } else {
      //for loop to set value = 0 || 1 || 2 || 3
      for (var i = 0; i < items.length; i++) {
        let groups = [...this.state.iGroups];
        if (items[i].type !== "QUESTION_HORIZONTAL_RADIO") {
          groups[i] = {
            id: items[i].id,
            value: 0,
          };
        } else if (
          items[i].type === "QUESTION_HORIZONTAL_RADIO" &&
          (!items[i - 1] ||
            items[i - 1].type !== "QUESTION_HORIZONTAL_RADIO") &&
          (!items[i + 1] || items[i + 1].type !== "QUESTION_HORIZONTAL_RADIO")
        ) {
          groups[i] = {
            id: items[i].id,
            value: 1,
            group: false,
          };
        } else if (
          items[i].type === "QUESTION_HORIZONTAL_RADIO" &&
          items[i + 1] &&
          items[i + 1].type === "QUESTION_HORIZONTAL_RADIO"
        ) {
          groups[i] = {
            id: items[i].id,
            value: 2,
          };
        } else if (
          items[i].type === "QUESTION_HORIZONTAL_RADIO" &&
          items[i - 1] &&
          items[i - 1].type === "QUESTION_HORIZONTAL_RADIO" &&
          (!items[i + 1] || items[i + 1].type !== "QUESTION_HORIZONTAL_RADIO")
        ) {
          groups[i] = {
            id: items[i].id,
            value: 3,
          };
        } else {
          console.log("Da fehlt wohl noch etwas! Item: ", items[i]);
        }
        this.setState({ iGroups: groups });
      }
    }
    //for loop to loop over items-list with inner for-loop to check response_options
    for (var j = 0; j < items.length; j++) {
      //console.log("Item: ", j, "Gruppe: ", this.state.iGroups);
      var group = 0;
      let groups = [...this.state.iGroups];
      if (this.state.iGroups[j].value === 2) {
        for (var l = 0; l < items[j].response_options.length; l++) {
          if (
            items[j + 1] &&
            items[j].response_options.length ===
              items[j + 1].response_options.length &&
            items[j].response_options[l].label ===
              items[j + 1].response_options[l].label
          ) {
            group++;
          }
        }
        if (group === items[j].response_options.length) {
          groups[j] = {
            ...this.state.iGroups[j],
            group: true,
          };
          groups[j + 1] = {
            ...this.state.iGroups[j + 1],
            group: true,
          };
          this.setState({ iGroups: groups });
        } else if (this.state.iGroups[j].group === true) {
          console.log("Change value");
          groups[j] = {
            ...this.state.iGroups[j],
            value: 3,
          };
          this.setState({ iGroups: groups });
        } else if (!this.state.iGroups[j].group) {
          groups[j] = {
            ...this.state.iGroups[j],
            group: false,
          };
          this.setState({ iGroups: groups });
        }
      } else if (
        this.state.iGroups[j].value === 3 &&
        !this.state.iGroups[j].group
      ) {
        groups[j] = {
          ...this.state.iGroups[j],
          group: false,
        };
        this.setState({ iGroups: groups });
      }
      //console.log("Item: ", j, "Groups: ", this.state.iGroups);
    }
    var iGroups = this.state.iGroups;
    for (var k = 0; k < iGroups.length; k++) {
      if (
        iGroups[k].value === 2 &&
        iGroups[k].group === true &&
        (!iGroups[k - 1] || iGroups[k - 1].value !== 2 || !iGroups[k - 1].group)
      ) {
        let groups = [...this.state.iGroups];
        groups[k] = {
          ...this.state.iGroups[k],
          head: true,
        };
        this.setState({ iGroups: groups });
      }
    }
    console.log("Gruppieren?: ", this.state.iGroups);
  }

  /**Handle responses from radio buttons and set response state with the right item id
   * @param event {ChangeEvent} onChange delivers the event with the selected radio button
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  handleRadioBtn(event, item, index) {
    console.log("Item: ", item);
    //console.log("Event: ", event);
    let responses = [...this.state.itemResponses];
    responses[index] = {
      id: item.id,
      label: event.target.dataset.name,
      time_taken: event.timeStamp,
      value: event.target.value,
    };
    this.setState({ itemResponses: responses });
    console.log("Antworten: ", this.state.itemResponses);
  }

  /**Handle responses from question select and set response state with the right item id
   * @param event {ChangeEvent} onChange delivers the event with the selected option
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  handleQuestionSelect(event, item, index) {
    let responses = [...this.state.itemResponses];
    if (event.target.value !== "Auswählen...") {
      responses[index] = {
        id: item.id,
        label: event.target[event.target.selectedIndex].dataset.name,
        time_taken: event.timeStamp,
        value: event.target.value,
      };
    } else if (event.target.value === "Auswählen...") {
      responses[index] = undefined;
    } else return;
    this.setState({ itemResponses: responses });
    console.log("Antworten: ", this.state.itemResponses);
  }

  /**Handle responses from text input and set response state with the right item id
   * @param event {ChangeEvent} onChange delivers the event with the changed text input
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  handleTextInput(event, item, index) {
    let responses = [...this.state.itemResponses];
    if (event.target.value !== "" || event.target.type === "email") {
      responses[index] = {
        id: item.id,
        label: "",
        time_taken: event.timeStamp,
        value: event.target.value,
      };
    } else {
      responses[index] = undefined;
    }

    this.setState({ itemResponses: responses });
    console.log("Antworten: ", this.state.itemResponses);
  }

  /**Handle responses from date input and set response state with the right item id
   * @param date {date} date from datepicker or date input
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  handleDate(date, item, index) {
    this.setState({ date: date });
    let responses = [...this.state.itemResponses];
    responses[index] = {
      id: item.id,
      label: "",
      time_taken: 0,
      value: date.toISOString().split("T")[0],
    };
    this.setState({ itemResponses: responses });
    console.log("Antworten: ", this.state.itemResponses);
  }

  /**Handle responses from integer input and set response state with the right item id.
   * Checks whether the value is an integer. If the value isNaN after parseInt() the state would not be updated.
   * @param event {ChangeEvent} onChange delivers the event with the changed text input
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  handleInteger(event, item, index) {
    const numberRegex = /^\d+$/;
    let responses = [...this.state.itemResponses];
    let strVal = event.target.value;
    var val = parseInt(strVal);
    const highestIntAllowedByBackend = 2147483647; //(2^31-1)

    if (
      !numberRegex.test(event.key) &&
      ![
        "Delete",
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Tab",
      ].includes(event.key) &&
      event.type === "keydown"
    ) {
      event.nativeEvent.preventDefault();
    }

    if (numberRegex.test(strVal)) {
      responses[index] = {
        id: item.id,
        label: "",
        time_taken: event.timeStamp,
        value: val,
      };
    } else if (event.target.value === "") {
      responses[index] = undefined;
    }

    if (val > highestIntAllowedByBackend) {
      event.target.value = event.target.value.slice(0, -1);
      showAlertModal(
        "Die von Ihnen eingegebene Zahl ist zu groß. Die höchste erlaubte Zahl ist " +
          highestIntAllowedByBackend
      );
    }

    this.setState({ itemResponses: responses });
    console.log("Antworten: ", this.state.itemResponses);
  }

  /**
   * When called searches for a sibling of the event target that is an input element of type=email and if found adds a @ symbol to the end of it's value
   * @param {*} event
   * @param {*} item
   * @param {*} index
   */
  handleAtSymbol(event, item, index) {
    let parent = event.target.parentElement;

    let formElement = parent.querySelector("input[type=email]");
    try {
      formElement.value += "@";
    } catch (e) {
      console.error("Error when trying to add @: ", e);
    }
  }

  /**Handle responses from positive double input and set response state with the right item id.
   * The value can be typed in with a "." or ",".
   * Checks whether the value is a positive double. If the value isNaN after parseFloat() or the value is negative the state would not be updated.
   * @param event {ChangeEvent} onChange delivers the event with the changed text input
   * @param item {Object} Contains the item with the item id
   * @param index {integer} Index in itemResponses-Array
   */
  handlePosDouble(event, item, index) {
    let responses = [...this.state.itemResponses];
    var val = parseFloat(event.target.value.replace(/,/, "."));
    if (!isNaN(val) && val > 0) {
      responses[index] = {
        id: item.id,
        label: "",
        time_taken: event.timeStamp,
        value: val,
      };
      this.setState({ itemResponses: responses });
    } else if (event.target.value === "") {
      responses[index] = undefined;
    } else {
      showAlertModal("Sie dürfen nur positive Kommazahlen eingeben.");
      console.log("The Value: '", event.target.value, "' is NaN oder negativ.");
    }
    console.log("Antworten: ", this.state.itemResponses);
  }

  /**
   * Method for handle the survey item responses.
   * The time taken is calculated and divided among the items.
   * Then it is checked whether an entry has been created in the list for each item. If not, an alert is displayed to the user.
   * The input data are stringified to JSON.
   * Then send JSON to backend API and handle the response.
   */
  handleSurveyResponses = (event) => {
    event.preventDefault();
    this.setState({
      highlightItemIndex: -1,
    });
    if (this.state.items) {
      if (this.state.items[0].type !== "INFO_AUTOSUBMIT") {
        var count = this.state.items.length;
        var timeTaken = (event.timeStamp / 1000 / count).toFixed(3);
        var valid = true;
        console.log("Zeit insgesamt: ", event.timeStamp);
        console.log("Zeit pro Item: ", timeTaken);
        let responses = [...this.state.itemResponses];
        for (var i = 0; i < count; i++) {
          if (
            this.state.items[i].type === "EMAIL_NOTIFICATION_FOLLOW_UP" ||
            this.state.items[i].type === "EMAIL_NOTIFICATION_SENSING_STUDY" ||
            this.state.items[i].type === "EMAIL_NOTIFICATION_FOLLOW_UP_1"
          ) {
            var email = responses[i].value;
            if (
              email === "" ||
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
            ) {
              valid = true;
            } else {
              showAlertModal(
                "Die E-Mail ist nicht valide. Bitte geben Sie eine valide E-Mail ein."
              );
              valid = false;
            }
          }
          if (!responses[i]) {
            showAlertModal(
              "Sie haben noch nicht alle Fragen beantwortet. Folgende Frage wurde noch nicht beantwortet: " +
                (i + 1)
            );
            this.setState({
              highlightItemIndex: i,
            });
            return;
          } else {
            responses[i] = {
              ...this.state.itemResponses[i],
              time_taken: timeTaken,
            };
          }
        }
        console.log("Antworten mit der neuen Zeit: ", responses);

        if (valid === true) {
          this.setState({ loadingSpinner: true });
          try {
            setSurvey(responses)
              .then((response) => {
                console.log("Submit Response: ", response);
                this.setState({ itemResponses: [], items: [], iGroups: [] });
                this.componentDidMount();
              })
              .catch((error) => {
                console.error("Error: ", error.response);
                this.setState({ loadingSpinner: false });
                showAlertModal(
                  "Irgendetwas ist schief gelaufen. Bitte füllen Sie die Fragen erneut aus."
                );
              });
          } catch (e) {
            console.error("Catch", e);
          }
        }
      } else {
        console.log("Weiter zum Dashboard");
        this.setState({ endOfSurvey: true, loadingSpinner: true });
        this.componentDidMount();
      }
    } else {
      console.log("Die Items-Liste ist leer.");
    }
  };

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <NavigationBar>
          {this.state.clinic_id}
          {this.state.clinic_name}
          {this.state.username}
          {this.state.user_type}
          {this.state.provisionLevel}
        </NavigationBar>
        <div className="page-wrapper-new">
          <div className="wrapper">
            <div className="content-wrapper">
              <div className="content content-without-sidebar survey-form">
                <h4>{this.state.title}</h4>
                <div
                  className="survey-description-text"
                  dangerouslySetInnerHTML={{ __html: this.state.paragraph }}
                ></div>
                <Form noValidate onSubmit={this.handleSurveyResponses}>
                  {this.state.items &&
                    this.state.items.length > 0 &&
                    this.state.items.map((item, index) => {
                      return item.type === "INFO" ||
                        item.type === "INFO_AUTOSUBMIT" ? (
                        <div
                          className="survey-info-element"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                      ) : (
                        <Form.Group key={index} className="survey-form-group">
                          {item.type !== "QUESTION_HORIZONTAL_RADIO" ? (
                            <Form.Label
                              className={`survey-label-element${
                                index === this.state.highlightItemIndex
                                  ? " question-highlighted"
                                  : ""
                              }`}
                              key={index}
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></Form.Label>
                          ) : null}

                          {(() => {
                            switch (item.type) {
                              case "QUESTION_HORIZONTAL_RADIO":
                                return (
                                  <div className="radio-horizontal">
                                    {this.state.iGroups &&
                                      this.state.iGroups.length > 0 &&
                                      this.state.iGroups.map((itemgroup, i) => {
                                        return i === index &&
                                          itemgroup.group === true ? (
                                          <div
                                            key={itemgroup.id}
                                            className="radio-grid-table-element "
                                          >
                                            {itemgroup.head ? (
                                              <div
                                                key={i}
                                                className="radio-grid-table-head radio-grid-table without-horizontal-scroll"
                                              >
                                                <div
                                                  className="radio-label"
                                                  key="-1"
                                                ></div>
                                                {item.response_options &&
                                                  item.response_options.length >
                                                    0 &&
                                                  item.response_options.map(
                                                    (option, idx) => {
                                                      return (
                                                        <div className="radio-col">
                                                          <label
                                                            className="radio-custom"
                                                            key={idx}
                                                          >
                                                            {option.label}
                                                          </label>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            ) : null}
                                            {/* Question Label */}
                                            <div
                                              className="radio-label"
                                              dangerouslySetInnerHTML={{
                                                __html: item.content,
                                              }}
                                            ></div>
                                            <div
                                              className={`without-horizontal-scroll radio-grid-table grid-table${
                                                index ===
                                                this.state.highlightItemIndex
                                                  ? " question-highlighted"
                                                  : ""
                                              }`} /*{
                                                  i % 2 === 0
                                                    ? "align-horizontal-scroll radio-grid-table grid-table"
                                                    : "align-horizontal-scroll radio-grid-table"
                                                }*/
                                            >
                                              <div
                                                className="radio-label"
                                                dangerouslySetInnerHTML={{
                                                  __html: item.content,
                                                }}
                                                key={i}
                                              ></div>
                                              {item.response_options &&
                                                item.response_options.length >
                                                  0 &&
                                                item.response_options.map(
                                                  (option, idx) => {
                                                    return (
                                                      <div className="radio-col">
                                                        <label className="radio-custom">
                                                          <Form.Check
                                                            /*className="btn-lg"*/
                                                            type="radio"
                                                            aria-label="radio-btn"
                                                            inline
                                                            name={item.id}
                                                            data-name={
                                                              option.label
                                                            }
                                                            key={idx}
                                                            id={option.id}
                                                            value={option.value}
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              this.handleRadioBtn(
                                                                event,
                                                                item,
                                                                index
                                                              );
                                                            }}
                                                          />
                                                        </label>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          </div>
                                        ) : i === index &&
                                          itemgroup.group === false ? (
                                          <div
                                            key={i}
                                            className="radio-normal-element"
                                          >
                                            <Form.Label
                                              key={index}
                                              dangerouslySetInnerHTML={{
                                                __html: item.content,
                                              }}
                                            ></Form.Label>
                                            <div className="align-horizontal-scroll">
                                              {item.response_options &&
                                                item.response_options.length >
                                                  0 &&
                                                item.response_options.map(
                                                  (option, idx) => {
                                                    return (
                                                      <div className="radio-col">
                                                        <label className="radio-custom">
                                                          <Form.Check
                                                            type="radio"
                                                            /*className="btn-lg"*/
                                                            inline
                                                            name={item.id}
                                                            data-name={
                                                              option.label
                                                            }
                                                            key={idx}
                                                            id={option.id}
                                                            label={option.label}
                                                            value={option.value}
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              this.handleRadioBtn(
                                                                event,
                                                                item,
                                                                index
                                                              );
                                                            }}
                                                          />
                                                        </label>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>{" "}
                                          </div>
                                        ) : null;
                                      })}{" "}
                                  </div>
                                );

                              case "QUESTION_HORIZONTAL_RADIO_ENFORCED":
                                return (
                                  <div
                                    className={`align-horizontal-scroll radio-horizontal-enforced${
                                      index === this.state.highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                  >
                                    {/* Question Label */}

                                    {item.response_options &&
                                      item.response_options.length > 0 &&
                                      item.response_options.map(
                                        (option, idx) => {
                                          return (
                                            <div className="radio-col">
                                              <label className="radio-custom">
                                                <Form.Check
                                                  type="radio"
                                                  /*className="btn-lg horizontal-radio-enforced"*/
                                                  inline
                                                  name={item.id}
                                                  data-name={option.label}
                                                  key={idx}
                                                  id={option.id}
                                                  label={option.label}
                                                  value={option.value}
                                                  onChange={(event) => {
                                                    this.handleRadioBtn(
                                                      event,
                                                      item,
                                                      index
                                                    );
                                                  }}
                                                />
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                );

                              case "QUESTION_VERTICAL_RADIO":
                                return (
                                  <div
                                    className={`radio-vertical${
                                      index === this.state.highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                  >
                                    {item.response_options &&
                                      item.response_options.length > 0 &&
                                      item.response_options.map(
                                        (option, idx) => {
                                          return (
                                            <div className="radio-col">
                                              <label className="radio-custom">
                                                <Form.Check
                                                  type="radio"
                                                  /*className="btn-lg"*/
                                                  inline
                                                  name={item.id}
                                                  data-name={option.label}
                                                  key={idx}
                                                  id={option.id}
                                                  label={option.label}
                                                  value={option.value}
                                                  onChange={(event) => {
                                                    this.handleRadioBtn(
                                                      event,
                                                      item,
                                                      index
                                                    );
                                                  }}
                                                />
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                );
                              case "QUESTION_SELECT":
                                return (
                                  <div
                                    className={`${
                                      index === this.state.highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                  >
                                    <Form.Select
                                      onChange={(event) => {
                                        this.handleQuestionSelect(
                                          event,
                                          item,
                                          index
                                        );
                                      }}
                                    >
                                      <option>Auswählen...</option>
                                      {item.response_options &&
                                        item.response_options.length > 0 &&
                                        item.response_options.map(
                                          (option, idx) => {
                                            return (
                                              <option
                                                key={idx}
                                                value={option.value}
                                                data-name={option.label}
                                              >
                                                {option.label}
                                              </option>
                                            );
                                          }
                                        )}
                                    </Form.Select>
                                  </div>
                                );

                              case "QUESTION_TEXT":
                              case "QUESTION_TEXT_OPTIONAL":
                                return (
                                  <Form.Control
                                    type="text"
                                    className={`${
                                      index === this.state.highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                    onChange={(event) => {
                                      this.handleTextInput(event, item, index);
                                    }}
                                  />
                                );
                              case "QUESTION_DATE":
                                return (
                                  <DatePicker
                                    className={`form-control${
                                      index === this.state.highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                    type="date"
                                    dateFormat="yyyy-MM-dd"
                                    selected={this.state.date}
                                    value={this.state.date}
                                    onChange={(date) => {
                                      this.handleDate(date, item, index);
                                    }}
                                  />
                                );
                              case "QUESTION_INTEGER":
                                return (
                                  <Form.Control
                                    className={`survey-form-element${
                                      index === this.state.highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                    type="number"
                                    min="0"
                                    max="2147483647"
                                    pattern="[0-9]*"
                                    onChange={(event) => {
                                      this.handleInteger(event, item, index);
                                    }}
                                    onKeyDown={(event) => {
                                      this.handleInteger(event, item, index);
                                    }}
                                  />
                                );
                              case "QUESTION_DOUBLE_POSITIVE":
                                return (
                                  <Form.Control
                                    type="text"
                                    className={`${
                                      index === this.state.highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                    onChange={(event) => {
                                      this.handlePosDouble(event, item, index);
                                    }}
                                  />
                                );
                              case "EMAIL_NOTIFICATION_FOLLOW_UP":
                              case "EMAIL_NOTIFICATION_SENSING_STUDY":
                              case "EMAIL_NOTIFICATION_FOLLOW_UP_1":
                                return (
                                  <>
                                    <Form.Control
                                      type="email"
                                      placeholder="max@mustermann.de"
                                      className={`${
                                        index === this.state.highlightItemIndex
                                          ? " question-highlighted"
                                          : ""
                                      }`}
                                      onChange={(event) => {
                                        this.handleTextInput(
                                          event,
                                          item,
                                          index
                                        );
                                      }}
                                    />
                                    <Button
                                      onClick={(event) => {
                                        this.handleAtSymbol(event, item, index);
                                      }}
                                    >
                                      @ einfügen
                                    </Button>
                                  </>
                                );
                              case "QUESTION_TEXT_AREA":
                              case "QUESTION_TEXT_AREA_OPTIONAL":
                                return (
                                  <Form.Control
                                    as="textarea"
                                    className={`${
                                      index === this.state.highlightItemIndex
                                        ? " question-highlighted"
                                        : ""
                                    }`}
                                    onChange={(event) => {
                                      this.handleTextInput(event, item, index);
                                    }}
                                  />
                                );
                              default:
                                return <div></div>;
                            }
                          })()}
                        </Form.Group>
                      );
                    })}
                  <OverlayTrigger
                    overlay={
                      <Tooltip className="tooltip">
                        Sie können nicht zur vorherigen Frage zurückkehren.
                      </Tooltip>
                    }
                  >
                    <Button variant="outline" className="custom-btn">
                      Zurück
                    </Button>
                  </OverlayTrigger>
                  <Button
                    disabled={
                      // this.state.items.length === 0 ||
                      this.state.loadingSpinner ? true : false
                    }
                    type="submit"
                    className="custom-btn"
                    variant="primary"
                  >
                    {" "}
                    {this.state.loadingSpinner ? (
                      <Spinner
                        animation="border"
                        role="status"
                        // className="loadingSpinner"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Weiter"
                    )}
                  </Button>
                </Form>
                <Modal
                  backdrop="static"
                  show={this.state.waitModalOpen}
                  onHide={this.closeModal}
                >
                  <ModalBody>
                    Ihre Daten werden übermittelt. Bitte haben Sie einen Moment
                    Geduld.
                    <ProgressBar animated now={this.state.progressBarStatus} />
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RunSurvey;
