import { Col, Row, Tooltip } from "react-bootstrap";
import React, { Component } from "react";
import { Card, Container, Form, OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { getClinics } from "../../services/getFunctions";
import AuthService from "../../services/AuthService";
import Footer from "../Footer";
import { RiInformationFill } from "react-icons/ri";
import { BiHide, BiShow } from "react-icons/bi";
import {
  reportGetClinicListError,
  reportPatientLoginError,
  reportNoClinicSelected,
} from "../../helper/alertBoxes";

/**
 * Component for patient login.
 */
class LoginPatient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      clinicname: "",
      username: "",
      password: "",
      result: "",
      isInvalid: true,
      unauthorized: true,
      clinics: [],
      hiddenPw: true,
    };
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleClinicId = this.handleClinicId.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  /**Method for loading the clinic list from backend API. */
  componentDidMount() {
    sessionStorage.removeItem("auth");

    try {
      getClinics()
        .then((clinicList) => {
          console.log("Klinik-Liste: ", clinicList);
          this.setState({
            clinics: clinicList.clinics,
          });
        })
        .catch((error) => {
          reportGetClinicListError(error);
        });
    } catch (error) {}
  }

  /**Method to update the clinic id state, when it is changed.
   * @param event {ChangeEvent} onChange delivers the event with the selected option
   */
  handleClinicId(event) {
    var clinicId = event.target.value;
    var clinic = this.state.clinics.find((c) => {
      return c.id === clinicId;
    });

    if (!clinic) {
      this.setState({ value: clinicId, clinicname: "" });
      return;
    }

    var clinicName = clinic.name;
    this.setState({ value: clinicId, clinicname: clinicName });
    console.log("Klinik-ID: ", this.state.value, "Klinikname: ", clinicName);
  }

  /**Method to update the username state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleUsername(text) {
    console.log("Username", this.state.username, " ausgefüllt");
    this.setState({ username: text.target.value });
  }

  /**Method to update the password state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handlePassword(text) {
    console.log("Passwort", this.state.password, " ausgefüllt");
    this.setState({ password: text.target.value });
  }

  /**
   * Method for handle the login.
   * The input data are stringified to JSON.
   * Then send JSON to backend API and handle the response.
   */
  handleLogin = async (event) => {
    event.preventDefault();
    console.log("Time: ", event.timeStamp / 1000);
    console.log("Button aktiviert");
    if (!this.state.username) {
      console.log("Kein Username");

      return;
    }
    if (!this.state.password) {
      console.log("Kein Passwort");
      return;
    }
    if (!this.state.value || this.state.value === "") {
      console.log("Keine Klinik");
      reportNoClinicSelected();
      return;
    }
    var loginData = {
      clinic_id: this.state.value,
      username: this.state.username,
      password: this.state.password,
    };

    try {
      let authResponse = await AuthService.login(loginData, "patient");
      if (authResponse === "success") {
        this.setState({ unauthorized: false });
        console.log("JSON: ", loginData);

        if (
          this.props.location.state?.redirectTo &&
          this.props.location.state.redirectTo.length > 0
        ) {
          this.props.history.push({
            pathname: this.props.location.state.redirectTo,
            state: {
              clinic_id: this.state.value,
              clinic_name: this.state.clinicname,
              username: this.state.username,
            },
          });
        } else {
          this.props.history.push({
            pathname: "/patients",
            state: {
              clinic_id: this.state.value,
              clinic_name: this.state.clinicname,
              username: this.state.username,
            },
          });
        }
      } else {
        reportPatientLoginError(authResponse);
      }
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Change hiddenPw state on true or false. */
  showPassword = (event) => {
    event.preventDefault();
    console.log("Event: ", event);
    if (event.target.id === "show") {
      this.setState({ hiddenPw: false });
    } else this.setState({ hiddenPw: true });
  };

  /**
   * React render method.
   */
  render() {
    return (
      <Container className="container d-flex align-items-center justify-content-center">
        <Card className="card login col-lg-6 col-md-10">
          <Card.Header className="card-header d-flex justify-content-center">
            <img
              src="img/rehacat.png"
              alt="Rehacat Logo"
              width="230px"
              height="auto"
            />
          </Card.Header>
          <Card.Body className="p-5">
            <Card.Title className="text-dark mb-5">
              Anmeldung Patient*innen
            </Card.Title>
            <Form noValidate validated onSubmit={this.handleLogin}>
              <Form.Group controlId="formGroupClinic">
                <Form.Label className="">
                  Klinik{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip className="tooltip">
                        Ihre Klinik ist in der Liste nicht enthalten? Wenden Sie
                        sich bitte an rehacat@uni-ulm.de
                      </Tooltip>
                    }
                  >
                    <span className="mdi">
                      <RiInformationFill />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  className="form-control"
                  required
                  defaultValue=""
                  onChange={(event) => {
                    this.handleClinicId(event);
                  }}
                >
                  {this.state.clinics &&
                    this.state.clinics.length > 0 &&
                    [
                      {
                        name: "Bitte auswählen",
                        id: "",
                      },
                      ...this.state.clinics,
                    ].map((clinic, index) => {
                      return (
                        <option
                          name={clinic.name}
                          key={index}
                          value={clinic.id}
                        >
                          {clinic.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formGroupCode">
                <Form.Label className="">
                  Reha-ID{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip className="tooltip">
                        Bitte verwenden Sie die Ihnen bei der Anmeldung
                        zugewiesene Reha-ID.
                      </Tooltip>
                    }
                  >
                    <span className="mdi">
                      <RiInformationFill />
                    </span>
                  </OverlayTrigger>
                </Form.Label>

                <Form.Control
                  className="form-control"
                  required
                  type="username"
                  placeholder="Reha-ID"
                  onChange={(text) => {
                    this.handleUsername(text);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte geben Sie Ihre Reha-ID ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupPassword">
                <Row>
                  <Col sm="3">
                    <Form.Label className="">Geburtsdatum</Form.Label>
                  </Col>
                  <Col>
                    <div className="" variant="" onClick={this.showPassword}>
                      {this.state.hiddenPw ? (
                        <BiShow className="hideShowPw" id="show" />
                      ) : (
                        <BiHide className="hideShowPw" id="hide" />
                      )}
                    </div>
                  </Col>
                </Row>

                <Form.Control
                  className="form-control"
                  required
                  type={this.state.hiddenPw ? "password" : "text"}
                  placeholder="tt.mm.jjjj"
                  onChange={(text) => {
                    this.handlePassword(text);
                  }}
                ></Form.Control>
                <Form.Text>
                  Beispiel: Geburtstag am 7. September 1984. Eingabe: 07.09.1984
                </Form.Text>
              </Form.Group>
              <OverlayTrigger
                overlay={
                  <Tooltip className="tooltip">
                    Zum Einloggen geben Sie bitte Ihre Reha-ID an und zusätzlich
                    Ihr Geburtsdatum. Sollten Sie Ihre Reha-ID vergessen haben,
                    melden Sie sich bitte in Ihrer Klinik.
                  </Tooltip>
                }
              >
                <Button variant="link" readOnly>
                  Reha-ID vergessen?
                </Button>
              </OverlayTrigger>
              <div className="d-grid gap-2">
                <Button className="" variant="primary" type="submit">
                  Anmelden
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <Footer>{"patient"}</Footer>
      </Container>
    );
  }
}

export default LoginPatient;
