/* eslint-disable no-eval */
import React from "react";
import { Component } from "react";
import { Accordion, Button, Card, Col, Row, Table } from "react-bootstrap";
import { MdLocalPrintshop, MdViewList } from "react-icons/md";
import { appendScript } from "../../helper/appendScript";
import { removeScript } from "../../helper/removeScript";
import TimeoutSession from "../../helper/TimeoutSession";
import { getPatientReport } from "../../services/getFunctions";
import Footer from "../Footer";
import NavigationBar from "../navigation/NavigationBar";
import SidebarClinician from "../sidebars/SidebarClinician";
import SidebarPatient from "../sidebars/SidebarPatient";
import { reportGetSurveySessionReportError } from "../../helper/alertBoxes";

/**Patient reports component. */
class PatientReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "",
      clinic_id: "",
      clinic_name: "",
      username: "",
      provisionLevel: "",
      survey_session_id: "",
      patient_code: "",
      birthday: "",
      gender: "",
      stw_type: "",
      started_time: "",
      report: "",
      reportContent: "",
      today: new Date().toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      accordionCurrent: "-1",
      accordionInformation: "-1",
      accordionSurvey: "-1",
      accordionReport: "-1",
      accPatients: "-1",
      accClinics: "-1",
    };
  }

  /**Method for loading the patient report from backend.
   * The final_report script would be append to the component after loading the report from backend.
   */
  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.location.state.user_type === "patient") {
      this.setState({ patient_code: this.props.location.state.username });
    } else {
      this.setState({ patient_code: this.props.location.state.patient_code });
    }
    this.setState({
      clinic_id: this.props.location.state.clinic_id,
      clinic_name: this.props.location.state.clinic_name,
      username: this.props.location.state.username,
      survey_session_id: this.props.location.state.survey_session_id,
      // patient_code: this.props.location.state.patient_code,
      birthday: this.props.location.state.birthday,
      gender: this.props.location.state.gender,
      stw_type: this.props.location.state.stw_type,
      started_time: this.props.location.state.started_time,
      provisionLevel: this.props.location.state.provisionLevel,
      user_type: this.props.location.state.user_type,
    });

    try {
      getPatientReport(this.props.location.state.survey_session_id)
        .then((reportHtml) => {
          console.log("Patient Report: ", reportHtml);
          this.setState({ report: reportHtml });
          appendScript(`${process.env.PUBLIC_URL}/js/final_report.js`);
          this.extractScript();
        })
        .catch((error) => {
          reportGetSurveySessionReportError(error);
        });
    } catch (error) {}
  }

  /**Before component will be destroyed, the final_report script would be removed. */
  componentWillUnmount() {
    removeScript(`${process.env.PUBLIC_URL}/js/final_report.js`);
  }

  /**Extract scripts in html-response.
   * Run clean javascript-code with window.eval().
   */
  extractScript() {
    var content = this.state.report;
    console.log("Content: ", content);
    if (/<script\s*.*>[\s\S]*<\/script>/gi.exec(content) != null) {
      var extract = /<script\s*.*>[\s\S]*<\/script>/gi.exec(content)[0];
      console.log("Script: ", extract);
      this.setState({ reportContent: extract });
    }

    if (this.state.reportContent !== "") {
      var runScript = this.state.reportContent;
      if (runScript !== undefined) {
        let updateScript = runScript.replace(
          /<\/script>([\s\S]*?)<script[^>]*>/gm,
          ""
        );
        let cleanScript = updateScript.replace(
          /<script[^>]*>([\s\S]*?)<\/script>/gm,
          "$1"
        );

        console.log("Clean: ", cleanScript);
        // let reportValues = cleanScript.replace(
        //   /let reportValues = {([\s\S]*?)}/gm,
        //   "$1"
        // );
        // console.log("Report Values: ", reportValues);
        window.eval(cleanScript);
      }
    }
  }

  /**When clicking on sidebar, check which menu item is selected.
   * Open on dashboard the right accordion.
   */
  handleAccordion = (input) => {
    switch (this.state.user_type) {
      case "patient":
        var accCurrent = "-1";
        var accInfo = "-1";
        var accSurvey = "-1";
        var accReports = "-1";
        switch (input) {
          case "current":
            accCurrent = "0";
            break;
          case "info":
            accInfo = "1";
            break;
          case "survey":
            accSurvey = "2";
            break;
          case "report":
            accReports = "3";
            break;
          default:
            console.error("Fehler");
            break;
        }
        if (input !== "general") {
          this.props.history.push({
            pathname: "/patients",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionCurrent: accCurrent,
              accordionInformation: accInfo,
              accordionSurvey: accSurvey,
              accordionReport: accReports,
            },
          });
        }

        break;
      case "clinician":
        var accPatients = "-1";
        var accClinics = "-1";
        switch (input) {
          case "myPatients":
            accPatients = "0";
            break;
          case "clinics":
            accClinics = "1";
            break;
          default:
            console.error("Fehler");
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/clinician",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionMyPatients: accPatients,
              accordionMyClinics: accClinics,
            },
          });
        }

        break;
      default:
        console.error("Fehler");
        break;
    }
  };

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <NavigationBar>
          {this.state.clinic_id}
          {this.state.clinic_name}
          {this.state.username}
          {this.state.user_type}
          {this.state.provisionLevel}
        </NavigationBar>
        <div className="page-wrapper-new">
          <div className="wrapper">
            {this.state.user_type === "patient" ? (
              <SidebarPatient
                handleAccordion={this.handleAccordion}
                state={this.state}
              />
            ) : (
              <SidebarClinician
                handleAccordion={this.handleAccordion}
                state={this.state}
              />
            )}

            <div className="content-wrapper">
              <div
                className="content ergebnisbericht_container"
                id="final-report"
              >
                <Col id="report-heading" lg="12">
                  <h1>Ergebnisbericht</h1>
                </Col>

                <Card
                  id="report-head-info"
                  className="card-default widget-media p-4 active border"
                >
                  <Card.Body className="m-0 p-0">
                    <Row>
                      <Col className="col-12 col-sm-6 col-print-6">
                        <Row>
                          <Col md="3">
                            <div className="icon rounded-circle-outline ml-2">
                              <i className="mdi mdi-comment-account" />
                            </div>
                          </Col>
                          <Col md="9">
                            <Table>
                              <tbody>
                                <tr>
                                  <td className="table-without-top">
                                    Reha-ID:{" "}
                                  </td>
                                  <td className="table-without-top text-grey">
                                    {this.state.patient_code}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Geburtsdatum: </td>
                                  <td className="text-grey">
                                    {this.state.user_type === "patient"
                                      ? "******"
                                      : this.state.birthday}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-without-bottom">
                                    Geschlecht:{" "}
                                  </td>
                                  <td className="table-without-bottom text-grey">
                                    {this.state.user_type === "patient"
                                      ? "******"
                                      : this.state.gender === "MALE"
                                      ? "Männlich"
                                      : this.state.gender === "FEMALE"
                                      ? "Weiblich"
                                      : this.state.gender === "DIVERSE"
                                      ? "Divers"
                                      : this.state.gender}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-12 col-sm-6 col-print-6">
                        <Row>
                          <Col md="3">
                            <div className="icon rounded-circle-outline ml-2">
                              <i className="mdi mdi-timetable"></i>
                            </div>
                          </Col>
                          <Col md="9">
                            <Table>
                              <tbody>
                                <tr>
                                  <td className="table-without-top">
                                    Messzeitpunkt:
                                  </td>
                                  <td className="table-without-top text-grey">
                                    {(() => {
                                      switch (this.state.stw_type) {
                                        case "PRE":
                                          return "Aufnahme";
                                        case "POST":
                                          return "Entlassung";
                                        case "FOLLOW_UP_1":
                                          return "Nachsorge 6 Monate";
                                        case "FOLLOW_UP_2":
                                          return "Nachsorge 12 Monate";

                                        default:
                                          return null;
                                      }
                                    })()}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Screening Datum:</td>
                                  <td className="text-grey">
                                    {this.state.started_time}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-without-bottom">
                                    Abrufdatum:{" "}
                                  </td>
                                  <td className="table-without-bottom text-grey">
                                    {this.state.today}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Row>
                  <Col>
                    <Accordion
                      defaultActiveKey="0"
                      className="card-default accordion"
                      id="report_overview_box"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <MdViewList className="mr-1" /> Seitenübersicht
                        </Accordion.Header>
                        <Accordion.Body className="p-0 pt-3 ">
                          <ul
                            className="list-unstyled"
                            id="final_report_overview"
                          >
                            <li>
                              <a
                                href="#dep_patient_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_dep_status"
                                    id="list_item_dep_status"
                                  ></div>
                                  <h4 className="title">
                                    Ihr Ergebnis: Depression
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#anx_patient_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <div
                                    className="position-relative mr-3 list_item_anx_status"
                                    id="list_item_anx_status"
                                  ></div>
                                  <h4 className="title">Ihr Ergebnis: Angst</h4>
                                </div>
                              </a>
                            </li>

                            <li
                              id="list_item_management_container"
                              style={{ display: "none" }}
                            >
                              <a
                                href="#management_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <h4 className="title">
                                    Erweiterter Gesundheits-Check
                                  </h4>
                                </div>
                              </a>
                            </li>
                            <li
                              id="list_item_entlassbericht_container"
                              style={{ display: "none" }}
                            >
                              <a
                                href="#entlassbericht_container"
                                className="media media-message eb-navigation"
                              >
                                <div className="media-body d-flex">
                                  <h4 className="title">Entlassbericht</h4>
                                </div>
                              </a>
                            </li>
                          </ul>
                          {this.state.user_type === "clinician" ? (
                            <div className="card-footer">
                              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <Button
                                  variant="primary"
                                  onClick={() => this.props.history.goBack()}
                                >
                                  <i className="mdi mr-1 mdi-clipboard-account" />
                                  Zurück zum Bericht für Mitarbeitende
                                </Button>
                              </div>
                            </div>
                          ) : null}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col>
                    <Card className="widget-media p-4 active border">
                      <Card.Title className="d-flex justify-content-between">
                        <h5>Ergebnisbericht drucken</h5>
                      </Card.Title>
                      <Card.Body className="m-0 p-0">
                        <Button variant="primary" id="print_final_report">
                          <MdLocalPrintshop /> Drucken
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <div
                  id="final-html"
                  dangerouslySetInnerHTML={{ __html: this.state.report }}
                ></div>

                {/* END html start */}

                <Row className="mt-3" id="management_container">
                  <Col lg="12">
                    <Accordion className="accordion" id="management_clinician">
                      <Accordion.Item>
                        <Accordion.Header id="heading_management_clinician">
                          Erweiterter Gesundheits-Check
                        </Accordion.Header>
                        <Accordion.Body id="management_content_wrapper"></Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>

                <Row
                  className="entlassbericht mt-3"
                  id="entlassbericht_container"
                >
                  <Col lg="12">
                    <Accordion
                      id="entlassbericht_clinician"
                      className="accordion"
                    >
                      <Accordion.Item>
                        <Accordion.Header id="heading_entlassbericht_clinician">
                          Entlassbericht
                        </Accordion.Header>
                        <Accordion.Body id="entlassbericht_content_wrapper"></Accordion.Body>
                        <div className="card-footer">
                          <div className="d-grip gap-2 d-md-flex justify-content-md-end">
                            <Button variant="primary" id="print_entlassbericht">
                              <MdLocalPrintshop /> Entlassbericht drucken
                            </Button>
                            <Button
                              variant="outline-secondary"
                              id="copy_entlassbericht"
                            >
                              In Zwischenablage kopieren
                            </Button>
                          </div>
                        </div>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
                {/* END html end*/}
              </div>
            </div>
            <Footer>
              {this.state.clinic_id}
              {this.state.clinic_name}
              {this.state.username}
              {this.state.user_type}
              {this.state.provisionLevel}
            </Footer>
          </div>
        </div>
      </div>
    );
  }
}
export default PatientReport;
