import React from "react";
import { Component } from "react";
import AuthService from "../services/AuthService";
import NavigationBar from "./navigation/NavigationBar";
import SidebarPatient from "./sidebars/SidebarPatient";
import SidebarAdmin from "./sidebars/SidebarAdmin";
import SidebarClinician from "./sidebars/SidebarClinician";
import Footer from "./Footer";
import { Button } from "react-bootstrap";
import TimeoutSession from "../helper/TimeoutSession";
// import { reportGetProvisionLevelError } from "../helper/alertBoxes";

/**This component contains the content of the privacy policy */
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic_id: "",
      clinic_name: "",
      username: "",
      user_type: "",
      isLoggedIn: false,
      provisionLevel: "",
      accordionCurrent: "-1",
      accordionInformation: "-1",
      accordionSurvey: "-1",
      accordionReport: "-1",
      accordionMyPatients: "-1",
      accordionMyClinics: "-1",
      accordionPatients: "-1",
      accordionEmployees: "-1",
      accordionClinic: "-1",
    };
  }

  /**Checks wheter the user is logged in or not.
   * If the user is logged in, the states would be set.
   */
  componentDidMount() {
    window.scrollTo(0, 0);
    var loggedIn = AuthService.isLoggedIn();
    this.setState({ isLoggedIn: loggedIn });
    if (loggedIn) {
      this.setState({
        clinic_name: this.props.location.state.clinic_name,
        clinic_id: this.props.location.state.clinic_id,
        username: this.props.location.state.username,
        user_type: this.props.location.state.user_type,
        provisionLevel: this.props.location.state.provisionLevel,
      });
    }
  }

  /**When clicking on sidebar, check which user_type the user has and which menu item is selected.
   * Open on dashboard the right accordion.
   */
  handleAccordion = (input) => {
    switch (this.state.user_type) {
      case "patient":
        var accCurrent = "-1";
        var accInfo = "-1";
        var accSurvey = "-1";
        var accReports = "-1";
        switch (input) {
          case "current":
            accCurrent = "0";
            break;
          case "info":
            accInfo = "1";
            break;
          case "survey":
            accSurvey = "2";
            break;
          case "report":
            accReports = "3";
            break;
          default:
            break;
        }
        if (input !== "general") {
          this.props.history.push({
            pathname: "/patients",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionCurrent: accCurrent,
              accordionInformation: accInfo,
              accordionSurvey: accSurvey,
              accordionReport: accReports,
            },
          });
        }

        break;
      case "clinician":
        var accPatients = "-1";
        var accClinics = "-1";
        switch (input) {
          case "myPatients":
            accPatients = "0";
            break;
          case "clinics":
            accClinics = "1";
            break;
          default:
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/clinician",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionMyPatients: accPatients,
              accordionMyClinics: accClinics,
            },
          });
        }

        break;
      case "admin":
        var accPatient = "-1";
        var accEmployees = "-1";
        var accClinic = "-1";
        switch (input) {
          case "patients":
            accPatient = "0";
            break;
          case "employees":
            accEmployees = "1";
            break;
          case "clinic":
            accClinic = "2";
            break;
          default:
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/admin",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionPatients: accPatient,
              accordionEmployees: accEmployees,
              accordionClinic: accClinic,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  /**React render method. */
  render() {
    return (
      <div>
        <div className={this.state.isLoggedIn ? "header-fixed" : ""} id="body">
          {this.state.isLoggedIn ? (
            <>
              <TimeoutSession />
              <NavigationBar>
                {this.state.clinic_id}
                {this.state.clinic_name}
                {this.state.username}
                {this.state.user_type}
                {this.state.provisionLevel}
              </NavigationBar>
            </>
          ) : null}
          <div className={this.state.isLoggedIn ? "page-wrapper-new" : ""}>
            <div className={this.state.isLoggedIn ? "wrapper" : ""}>
              {this.state.isLoggedIn ? (
                this.state.user_type === "patient" ? (
                  <SidebarPatient
                    handleAccordion={this.handleAccordion}
                    state={this.state}
                  />
                ) : this.state.user_type === "clinician" ? (
                  <SidebarClinician
                    handleAccordion={this.handleAccordion}
                    state={this.state}
                  />
                ) : (
                  <SidebarAdmin
                    state={this.state}
                    handleAccordion={this.handleAccordion}
                  />
                )
              ) : null}
              <div className="content-wrapper">
                <div
                  className={
                    this.state.isLoggedIn
                      ? "content"
                      : "content-without-sidebar content-without-navbar"
                  }
                >
                  {" "}
                  {!this.state.isLoggedIn ? (
                    <Button
                      variant="primary"
                      id="backToLogin"
                      onClick={() => this.props.history.goBack()}
                    >
                      Zurück
                    </Button>
                  ) : null}
                  <h3>Informationen zum Datenschutz</h3>
                  <p>
                    Sehr geehrte Nutzer:innen der Plattform RehaCAT,
                    <br /> der Schutz Ihrer Daten ist uns wichtig. Deshalb
                    informieren wir Sie an dieser Stelle, zu welchem Zweck wir
                    Ihre Daten erheben, speichern oder weiterleiten. Sollten Sie
                    die Plattform im Rahmen einer Studie nutzen, können weitere
                    datenschutzrelevante Informationen den dazugehörigen
                    Teilnahmeinformationen bzw. Einwilligungserklärungen
                    entnommen werden. Diese beinhalten auch, welche Rechte Ihnen
                    bezüglich Ihrer personenbezogenen Daten zustehen.
                  </p>
                  <strong>
                    1. Verantwortliche:r für die Datenverarbeitung
                  </strong>
                  <p>
                    Verantwortlicher für die Datenverarbeitung ist gem. Art. 4
                    Abs. 7 Datenschutzgrundverordnung (DSGVO) die <br />
                    Universität Ulm
                    <br />
                    89069 Ulm
                    <br />
                    Telefon +49 (0)731/50-10
                    <br />
                    Telefax +49 (0)731/50-22038
                  </p>
                  <p>
                    Die Universität Ulm ist eine Körperschaft des öffentlichen
                    Rechts, die durch den Präsidenten Prof. Dr.-Ing. Michael
                    Weber (praesident(at)uni-ulm.de) oder durch den Kanzler
                    Dieter Kaufmann (kanzler(at)uni-ulm.de) vertreten wird.
                  </p>
                  <p>
                    Bei Fragen rund um den Datenschutz wenden Sie sich bitte an
                    dsb(at)uni-ulm.de oder senden einen Brief mit dem Zusatz
                    "Datenschutzbeauftragte" an die o. g. Adresse.
                  </p>
                  <p>
                    Weitere spezifische Informationen bezüglich der jeweiligen
                    Studie, an der Sie teilnehmen, finden Sie in den zugehörigen
                    Teilnahmeinformationen bzw. Einwilligungserklärungen.
                  </p>
                  <strong>
                    2. Datenkategorien, Zweck und Rechtsgrundlage der
                    Datenverarbeitung
                  </strong>
                  <p>
                    Das Programm wird auf der passwortgeschützten
                    Online-Plattform RehaCAT der Universität Ulm angeboten. Die
                    Plattform wird von der Abteilung für Klinische Psychologie
                    und Psychotherapie der Universität Ulm betrieben. Alle
                    Daten, die über die Plattform erhoben werden, werden auf den
                    in Deutschland verorteten Servern der STRATO AG
                    verschlüsselt gespeichert.
                  </p>
                  <p>
                    Im Rahmen der Verwendung von RehaCAT werden die folgenden
                    personenbezogenen Daten von Ihnen pseudonymisiert
                    verarbeitet (eine Zuordnung zu Ihnen als Person kann nur
                    durch Ihre Rehabilitationseinrichtung erfolgen):
                  </p>
                  <p>
                    • E-Mail-Adresse (freiwillige Angabe) <br />
                    • Eingaben in Befragungen (freiwillige Angabe) <br />•
                    Nutzungsdaten der Plattform in Hinblick auf das allgemeine
                    Nutzungsverhalten (z.B. Zeitpunkt des Logins)
                  </p>
                  <p>
                    Wir verarbeiten Daten unserer Nutzer:innen grundsätzlich
                    nur, soweit dies zur Bereitstellung einer funktionsfähigen
                    Plattform sowie unserer Inhalte und Leistungen zur Erfüllung
                    von wissenschaftlichen Förderungspflichten (Art. 9 Abs. 2
                    lit. j DSGVO) erforderlich ist . Sollten Sie diese Plattform
                    im Rahmen einer Studie nutzen , können Sie weitere Details
                    den jeweils zugehörigen Teilnahmeinformationen bzw. der
                    Einwilligungserklärung entnehmen. Rechtsgrundlage für die
                    Verarbeitung ist in diesem Fall Ihre Einwilligung zur
                    Teilnahme an der jeweiligen Studie gem. Art. 6 Abs. 1 lit. a
                    EU-DSGVO.
                  </p>
                  <strong>3. Speicherung Ihrer Daten</strong>
                  <p>
                    Ihre Daten werden so lange gespeichert, wie sie für die
                    ordnungsgemäße Funktionalität der Plattform benötigt werden.
                  </p>
                  <p>
                    Sofern einer Löschung keine sonstigen berechtigten
                    Interessen des für die Verarbeitung Verantwortlichen
                    entgegenstehen, erfolgt die Löschung bei Teilnahme an einer
                    Studie oder an einem Forschungsprojekt entsprechend der
                    dortigen Speicherdauer. Sollten Sie die Plattform im Rahmen
                    einer Studie nutzen, finden Sie weitere spezifische
                    Informationen in den zugehörigen Teilnahmeinformationen bzw.
                    der Einwilligungserklärung. Bitte Informieren Sie sich
                    diesbezüglich über die Löschfrist im Rahmen der
                    entsprechenden Studie oder des Forschungsprojekts.
                  </p>
                  <strong>4. Empfänger Ihrer Daten</strong>
                  <p>
                    Im Rahmen der Nutzung der Online-Plattform werden Ihre Daten
                    von den jeweiligen Rehabilitationseinrichtungen, sowie der
                    Abteilung für Klinische Psychologie und Psychotherapie der
                    Universität Ulm verwendet (z.B. Gesundheitszustand während
                    der Rehabilitation, Verbesserung der Routineversorgung).
                  </p>
                  <p>
                    Bei Teilnahme an einer Studie finden Sie weitere
                    Informationen in den entsprechenden Teilnahmeinformationen
                    bzw. Einwilligungserklärung der jeweiligen Studie.
                  </p>
                  <strong>5. Datenverarbeitung durch einen Dritten</strong>
                  <p>
                    Für das Hosting der Online-Plattform RehaCAT nutzt die
                    Universität Ulm einen Server der Firma
                  </p>
                  <p>
                    STRATO AG
                    <br />
                    Pascalstraße 10 <br />
                    10587 Berlin
                  </p>
                  <p>
                    Ihre eingegebenen Daten werden für uns bei der Firma STRATO
                    AG verarbeitet. Alle notwendigen technischen und
                    organisatorischen Sicherheitsmaßnahmen, um Ihre
                    personenbezogenen Daten vor Verlust und Missbrauch zu
                    schützen, werden von uns und in unserem Auftrag von der
                    Firma STRATO AG getroffen. Mit STRATO AG besteht eine
                    rechtlich geprüfte Auftragsdatenverarbeitungsvereinbarung
                    (AVV).
                  </p>
                  <strong>6. Ihre Rechte als Betroffener </strong>
                  <p>
                    Zum Schutz Ihrer personenbezogenen Daten stehen Ihnen
                    folgende Rechte zu:
                  </p>
                  <p>
                    • Ihre Einwilligung widerrufen (Art. 7 Abs. 3 DSGVO)
                    <br />
                    • Auskunft über die Sie betreffenden personenbezogenen Daten
                    zu erhalten (Art. 15 DSGVO),
                    <br />
                    • unrichtige Daten berichtigen zu lassen (Art. 16 DSGVO),
                    <br />
                    • unter bestimmten Voraussetzungen die Löschung oder
                    Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                    zu verlangen (Art. 17, 18 DSGVO),
                    <br />
                    • Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen
                    (Art. 21 DSGVO),
                    <br />
                    • Ihre Daten zu erhalten und an andere von Ihnen bestimmte
                    Stellen übertragen (Art. 20 DSGVO).
                    <br />• eine Beschwerde einreichen (Art. 77 DSGVO)
                  </p>
                  <p>
                    Sie haben das Recht, sich an die zuständige Aufsichtsbehörde
                    für den Datenschutz zu wenden, wenn Sie der Ansicht sind,
                    dass die Verarbeitung Ihrer personenbezogenen Daten nicht
                    rechtmäßig erfolgt. Die für uns zuständige Aufsichtsbehörde
                    ist der Landesbeauftragte für Datenschutz und
                    Informationsfreiheit Baden-Württemberg.
                  </p>
                  <strong>
                    7. Widerruf Ihrer Einwilligung zur Datenverarbeitung
                  </strong>
                  <p>
                    Die Einwilligung zur Datenverarbeitung ist freiwillig. Sie
                    haben das Recht, Ihre Einwilligung jederzeit und ohne
                    Angaben von Gründen zu widerrufen. Durch den Widerruf der
                    Einwilligung wird die Rechtmäßigkeit, der aufgrund der
                    Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
                    berührt. Sollten Sie die Plattform im Rahmen einer Studie
                    nutzen, richten Sie Ihren Widerruf als formlose Mitteilung
                    an die in Teilnahmeinformationen bzw. der
                    Einwilligungserklärung Ihrer jeweiligen Studie genannte
                    Adresse.
                  </p>
                  <strong>SSL- bzw. TLS-Verschlüsselung</strong>
                  <p>
                    Aus Sicherheitsgründen und zum Schutz der Übertragung
                    vertraulicher Inhalte, die Sie an uns als Seitenbetreiber
                    senden, nutzt unsere Website eine SSL-bzw.
                    TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
                    Website übermitteln, für Dritte nicht mitlesbar. Sie
                    erkennen eine verschlüsselte Verbindung an der „https://“
                    Adresszeile Ihres Browsers und am Schloss-Symbol in der
                    Browserzeile.
                  </p>
                  <strong>Server-Log-Dateien</strong>
                  <p>
                    In Server-Log-Dateien erhebt und speichert der Provider der
                    Website automatisch Informationen, die Ihr Browser
                    automatisch an uns übermittelt. Dies sind:
                  </p>
                  <p>
                    • Besuchte Seite auf unserer Domain <br />
                    • Browsertyp und Browserversion
                    <br />
                    • Verwendetes Betriebssystem
                    <br />
                    • Referrer URL
                    <br />
                    • Hostname des zugreifenden Rechners
                    <br />
                    • Datum und Uhrzeit der Serveranfrage
                    <br />• IP-Adresse
                  </p>
                  <p>
                    Es findet keine Zusammenführung dieser Daten mit anderen
                    Datenquellen statt. Grundlage der Datenverarbeitung bildet
                    Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
                    zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
                    gestattet.
                  </p>
                  <strong>Cookies</strong>
                  <p>
                    Unsere Website verwendet Session-Cookies. Das sind kleine
                    Textdateien, die Ihr Webbrowser auf Ihrem Endgerät
                    speichert. Cookies helfen uns dabei, unser Angebot
                    nutzerfreundlicher, effektiver und sicherer zu machen.
                    Session-Cookies werden nach Ende Ihrer Browser-Sitzung von
                    selbst gelöscht. Das Setzen von Cookies, die zur Ausübung
                    elektronischer Kommunikationsvorgänge oder der
                    Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
                    notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
                    f DSGVO. Als Betreiber dieser Website haben wir ein
                    berechtigtes Interesse an der Speicherung von Cookies zur
                    technisch fehlerfreien und reibungslosen Bereitstellung
                    unserer Dienste.
                  </p>
                  <br />
                </div>
              </div>
              {this.state.isLoggedIn ? (
                <Footer>
                  {this.state.clinic_id}
                  {this.state.clinic_name}
                  {this.state.username}
                  {this.state.user_type}
                  {this.state.provisionLevel}
                </Footer>
              ) : (
                <Footer />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PrivacyPolicy;
