/**
 * All functions that write data to the API (DELETE, POST, PUT requests should go here)
 */
import axios from "axios";
import { retryIfTokenInvalid } from "./sessionManagement";

/**
 * Does the request to edit the clinician like specified in the given parameter object
 * @param clinicianData
 * @returns
 */
export async function editClinician(clinicianData: ClinicianData) {
  const url = "/api/auth/clinician/profile";
  let response = await genericPostWithRetry(url, clinicianData);

  return response.data;
}

/**
 * Assigns a patient to another clinician. Only usable as an admin. When signed in as clinician use the other function which is made for clinicians.
 * @param assignmentData
 * @returns
 */
export async function adminAssignClinician(
  assignmentData: ClinicianAssignmentData
) {
  const url = "/api/auth/clinic-admins/clinicians/patients";
  let response = await genericPostWithRetry(url, assignmentData);

  return response.data;
}

/**
 * Assigns a patient ot another clinician. Only usable as clinician. When signed in as admin use the respective function for admins instead.
 * @param assignmentData
 * @returns
 */
export async function clinicianAssignClinician(
  assignmentData: ClinicianAssignmentData
) {
  const url = "/api/auth/clinicians/patients";
  let response = await genericPostWithRetry(url, assignmentData);

  return response.data;
}

/**
 * Edits a patient according to the data given in the parameters. Only usable as clinician.
 * @param patientData
 * @returns
 */
export async function editPatient(patientData: PatientData) {
  const url = "/api/auth/clinic-admins/patients";
  let response = await genericPostWithRetry(url, patientData);

  return response.data;
}

/**
 * Saves the given survey data to the server. Typically used when a patient is answering questions in the survey.
 * @param surveyData
 * @returns
 */
export async function setSurvey(surveyData: SurveyData) {
  const url = "/api/auth/patients/survey";
  let response = await genericPostWithRetry(url, surveyData);

  return response.data;
}

/**
 * Sets the password of the currently signed in clinician to the value specified in the parameter object.
 * Note that the parameter object contains the password twice (for confirmation) and that it also has to contain
 * the old password.
 * @param passwordData
 * @returns
 */
export async function setPassword(passwordData: PasswordData) {
  const url = "/api/auth/password";
  let response = await genericPostWithRetry(url, passwordData);

  return response.data;
}

/**
 * This function seems to be redundant with adminAssignClinician. Right now it exists for legacy reasons until we are
 * sure why there were two functions in the first place.
 * Transfers the patient with the given code from the given clinician to the given clinician.
 * Only usable when signed in as admin.
 * Notice that for some reason the api requires the fromClinician value - I don't really know why
 * @param patientCode
 * @param clinicianFrom
 * @param clinicianTo
 * @deprecated
 * @returns
 */
export async function transferPatient(
  patientCode: string,
  clinicianFrom: string,
  clinicianTo: string
): Promise<void> {
  const url = "/api/auth/clinic-admins/clinicians/patients";

  let transferData = {
    clinician_from: clinicianFrom,
    clinician_to: clinicianTo,
    patient_id: patientCode,
  };

  return await genericPostWithRetry(url, transferData);
}

/**
 * Transfers multiple patients from one clinician to another
 * @param patientCodes
 * @param clinicianFrom
 * @param clinicianTo
 * @returns
 */
export async function transferPatients(
  patientCodes: string[],
  clinicianFrom: string,
  clinicianTo: string
): Promise<void> {
  let promises = patientCodes.map((patientCode) => {
    return transferPatient(patientCode, clinicianFrom, clinicianTo);
  });

  await Promise.all(promises);
  return;
}

/**
 * Sends the given file object to the bulk import endpoint to import multiple patients at once
 * @param file {file} CSV file
 */
export async function bulkImport(file) {
  const url = "/api/auth/clinic-admins/patients/csv";
  const formData = new FormData();
  formData.append("file", file);

  return await genericPostWithRetry(url, formData);
}

/**
 * This is a generic function used to do http post requests.
 * If it gets a 403 status code the first time (=token invalid) it triggers
 * a renew of the token and tries to do the original request again. If that second try fails too then the
 * error is passed along. Other errors than status code 403 are always passed along.
 *
 * @param url
 * @param data the http request body
 */
async function genericPostWithRetry(url, data: any): Promise<any> {
  return await retryIfTokenInvalid(async () => {
    return await axios.post(url, data, {
      withCredentials: true,
    });
  });
}

interface ClinicianData {
  enabled: boolean;
  id: string;
  username: string;
}

interface ClinicianAssignmentData {
  patient_id: string;
  clinician_from: string;
  clinician_to: string;
}

interface PatientData {
  date_of_birth: string;
  admission: string;
  discharge: string;
  clinician_id: string;
  clinician_username: string;
  diagnoses: any; //todo
  id: string;
  gender: string;
  code: string;
  created: string;
}

type SurveyData = ItemResponse[];

interface ItemResponse {
  id: number;
  label: string;
  time_taken: number;
  value: string;
}

interface PasswordData {
  new_password: string;
  old_password: string;
  confirmed_new_password: string; //this is the field where the new password has to be entered a second time
}
