import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import IdleTimer from "react-idle-timer";
import AuthService from "../services/AuthService";

let countdownInterval;
/**Helper component with idle timer. */
class TimeoutSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      countdown: 15,
    };
    this.idleTimer = null;
    this.secondIdleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  /**Method to set modalOpen on true and start the logout countdown if the user is idle. */
  openModal = () => {
    this.secondIdleTimer.start();
    this.secondIdleTimer.timeout = 15 * 1000;
    // console.log("Remaining Time: ", this.secondIdleTimer.getRemainingTime());
    this.setState({
      modalOpen: true,
    });

    let countdown = 15;
    console.log("Countdown: ", countdownInterval);
    countdownInterval = setInterval(() => {
      if (countdown > 0) {
        countdown--;
        this.setState({ countdown: countdown });
      } else if (this.state.modalOpen) {
        console.log("Modal Open: ", this.state.modalOpen);

        AuthService.logout();
      }
    }, 1000);
  };

  /**Set modalOpen on false, pause the countdown and clear the countdownInterval. */
  closeModal = () => {
    console.log("Modal schließen");
    this.setState({ modalOpen: false });
    clearInterval(countdownInterval);
    this.secondIdleTimer.timeout = 15 * 1000;
    this.secondIdleTimer.pause();
  };

  handleOnAction(event) {
    console.log(
      "Timeout: ",
      parseInt(window.APP_CONFIG.REACT_APP_REHACAT_TIMEOUT) * 60 * 1000
    );
  }

  handleOnActive(event) {
    console.log("user is active", event);
  }

  /**Open modal if the user is idle and start the second idle timer. */
  handleOnIdle(event) {
    console.log("user is idle", event);
    this.openModal();
  }

  /**If the user is still idle after the countdown, the logout method is called.  */
  handleOnIdleSecond(event) {
    AuthService.logout();
  }

  /**React render method. */
  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          startOnMount
          timeout={
            parseInt(window.APP_CONFIG.REACT_APP_REHACAT_TIMEOUT) * 60 * 1000
          }
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <IdleTimer
          ref={(ref) => {
            this.secondIdleTimer = ref;
          }}
          startManually
          onIdle={this.handleOnIdleSecond}
          onAction={this.handleOnActionSecond}
        />
        <Modal show={this.state.modalOpen} onHide={this.closeModal}>
          <ModalHeader closeButton>
            <ModalTitle>Achtung!</ModalTitle>
          </ModalHeader>
          <ModalBody>
            Sie werden ausgeloggt, wenn Sie nichts tun. Es verbleiben{" "}
            <b>{this.state.countdown}</b> Sekunden.
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.closeModal}>Eingeloggt bleiben</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default TimeoutSession;
