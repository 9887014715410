import { Col, Row, Tooltip } from "react-bootstrap";
import React, { Component } from "react";
import { Card, Container, Form, OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { getClinics } from "../../services/getFunctions";
import AuthService from "../../services/AuthService";
import Footer from "../Footer";
import { RiInformationFill } from "react-icons/ri";
import { BiHide, BiShow } from "react-icons/bi";
import {
  showAlertModal,
  reportNoClinicSelected,
} from "../../helper/alertBoxes";

/**
 * Component for clinician login.
 */
class LoginClinician extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      clinicname: "",
      username: "",
      password: "",
      result: "",
      isInvalid: true,
      unauthorized: true,
      clinics: [],
      user: [],
      hiddenPw: true,
    };
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleClinicId = this.handleClinicId.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  /**Loads clinic list from backend. */
  componentDidMount() {
    try {
      getClinics()
        .then((clinicList) => {
          console.log("Klinik-Liste: ", clinicList);
          this.setState({
            clinics: clinicList.clinics,
          });
        })
        .catch((error) => {
          console.error("Klinikliste Fail: ", error);
        });
    } catch (error) {}
  }

  /**Method to update the clinic ID state, when it is changed.
   * @param event {ChangeEvent} onChange delivers the event with the selection option
   */
  handleClinicId(event) {
    var clinicId = event.target.value;
    var clinic = this.state.clinics.find((c) => {
      return c.id === clinicId;
    });

    if (!clinic) {
      this.setState({ value: clinicId, clinicname: "" });
      return;
    }

    var clinicName = clinic.name;
    this.setState({ value: clinicId, clinicname: clinicName });
    console.log("Klinik-ID: ", this.state.value, "Klinikname: ", clinicName);
  }

  /**Method to update the username state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleUsername(text) {
    console.log("Username", this.state.username, " ausgefüllt");
    this.setState({ username: text.target.value });
  }

  /**Method to update the password state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handlePassword(text) {
    console.log("Passwort", this.state.password, " ausgefüllt");
    this.setState({ password: text.target.value });
  }

  /**Change hiddenPw state on true or false. */
  showPassword = (event) => {
    event.preventDefault();
    console.log("Event: ", event);
    if (event.target.id === "show") {
      this.setState({ hiddenPw: false });
    } else this.setState({ hiddenPw: true });
  };

  /**
   * Method for handle the login.
   * The input data are stringified to JSON.
   * Then send JSON to backend API and handle the response.
   */
  handleLogin = async (event) => {
    event.preventDefault();
    console.log("Button aktiviert");

    if (!this.state.username) {
      console.log("Kein Username");

      return;
    }
    if (!this.state.password) {
      console.log("Kein Passwort");
      return;
    }

    if (!this.state.value || this.state.value === "") {
      console.log("Keine Klinik");
      reportNoClinicSelected();
      return;
    }

    var loginData = {
      clinic_id: this.state.value,
      username: this.state.username,
      password: this.state.password,
    };

    try {
      let authResponse = await AuthService.login(loginData, "clinician");
      if (authResponse === "success") {
        this.setState({ unauthorized: false });
        console.log("JSON: ", loginData);
        this.props.history.push({
          pathname: "/clinician",
          state: {
            clinic_id: this.state.value,
            clinic_name: this.state.clinicname,
            username: this.state.username,
          },
        });
      } else if (authResponse === "credentials_wrong") {
        showAlertModal(
          "Ihre Anmeldedaten sind fehlerhaft oder Ihr Account wurde noch nicht freigeschaltet."
        );
      } else if (authResponse === "no_permissions") {
        showAlertModal("Sie haben für diese Seite keine Berechtigung.");
      } else {
        showAlertModal(
          "Irgendetwas ist schief gelaufen. Bitte versuchen Sie es erneut."
        );
      }
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**React render method. */
  render() {
    return (
      <Container className="container d-flex align-items-center justify-content-center">
        <Card className="card login col-lg-6 col-md-10">
          <Card.Header className="card-header d-flex justify-content-center">
            <img
              src="img/rehacat.png"
              alt="Rehacat Logo"
              width="230px"
              height="auto"
            />
          </Card.Header>
          <Card.Body className="p-5">
            <Card.Title className="text-dark mb-5">
              Anmeldung Mitarbeitende
            </Card.Title>
            <Form noValidate validated onSubmit={this.handleLogin}>
              <Form.Group controlId="formGroupClinic">
                <Form.Label className="">
                  Klinik{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip className="tooltip">
                        Ihre Klinik ist in der Liste nicht enthalten? Wenden Sie
                        sich bitte an rehacat@uni-ulm.de
                      </Tooltip>
                    }
                  >
                    <span className="mdi">
                      <RiInformationFill />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  className="form-control"
                  required
                  defaultValue=""
                  onChange={(event) => {
                    this.handleClinicId(event);
                  }}
                >
                  {this.state.clinics &&
                    this.state.clinics.length > 0 &&
                    [
                      {
                        name: "Bitte auswählen",
                        id: "",
                      },
                      ...this.state.clinics,
                    ].map((clinic, index) => {
                      return (
                        <option key={index} value={clinic.id}>
                          {clinic.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formGroupCode">
                <Form.Label className="">
                  Mitarbeitenden-ID{" "}
                  {/* <OverlayTrigger
                    overlay={
                      <Tooltip className="tooltip">
                        Bitte verwenden Sie den Ihnen vom Administrator
                        zugewiesenen Benutzernamen.
                      </Tooltip>
                    }
                  >
                    <span className="mdi">
                      <RiInformationFill />
                    </span>
                  </OverlayTrigger> */}
                </Form.Label>

                <Form.Control
                  className="form-control"
                  required
                  type="username"
                  placeholder="Mitarbeitenden-ID"
                  onChange={(text) => {
                    this.handleUsername(text);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte geben Sie Ihre Mitarbeitenden-ID ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupPassword">
                <Row>
                  <Col sm="2">
                    <Form.Label className="">Passwort</Form.Label>
                  </Col>
                  <Col>
                    <div className="" variant="" onClick={this.showPassword}>
                      {this.state.hiddenPw ? (
                        <BiShow className="hideShowPw" id="show" />
                      ) : (
                        <BiHide className="hideShowPw" id="hide" />
                      )}
                    </div>
                  </Col>
                </Row>
                <Form.Control
                  className="form-control"
                  required
                  type={this.state.hiddenPw ? "password" : "text"}
                  placeholder="Passwort"
                  onChange={(text) => {
                    this.handlePassword(text);
                  }}
                />
              </Form.Group>
              <OverlayTrigger
                overlay={
                  <Tooltip className="tooltip">
                    Wenn Sie Ihre Zugangsdaten vergessen haben, wenden Sie sich
                    an Ihre*n Administrator*in.
                  </Tooltip>
                }
              >
                <Button variant="link" readOnly>
                  Zugangsdaten vergessen?
                </Button>
              </OverlayTrigger>
              <div className="d-grid gap-2">
                <Button variant="primary" type="submit">
                  Anmelden
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <Footer>{"clinician"}</Footer>
      </Container>
    );
  }
}

export default LoginClinician;
