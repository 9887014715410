/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import { BsBellFill, BsFillPersonFill } from "react-icons/bs";
import { IoMdInformationCircle } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import { PubSub } from "../../helper/pubSub";

const SidebarAdmin = (props) => {
  const { state } = props;
  const { handleAccordion } = props;

  const location = useLocation();
  const informationPaths = [
    "/admin/information",
    "/impressum",
    "/privacypolicy",
    "/contact",
  ]; //all paths belonging to information section. For these paths the menu stays open
  let information = false;

  if (informationPaths.includes(location.pathname)) {
    information = true;
  }

  const [display, setDisplay] = useState({
    patients: true,
    employees: false,
    clinic: false,
    information,
  });

  const closeAllAccordions = () => {
    console.log("Called closeAllAccordions");
    setDisplay((prevState) => ({
      ...prevState,
      patients: false,
      employees: false,
      clinic: false,
      information: false,
    }));
    handleAccordion("closeAll");
  };

  const handlePatients = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patients: !display.patients,
      employees: false,
      clinic: false,
      information: false,
    }));
    handleAccordion("patients");
  };

  const handleEmployees = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patients: false,
      employees: !display.employees,
      clinic: false,
      information: false,
    }));
    handleAccordion("employees");
  };

  const handleClinic = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patients: false,
      employees: false,
      clinic: !display.clinic,
      information: false,
    }));
    handleAccordion("clinic");
  };

  const handleInformation = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patients: false,
      employees: false,
      clinic: false,
      information: !display.information,
    }));
    handleAccordion("info");
  };

  const renderPatientsItem = () => (
    <li
      className={display.patients ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handlePatients}
    >
      <a className="sidenav-item-link">
        <i className="mdi">
          {/* <BsBellFill /> */}
          <img
            className="rounded-circle user-role"
            src="../img/user/patient.png"
            width="25px"
            alt="P"
          />
        </i>
        <span className="nav-text">Patient*innen</span> <b className="caret" />
      </a>
    </li>
  );

  const renderEmployeesItem = () => (
    <li
      className={display.employees ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleEmployees}
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        aria-expanded="false"
      >
        <i className="mdi">
          {" "}
          {/* <BsFillPersonFill /> */}
          <img
            className="rounded-circle user-role"
            src="../img/user/clinician.png"
            width="25px"
            alt="M"
          />
        </i>
        <span className="nav-text">Mitarbeitende</span> <b className="caret" />
      </a>
    </li>
  );

  const renderClinicItem = () => (
    <li
      className={display.clinic ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleClinic}
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        aria-expanded="false"
      >
        <i className="mdi">
          {" "}
          {/* <BsFillPersonFill /> */}
          <img
            className="rounded-circle user-role"
            src="../img/user/hospital.png"
            width="25px"
            alt="K"
          />
        </i>
        <span className="nav-text">Klinik</span> <b className="caret" />
      </a>
    </li>
  );

  const renderInformationSubMenu = () => (
    <ul
      className={display.information ? "show" : "collapse"}
      id="documentation"
      data-parent="#sidebar-menu"
    >
      <div className="sub-menu p-0">
        <li className="">
          <Link
            to={{
              pathname: "/admin/information",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">RehaCAT</span>
          </Link>
          {/* <a className="sidenav-item-link" href="rtl.html">
            <span className="nav-text">RehaCAT</span>
          </a> */}
        </li>

        <li className="">
          <Link
            to={{
              pathname: "/impressum",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Impressum</span>
          </Link>
          {/* <a className="sidenav-item-link" href="/impressum">
            <span className="nav-text">Impressum</span>
          </a> */}
        </li>
        <li className="">
          <Link
            to={{
              pathname: "/privacypolicy",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Datenschutz</span>
          </Link>
          {/* <a className="sidenav-item-link" href="/privacypolicy">
            <span className="nav-text">Datenschutz</span>
          </a> */}
        </li>
        <li className="">
          <Link
            to={{
              pathname: "/contact",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Kontakt</span>
          </Link>
          {/* <a className="sidenav-item-link" href="/contact">
            <span className="nav-text">Kontakt</span>
            <span className="badge badge-success">Mail</span>
          </a> */}
        </li>
      </div>
    </ul>
  );

  const renderInformation = () => (
    <li
      className={display.information ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleInformation}
      id="clinic-stuff-sidebar-information"
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        data-target="#documentation"
        aria-expanded="false"
        aria-controls="documentation"
      >
        <i className="mdi">
          {/* <BsExclamationCircleFill /> */}
          <IoMdInformationCircle />
        </i>
        <span className="nav-text">Informationen</span>{" "}
        <b className="caret"></b>
      </a>
      {renderInformationSubMenu()}
    </li>
  );

  useEffect(() => {
    setDisplay((prevState) => ({
      ...prevState,
      patients: state.accordionPatients === "0",
      employees: state.accordionEmployees === "1",
      clinic: state.accordionClinic === "2",
    }));
  }, [state]);

  useEffect(() => {
    //runs only once when component is initialized because of the empty array as second argument
    PubSub.getInstance().subscribeToTopic("close-sidebar-submenus", () => {
      /* eslint-disable react-hooks/exhaustive-deps */
      closeAllAccordions();
    });
  }, []);

  return (
    <div>
      <div id="sidebar" className="sidebar sidebar-with-footer">
        <div data-simplebar height="100%">
          <ul className="nav sidebar-inner" id="sidebar-menu">
            {renderPatientsItem()}
            {renderEmployeesItem()}
            {renderClinicItem()}
            <hr className="separator mb-0" />
            {renderInformation()}
          </ul>
        </div>
        <div className="sidebar-footer sidebar-footer-content">
          <a
            href="https://www.uni-ulm.de/in/abt-klinische-psychologie-und-psychotherapie/"
            target="blank"
          >
            <img src="../img/Logo_uulm.png" width="160px" alt="Logo Uni Ulm" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default SidebarAdmin;
