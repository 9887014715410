import React, { Component } from "react";
import {
  Row,
  Form,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Nav,
  Navbar,
  NavDropdown,
  Col,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { BiClinic, BiLock, BiShow, BiHide } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import { Link } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { showAlertModal } from "../../helper/alertBoxes";
import { PubSub } from "../../helper/pubSub";
import { setPassword } from "../../services/postFunctions";

/**
 * Navigation Bar
 */
class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      modalOpen: false,
      hiddenOldPw: true,
      hiddenNewPw: true,
      hiddenNewConfirmedPw: true,
      old_password: "",
      new_password: "",
      confirmed_new_password: "",
      childrenPathname: "",
    };
  }

  componentDidMount() {
    console.log("Geladen", window.location.pathname);
    this.setState({ childrenPathname: window.location.pathname });
  }

  /**Set modalOpen on true, if the modal is opened. */
  openModal = () => this.setState({ modalOpen: true });

  /**Set modalOpen on false, if the modal is closed. */
  closeModal = () =>
    this.setState({
      modalOpen: false,
      old_password: "",
      new_password: "",
      confirmed_new_password: "",
    });

  /**Change hiddenPw state on true or false. */
  showPassword = (event) => {
    event.preventDefault();
    console.log("Event: ", event);
    switch (event.target.id) {
      case "show1":
        this.setState({ hiddenOldPw: false });
        break;
      case "hide1":
        this.setState({ hiddenOldPw: true });
        break;
      case "show2":
        this.setState({ hiddenNewPw: false });
        break;
      case "hide2":
        this.setState({ hiddenNewPw: true });
        break;
      case "show3":
        this.setState({ hiddenNewConfirmedPw: false });
        break;
      case "hide3":
        this.setState({ hiddenNewConfirmedPw: true });
        break;
      default:
        break;
    }
  };

  /**Method to update the old_password state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleOldPassword(text) {
    this.setState({ old_password: text.target.value });
  }

  /**Method to update the new_password state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleNewPassword(text) {
    this.setState({ new_password: text.target.value });
  }

  /**Method to update the confirmed_new_password state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleConfirmedNewPassword(text) {
    this.setState({ confirmed_new_password: text.target.value });
  }

  /**Method for handle the password change.
   * If the new_password === confirmed_new_password, then the input data are stringified to JSON.
   * Then send JSON to backend api.
   */
  handlePasswordChange = (event) => {
    event.preventDefault();

    if (this.state.new_password !== this.state.confirmed_new_password) {
      showAlertModal(
        "Das neue Passwort und das bestätigte Passwort stimmen nicht überein."
      );
    } else {
      var passwordData = {
        new_password: this.state.new_password,
        old_password: this.state.old_password,
        confirmed_new_password: this.state.confirmed_new_password,
      };

      try {
        setPassword(passwordData)
          .then((response) => {
            console.log("Response:", response.status);
            showAlertModal("Das Passwort wurde erfolgreich geändert.");
            this.closeModal();
          })
          .catch((error) => {
            showAlertModal(
              "Irgendetwas ist schief gelaufen. Ihr Passwort wurde nicht geändert."
            );
            console.error("Change Password Fail: ", error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**Logout handle method. */
  handleLogout = () => {
    AuthService.logout();
  };

  /**React render method. */
  render() {
    return (
      <>
        <Navbar fixed="top" className="nav-custom">
          <div className="app-brand">
            <Link
              to={{
                pathname:
                  this.props.children[3] === "clinician"
                    ? "/clinician"
                    : this.props.children[3] === "admin"
                    ? "/admin"
                    : "/patients",
                state: {
                  clinic_id: this.props.children[0],
                  clinic_name: this.props.children[1],
                  username: this.props.children[2],
                },
              }}
              onClick={() => {
                PubSub.getInstance().publishToTopic("open-sidebar-submenu", {
                  menuName: "current",
                });
                PubSub.getInstance().publishToTopic("close-sidebar-submenus", {
                  closeAll: true,
                });
              }}
            >
              <img
                src="../img/rehacat.png"
                alt="Rehacat Logo"
                width="auto"
                height="auto"
              />
            </Link>
          </div>

          <NavbarCollapse id="basic-navbar-nav ">
            <Nav className="ml-auto">
              <NavDropdown
                align={"end"}
                className="nav-link navbar-dropdown"
                title={
                  <span>
                    <i className="mdi">
                      <FaUserCircle />
                    </i>
                    <span> {this.props.children[2]}</span>
                  </span>
                }
              >
                <NavDropdown.Item>
                  <BiClinic /> Klinik: {this.props.children[1]}
                </NavDropdown.Item>
                {this.props.children[3] === "clinician" ||
                this.props.children[3] === "admin" ? (
                  <NavDropdown.Item onClick={this.openModal}>
                    <BiLock /> Passwort ändern
                  </NavDropdown.Item>
                ) : null}

                <NavDropdown.Item onClick={this.handleLogout}>
                  <GoSignOut /> Abmelden
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </NavbarCollapse>
        </Navbar>
        <Modal
          // size="md"
          backdrop="static"
          show={this.state.modalOpen}
          onHide={this.closeModal}
        >
          <ModalHeader closeButton>
            <ModalTitle>Passwort ändern</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Form noValidate onSubmit={this.handlePasswordChange}>
              <Form.Group as={Row} className="mb-3">
                <Col sm="4">
                  <Form.Label>Altes Passwort</Form.Label>
                </Col>
                <Col sm="6">
                  <Form.Control
                    className="form-control"
                    type={this.state.hiddenOldPw ? "password" : "text"}
                    value={this.state.old_password}
                    onChange={(text) => {
                      this.handleOldPassword(text);
                    }}
                  ></Form.Control>
                </Col>
                <Col>
                  <div className="" variant="" onClick={this.showPassword}>
                    {this.state.hiddenOldPw ? (
                      <BiShow className="hideShowPw hideShowNav" id="show1" />
                    ) : (
                      <BiHide className="hideShowPw hideShowNav" id="hide1" />
                    )}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Col sm="4">
                  <Form.Label>Neues Passwort</Form.Label>
                </Col>
                <Col sm="6">
                  <Form.Control
                    className="form-control"
                    type={this.state.hiddenNewPw ? "password" : "text"}
                    value={this.state.new_password}
                    onChange={(text) => {
                      this.handleNewPassword(text);
                    }}
                  />
                  <Form.Text className="text-muted">
                    Mind. 10 Zeichen. Mind. 1 Kleinbuchstabe, 1 Großbuchstabe
                    und 1 Zahl.
                  </Form.Text>
                </Col>
                <Col sm="2">
                  <div className="" variant="" onClick={this.showPassword}>
                    {this.state.hiddenNewPw ? (
                      <BiShow className="hideShowPw hideShowNav" id="show2" />
                    ) : (
                      <BiHide className="hideShowPw hideShowNav" id="hide2" />
                    )}
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Col sm="4">
                  <Form.Label>Neues Passwort bestätigen</Form.Label>
                </Col>
                <Col sm="6">
                  <Form.Control
                    className="form-control"
                    type={this.state.hiddenNewConfirmedPw ? "password" : "text"}
                    value={this.state.confirmed_new_password}
                    onChange={(text) => {
                      this.handleConfirmedNewPassword(text);
                    }}
                  />
                </Col>
                <Col sm="2">
                  <div className="" variant="" onClick={this.showPassword}>
                    {this.state.hiddenNewConfirmedPw ? (
                      <BiShow className="hideShowPw hideShowNav" id="show3" />
                    ) : (
                      <BiHide className="hideShowPw hideShowNav" id="hide3" />
                    )}
                  </div>
                </Col>
              </Form.Group>
              <Button type="submit">Ändern</Button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button variant="danger" onClick={this.closeModal}>
              Abbrechen
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default NavigationBar;
