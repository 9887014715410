import React from "react";
import { Component } from "react";
import {
  Accordion,
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Row,
  Spinner,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import DatePicker from "react-datepicker";
import {
  getProvisionLevel,
  adminGetPatients,
  adminGetClinicians,
  getDiagnoses,
  activateClinician,
  deactivateClinician,
  getClinicianReportsForPatient,
  adminResetClinicianPassword,
} from "../../services/getFunctions";
import AuthService from "../../services/AuthService";
import NavigationBar from "../navigation/NavigationBar";
import SidebarAdmin from "../sidebars/SidebarAdmin";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { FiEdit, FiUpload } from "react-icons/fi";
import { BsPeople, BsCheckCircle } from "react-icons/bs";
import { CgUserList } from "react-icons/cg";
import { BiLock, BiBlock } from "react-icons/bi";
import ErrorBoundary from "../../ErrorBoundary";
import Footer from "../Footer";
import { GoChecklist } from "react-icons/go";
import TimeoutSession from "../../helper/TimeoutSession";
import {
  reportGetProvisionLevelError,
  reportGetPatientListError,
  reportGetClinicianListError,
  reportGetDiagnosesListError,
  reportNewPasswordError,
  reportDisableEnableClinicianError,
  reportEditPatientError,
  reportTransferPatientError,
  reportEditClinicianError,
  reportClinicianAssignError,
  reportRegistPatientError,
  reportRegistClinicianError,
  reportCsvValidationError,
  reportGetStwsError,
  reportGetPatientReportsError,
  showPasswordModal,
  showNewClinicianPasswordModal,
  showAlertModal,
} from "../../helper/alertBoxes";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { clinicianGetSurveyTimeWindows } from "../../services/getFunctions";
import {
  editPatient,
  editClinician,
  adminAssignClinician,
  transferPatients,
  bulkImport,
} from "../../services/postFunctions";

/**Clinic admins home component contains the dashboard methods and the regist methods for patients and clinicians. */
var reportCnt = 0;
var countPatCnt = 0;
class HomeClinicAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "admin",
      provisionLevel: "",
      content: "",
      modalOpen: false,
      modalBulkOpen: false,
      modalClinicianOpen: false,
      modalAssignClinicianOpen: false,
      modalEditPatientOpen: false,
      modalEditClinicianOpen: false,
      modalShowPatientListOpen: false,
      modalTransferPatientsOpen: false,
      modalDiagnosisOpen: false,
      modalSurveyStateOpen: false,
      modalValidOpen: false,
      admission: new Date(),
      editAdmission: new Date(),
      editDischarge: new Date(),
      editBirthday: new Date(),
      clinician: "",
      code: "",
      birthday: new Date("01/01/1990"),
      diagnosis: "",
      diagnosisPatient: [],
      discharge: new Date(),
      gender: "",
      genderList: [
        { value: "FEMALE", name: "Weiblich" },
        { value: "MALE", name: "Männlich" },
        { value: "DIVERSE", name: "Divers" },
      ],
      clinicians: [],
      diagnoses: [],
      csv: null,
      patients: [],
      usernameClinician: "",
      username: "",
      clinic_id: "",
      clinic_name: "",
      patientList: [],
      surveyStatus: [],
      reports: [],
      reportsParallel: [],
      stwsParallel: [],
      stws: [],
      patient_id: "",
      clinician_from_id: "",
      clinician_username: "",
      clinician_enabled: true,
      addDiagnosisField: 0,
      accordionPatients: "-1",
      accordionEmployees: "-1",
      accordionClinic: "-1",
      actualPatient: "",
      modalLoadingOpen: true,
      isLoading: true,
      countData: 0,
      bulkValidation: false,
      bulkData: [],
      editPatientButton: "",
      updatePatients: false,
      bulkImportButton: "",
      today: new Date().toISOString().split("T")[0],
      currentPatient: "",
    };
    this.handleCode = this.handleCode.bind(this);
    this.handleBirthdate = this.handleBirthdate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGender = this.handleGender.bind(this);
    this.handleClinician = this.handleClinician.bind(this);
    this.handleDiagnosis = this.handleDiagnosis.bind(this);
    this.handleAdmissionDate = this.handleAdmissionDate.bind(this);
    this.handleBulkImport = this.handleBulkImport.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.fileInput = React.createRef();
  }

  /**Method for loading the clinician, patient and diagnoses lists from backend API. */
  componentDidMount() {
    console.log("Gender Liste: ", this.state.genderList);
    if (this.props.location.state === undefined) {
      console.warn("Props Location Fail");
      ErrorBoundary.getDerivedStateFromError(Error);
    } else {
      this.setState({
        clinic_id: this.props.location.state.clinic_id,
        clinic_name: this.props.location.state.clinic_name,
        username: this.props.location.state.username,
        provisionLevel: this.props.location.state.provisionLevel,
      });
      if (this.props.location.state.accordionPatients) {
        this.setState({
          accordionPatients: this.props.location.state.accordionPatients,
          accordionEmployees: this.props.location.state.accordionEmployees,
          accordionClinic: this.props.location.state.accordionClinic,
        });
      }
    }

    if (this.state.provisionLevel === "") {
      try {
        getProvisionLevel()
          .then((provisionLevel) => {
            console.info("Provision-Level: ", provisionLevel);
            this.setState({ provisionLevel: provisionLevel });
          })
          .catch((error) => {
            reportGetProvisionLevelError(error);
          });
      } catch (error) {}
    }

    try {
      adminGetPatients()
        .then((patientList) => {
          console.info("Patientenliste: ", patientList);
          this.setState({ patients: patientList.list_patient_dto });
          this.setState((prev) => {
            return { countData: prev.countData + 1 };
          });
          this.customizePatientList();
          this.closeLoadingModal();
        })
        .catch((error) => {
          reportGetPatientListError(error);
        });
    } catch (error) {}

    try {
      adminGetClinicians()
        .then((clinicianList) => {
          console.info("Mitarbeiter-Liste: ", clinicianList.clinicians);
          console.info("Liste: ", clinicianList);
          this.setState({ clinicians: clinicianList.clinicians });
          if (clinicianList.clinicians.length === 0) {
            this.closeLoadingModal();
          }
          this.setState((prev) => {
            return { countData: prev.countData + 1 };
          });
          this.countPatients();
        })
        .catch((error) => {
          reportGetClinicianListError(error);
        });
    } catch (error) {}

    try {
      getDiagnoses()
        .then((diagnoses) => {
          console.log("Diagnosen-Liste: ", diagnoses);
          this.setState({ diagnoses: diagnoses.diagnosis });
        })
        .catch((error) => {
          reportGetDiagnosesListError(error);
        });
    } catch (error) {}
  }

  /**Set modalOpen on true, if the modal is opened. */
  openModal = () =>
    this.setState({
      modalOpen: true,
    });
  openBulkModal = () => this.setState({ modalBulkOpen: true });
  openValidModal = () =>
    this.setState({ modalValidOpen: true, validation: false });
  openClinicianModal = () => this.setState({ modalClinicianOpen: true });
  openDiagnosisModal = () => this.setState({ modalDiagnosisOpen: true });
  openLoadingModal = () => this.setState({ modalLoadingOpen: true });

  /**Set modalAssignClinicianOpen on true, if the modal is opened.
   * Set patient_id and clinician_from.
   */
  openAssignClinicianModal = (event) => {
    event.preventDefault();
    var code = event.target.id;
    var patient;
    for (var i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].code === code) {
        patient = this.state.patients[i];
      }
    }
    this.setState({
      modalAssignClinicianOpen: true,
      patient_id: patient.id,
      code: patient.code,
      clinician_from_id: patient.clinician_id,
      clinician_from_username: patient.clinician_username,
    });
  };

  /**Set modalEditPatientOpen on true, if the modal is opened.
   * Set the actual states of the selected patient.
   */
  openEditPatientModal = (event) => {
    event.preventDefault();
    var code = event.target.id;
    var patient;
    var gender;
    for (var i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].code === code) {
        patient = this.state.patients[i];
      }
    }
    var birthdaySplit = patient.date_of_birth.split(".");
    var newBirthday = new Date(
      birthdaySplit[2] + "-" + birthdaySplit[1] + "-" + birthdaySplit[0]
    );
    var admissionSplit = patient.admission.split(".");
    var newAdmission = new Date(
      admissionSplit[2] + "-" + admissionSplit[1] + "-" + admissionSplit[0]
    );
    var dischargeSplit = patient.discharge.split(".");
    var newDischarge = new Date(
      dischargeSplit[2] + "-" + dischargeSplit[1] + "-" + dischargeSplit[0]
    );
    if (patient.gender === "Weiblich") {
      gender = "FEMALE";
    } else if (patient.gender === "Männlich") {
      gender = "MALE";
    } else if (patient.gender === "Divers") {
      gender = "DIVERSE";
    }

    this.setState({
      modalEditPatientOpen: true,
      editBirthday: newBirthday,
      editAdmission: newAdmission,
      editDischarge: newDischarge,
      clinician_id: patient.clinician_id,
      clinician_username: patient.clinician_username,
      diagnosisPatient: patient.diagnoses,
      patient_id: patient.id,
      gender: gender,
      code: patient.code,
    });

    console.info("Diagnose(n): ", patient.diagnoses);
  };

  /**Set modalEditClinicianOpen on true, if the modal is opened.
   * Set the actual states of the selected clinician.
   */
  openEditClinicianModal = (event) => {
    event.preventDefault();
    var index = event.target.id;
    var clinician = this.state.clinicians[index];

    this.setState({
      modalEditClinicianOpen: true,
      clinician_enabled: clinician.enabled,
      clinician_id: clinician.id,
      clinician_username: clinician.username,
    });
  };

  /**Set modalShowPatientListOpen on true, if the modal is opened.
   * Create the patient list for the current clinician.
   */
  openShowPatientListMOdal = (event) => {
    event.preventDefault();
    var index = event.target.id;
    var clinician_username = this.state.clinicians[index].username;
    var patientList = [];
    for (var i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].clinician_username === clinician_username) {
        patientList.push(this.state.patients[i].code);
      }
    }
    this.setState({
      modalShowPatientListOpen: true,
      clinician_username: clinician_username,
      patientList: patientList,
    });
  };

  /**Set modalTransferPatientOpen on true, if the modal is opened.
   *
   */
  openTransferPatientsModal = (event) => {
    event.preventDefault();
    var index = event.target.id;
    var clinician = this.state.clinicians[index];
    var patientList = [];
    for (var i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].clinician_username === clinician.username) {
        patientList.push([
          this.state.patients[i].code,
          this.state.patients[i].id,
        ]);
      }
    }

    this.setState({
      modalTransferPatientsOpen: true,
      clinician_from_id: clinician.id,
      clinician_from_username: clinician.username,
      patientList: patientList,
    });
  };

  openSurveyStateModal = (event) => {
    event.preventDefault();
    var code = event.target.id;
    var patient;
    var index;
    for (var i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].code === code) {
        patient = this.state.patients[i];
        index = i;
      }
    }
    this.setState({ modalSurveyStateOpen: true, currentPatient: patient.code });
    this.loadReportsAndStws(patient, index);
    console.log("Event: ", event.target);
  };

  /**Set modalOpen on false, if the modal is closed. */
  closeModal = () =>
    this.setState({
      modalOpen: false,
      code: "",
      value: "",
      gender: "",
      diagnosis: "",
      clinician: "",
      birthday: new Date("01/01/1990"),
      admission: new Date(),
      discharge: new Date(),
    });
  closeBulkModal = () =>
    this.setState({
      modalBulkOpen: false,
      bulkValidation: false,
      validation: false,
    });
  closeValidModal = () => this.setState({ modalValidOpen: false });
  closeClinicianModal = () => this.setState({ modalClinicianOpen: false });
  closeAssignClinicianModal = () =>
    this.setState({ modalAssignClinicianOpen: false, code: "" });
  closeEditPatientModal = () =>
    this.setState({ modalEditPatientOpen: false, code: "", gender: "" });
  closeDiagnosisModal = () => this.setState({ modalDiagnosisOpen: false });
  closeEditClinicianModal = () =>
    this.setState({
      modalEditClinicianOpen: false,
      clinician_id: "",
      clinician_username: "",
    });

  closeShowPatientListModal = () =>
    this.setState({ modalShowPatientListOpen: false });
  closeTransferPatientsModal = () =>
    this.setState({ modalTransferPatientsOpen: false });

  closeLoadingModal = () => this.setState({ modalLoadingOpen: false });

  closeSurveyStateModal = () =>
    this.setState({ modalSurveyStateOpen: false, surveyStatus: [] });

  updatePatientOverview = (event) => {
    event.preventDefault();
    this.setState({ updatePatients: true.valueOf, isLoading: true });
    this.componentDidMount();
  };

  /**Method to update the edited bithdate state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditBirthdate(date) {
    this.setState({ editBirthday: date });
    console.info("Birthday: ", this.state.editBirthday);
  }

  /**Method to update the edited admission date state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditAdmissionDate(date) {
    showAlertModal(
      "Achtung: Durch das Ändern von Aufnahme- oder Entlassdatum können bisherige Befragungen ungültig werden."
    );
    this.setState({ editAdmission: date });
  }

  /**Method to update the edited discharge date state, when it is changed.
   * @param date {date} date from datepicker or date input.
   */
  handleEditDischargeDate(date) {
    if (date <= this.state.editAdmission) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      showAlertModal(
        "Achtung: Durch das Ändern von Aufnahme- oder Entlassdatum können bisherige Befragungen ungültig werden."
      );
      this.setState({ editDischarge: date });
    }
  }

  handleClinicianUsername(text) {
    console.info("Username: ", text.target.value);
    this.setState({ clinician_username: text.target.value });
  }

  /**Method to update the birthdate state, when it is changed.
   * @param date {date} date from datepicker or date input
   */
  handleBirthdate(date) {
    this.setState({ birthday: date });
  }

  /**Method to update the code state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleCode(text) {
    console.log("Code", this.state.code, " ausgefüllt");
    this.setState({ code: text.target.value });
  }

  /**Method to update the gender state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the selected gender
   */
  handleGender(text) {
    if (text.target.value === "Auswählen...") {
      this.setState({ gender: "" });
    } else {
      console.log("Gender: ", text.target.value);
      this.setState({ gender: text.target.value });
    }
  }

  /**Method to update the clinician state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the selected clinician
   */
  handleClinician(text) {
    if (text.target.value === "Auswählen...") {
      this.setState({ clinician: "" });
    } else {
      this.setState({ clinician: text.target.value });
    }
  }

  /**Method to update the diagnosis state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the slected diagnosis
   */
  handleDiagnosis(text) {
    console.log("Diagnose: ", text.target.value);
    if (text.target.value === "Auswählen...") {
      this.setState({ diagnosis: "" });
    } else {
      this.setState({ diagnosis: text.target.value });
    }
  }

  /**Method to update the admission date state, when it is changed.
   * @param date {date} date from datepicker or date input
   */
  handleAdmissionDate(date) {
    this.setState({ admission: date });
  }

  /**Method to update the discharge date state, when it is changed.
   * @param date {date} date from datepicker or date input
   */
  handleDischargeDate(date) {
    if (date <= this.state.admission) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      this.setState({ discharge: date });
    }
  }

  /**Method to update the clinician username state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed clinician username
   */
  handleUsername(text) {
    this.setState({ usernameClinician: text.target.value });
  }

  handleAccordion = (input) => {
    if (input === "patients") {
      this.state.accordionPatients === "-1"
        ? this.setState({ accordionPatients: "0" })
        : this.setState({ accordionPatients: "-1" });
      this.setState({ accordionEmployees: "-1", accordionClinic: "-1" });
    } else if (input === "employees") {
      this.state.accordionEmployees === "-1"
        ? this.setState({ accordionEmployees: "1" })
        : this.setState({ accordionEmployees: "-1" });
      this.setState({ accordionPatients: "-1", accordionClinic: "-1" });
    } else if (input === "clinic") {
      this.state.accordionClinic === "-1"
        ? this.setState({ accordionClinic: "2" })
        : this.setState({ accordionClinic: "-1" });
      this.setState({ accordionPatients: "-1", accordionEmployees: "-1" });
    }
  };

  /**Method to transfer all patients from one clinician to another clinician. */
  transferPatients = (event) => {
    event.preventDefault();
    if (this.state.clinician !== "") {
      var clinicianFrom = this.state.clinician_from_id;
      var clinicianTo = this.state.clinician;

      try {
        //todo this call may be wrong. I'm just not completely sure how this.state.patientList
        //looks like. I'm expecting it to be an array of strings each representing a patient code
        transferPatients(this.state.patientList, clinicianFrom, clinicianTo)
          .then((responses) => {
            console.log("Patients transfer parallel: ", responses);
            showAlertModal(
              "Die Patient*innen wurden erfolgreich an den*die Mitarbeitende*n " +
                this.state.clinician +
                " übertragen."
            );
            this.closeTransferPatientsModal();
            this.componentDidMount();
          })
          .catch((error) => {
            showAlertModal(
              "Irgendetwas ist schief gelaufen. Die Patient*innen wurden nicht übertragen."
            );
            reportTransferPatientError(error);
          });
      } catch (e) {}
    } else {
      showAlertModal(
        "Sie müssen noch eine*n Mitarbeitende*n auswählen, an welchen die Patient*innen übertragen werden sollen."
      );
    }
  };

  /**Method to reset clinicians password. */
  resetPassword = (event) => {
    event.preventDefault();
    var index = event.target.id;
    var clinician_id = this.state.clinicians[index].id;
    console.log("Mitarbeiter ID: ", clinician_id);

    try {
      adminResetClinicianPassword(clinician_id)
        .then((password) => {
          showPasswordModal(password);
        })
        .catch((error) => {
          reportNewPasswordError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Method to enable or disable a clinician.
   */
  disableEnableClinician = (event) => {
    event.preventDefault();
    console.log("Disable Event: ", event);
    var index = event.target.id;
    var clinician_id = this.state.clinicians[index].id;
    var clinician_username = this.state.clinicians[index].username;
    console.log("Mitarbeiter: ", clinician_id);
    console.log("/api/auth/clinicans/" + clinician_id + "/deactivation");
    var str;

    let activationDeactivationFunction;
    if (event.target.name === "enable") {
      activationDeactivationFunction = activateClinician;
      str = "aktiviert";
    } else {
      activationDeactivationFunction = deactivateClinician;
      str = "deaktiviert";
    }

    try {
      activationDeactivationFunction(clinician_id)
        .then((response) => {
          console.log("Response:", response.status);
          showAlertModal(
            "Der*Die Mitarbeitende " +
              clinician_username +
              " wurde erfolgreich " +
              str +
              "."
          );
          this.componentDidMount();
        })
        .catch((error) => {
          reportDisableEnableClinicianError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Method for handle edit clinician.
   * The input data are stringified to JSON.
   * Then send JSON to backend api.
   */
  handleEditClinician = (event) => {
    event.preventDefault();

    var clinicianData = {
      enabled: this.state.clinician_enabled,
      id: this.state.clinician_id,
      username: this.state.clinician_username,
    };

    try {
      editClinician(clinicianData)
        .then((response) => {
          console.log("Response:", response.status);
          showAlertModal("Der Benutzername wurde erfolgreich geändert.");
          this.closeEditClinicianModal();
          this.componentDidMount();
        })
        .catch((error) => {
          reportEditClinicianError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Method for handle clinician assign.
   * The input data are stringified to JSON.
   * Then send JSON to backend api.
   */
  handleClinicianAssign = (event) => {
    event.preventDefault();

    var assignmentData = {
      patient_id: this.state.patient_id,
      clinician_from: this.state.clinician_from_id,
      clinician_to: this.state.clinician,
    };

    try {
      adminAssignClinician(assignmentData)
        .then((response) => {
          console.log("JSON: ", assignmentData);
          console.log("Response:", response.status);
          showAlertModal(
            "Dem Patienten wurde erfolgreich ein neuer Mitarbeiter zugewiesen."
          );
          this.closeAssignClinicianModal();
          this.componentDidMount();
        })
        .catch((error) => {
          reportClinicianAssignError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**Method for handle patients diagnoses.
   * Each diagnoses that is selected is written to the diagnosesPatient list.
   * If no diagnosis is selected, an alert is displayed.
   */
  handleEditDiagnosesList = (event) => {
    event.preventDefault();
    console.log("Event: ", event);
    var diagnoses = [];
    for (var i = 0; i < event.target.length; i++) {
      console.log("Event an der Stelle ", i, event.target[i]);
      if (
        event.target[i].type === "checkbox" &&
        event.target[i].checked === true
      ) {
        console.log("True: ", event.target[i]);
        diagnoses.push({
          description: event.target[i].dataset.name,
          id: event.target[i].value,
        });
      }
    }
    console.log("Liste: ", diagnoses);
    if (diagnoses.length === 0) {
      showAlertModal("Sie müssen mindestens eine Diagnose wählen.");
    } else {
      this.setState({ diagnosisPatient: diagnoses });
      this.closeDiagnosisModal();
    }
  };

  /**Method for handle  patient edit.
   */
  handleEditPatient = (event) => {
    event.preventDefault();
    if (this.state.editDischarge <= this.state.editAdmission) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      var patientData = {
        date_of_birth: this.state.editBirthday.toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        admission: this.state.editAdmission.toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        discharge: this.state.editDischarge.toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        clinician_id: this.state.clinician_id,
        clinician_username: this.state.clinician_username,
        diagnoses: this.state.diagnosisPatient,
        id: this.state.patient_id,
        gender: this.state.gender,
        code: this.state.code,
        created: "1990-01-01T00:00:00", //the backend wants this but the value will not be changed
      };

      console.log("JSON: ", patientData);

      try {
        editPatient(patientData)
          .then((response) => {
            console.log("JSON: ", patientData);
            console.log("Patient bearbeitet:", response.status);
            this.closeEditPatientModal();
            this.componentDidMount();
          })
          .catch((error) => {
            reportEditPatientError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**
   * Method for handle the patient regist.
   * The input data are stringified to JSON.
   * Then send JSON to backend API.
   */
  handleSubmit = (event) => {
    event.preventDefault();
    console.log("Button: ", this.state.editPatientButton);
    console.log(
      "Mitarbeitername: ",
      this.state.clinician,
      "Code: ",
      this.state.code,
      "Geschlecht: ",
      this.state.gender,
      "Diagnose: ",
      this.state.diagnosis
    );

    var admission = this.state.admission.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    var discharge = this.state.discharge.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    console.log(
      "Aufnahmedatum: ",
      this.state.admission.toISOString().split("T")[0],
      ", Entlassdatum: ",
      this.state.discharge.toISOString().split("T")[0]
    );
    if (this.state.code === "") {
      showAlertModal("Sie müssen eine Reha-ID angeben.");
    } else if (this.state.gender === "") {
      showAlertModal("Sie müssen ein Geschlecht auswählen.");
    } else if (this.state.clinician === "") {
      showAlertModal("Sie müssen eine*n Mitarbeitende*n auswählen.");
    } else if (this.state.diagnosis === "") {
      showAlertModal("Sie müssen eine Diagnose auswählen.");
    } else if (
      this.state.discharge.toISOString().split("T")[0] <=
      this.state.admission.toISOString().split("T")[0]
    ) {
      showAlertModal("Das Entlassdatum muss nach dem Aufnahmedatum liegen.");
    } else {
      var json = JSON.stringify({
        admission: admission,
        clinician_username: this.state.clinician,
        code: this.state.code,
        day_of_birth: this.state.birthday.toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        diagnosis_id: this.state.diagnosis,
        discharge: discharge,
        gender: this.state.gender,
      });

      console.log("JSON: ", json);

      try {
        AuthService.regist(json, "/api/auth/patients/registration")
          .then((response) => {
            console.log("JSON: ", json);
            console.log("Response:", response.status);
            // if (event.nativeEvent.submitter.name === "btn1") {
            if (this.state.editPatientButton === "btn1") {
              this.closeModal();
            } else {
              this.openModal();
            }

            this.setState({
              code: "",
              value: "",
              gender: "",
              diagnosis: "",
              clinician: "",
              birthday: new Date("01/01/1990"),
              admission: new Date(),
              discharge: new Date(),
            });

            this.componentDidMount();
          })
          .catch((error) => {
            reportRegistPatientError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**Method for handle the validation of patient bulk import.
   */
  validateBulkImport = (event) => {
    event.preventDefault();
    let file = this.fileInput.current.files[0];
    console.log("Datei: ", file);
    console.log("Event: ", event);
    console.log("Button Name: ", event.nativeEvent.submitter.name);

    if (file === undefined) {
      showAlertModal("Sie müssen zuerst eine Datei zur Überprüfung auswählen.");
    } else {
      this.setState({ validation: true });
      try {
        bulkImport(file)
          .then((response) => {
            console.log("Validierung: ", response.data);

            this.setState({ bulkData: response.data });
            if (response.data.valid === false) {
              console.log("Not valid");
              // alert("Die Datei enthält Fehler.");
            } else {
              this.setState({ bulkValidation: true });
            }
            this.openValidModal();
          })
          .catch((error) => {
            this.setState({ validation: false });
            reportCsvValidationError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**
   * Method for handle the patient bulk import.
   */
  handleBulkImport = (event) => {
    event.preventDefault();
    let file = this.fileInput.current.files[0];
    console.log("CSV: ", file);
    if (this.state.bulkImportButton === "validation") {
      this.validateBulkImport(event);
    } else {
      this.setState({ validation: false, importing: true });
      try {
        bulkImport(file)
          .then((response) => {
            console.log("Response: ", response);
            showAlertModal("Die Patient*innen wurden erfolgreich importiert.");
            this.setState({ bulkValidation: false, importing: false });
            this.closeBulkModal();
            this.componentDidMount();
          })
          .catch((error) => {
            this.setState({ importing: false });
            console.error(error);
            showAlertModal(
              "Irgendetwas ist schief gelaufen. Die Patient*innen wurden nicht importiert."
            );
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**Method for handle the clinician regist
   */
  handleClinicianRegist = (event) => {
    event.preventDefault();
    if (this.state.usernameClinician === "") {
      showAlertModal("Sie müssen einen Benutzernamen eingeben. ");
    } else {
      var json = JSON.stringify({
        username: this.state.usernameClinician,
      });
      console.log("JSON", json);

      try {
        AuthService.regist(json, "/api/auth/clinicians/registration")
          .then((response) => {
            console.log("Response: ", response);
            showNewClinicianPasswordModal(response.data);
            this.closeClinicianModal();
            this.componentDidMount();
          })
          .catch((error) => {
            reportRegistClinicianError(error);
          });
      } catch (e) {
        console.error("Catch", e);
        return;
      }
    }
  };

  /**The patient list is prepared for the bootstrap table with all needed attributes.*/
  customizePatientList() {
    if (this.state.patients.length !== 0) {
      let patSortList = [...this.state.patients];
      var patient;
      var gender;
      for (var i = 0; i < this.state.patients.length; i++) {
        patient = this.state.patients[i];
        if (patient.gender === "FEMALE") {
          gender = "Weiblich";
        } else if (patient.gender === "MALE") {
          gender = "Männlich";
        } else if (patient.gender === "DIVERSE") {
          gender = "Divers";
        }
        patSortList[i] = {
          ...this.state.patients[i],
          gender: gender,
          edit: (
            <DropdownButton
              key={patient.code}
              variant="outline-secondary"
              id="dropdown-basic-button"
              title={
                <i className="mdi">
                  <MdEdit />
                </i>
              }
            >
              <Dropdown.Item
                id={patient.code}
                onClick={this.openEditPatientModal}
              >
                <FiEdit /> Patient*in bearbeiten
              </Dropdown.Item>
              <Dropdown.Item
                id={patient.code}
                onClick={this.openAssignClinicianModal}
              >
                <BsPeople /> Mitarbeitende ändern
              </Dropdown.Item>
            </DropdownButton>
          ),
          state: (
            <Button id={patient.code} onClick={this.openSurveyStateModal}>
              Anzeigen
            </Button>
          ),
        };
      }
      this.setState({ patients: patSortList });
    }
    this.deleteDuplicates();
  }

  /**Method deletes duplicate patient entries with the same patient code. */
  deleteDuplicates() {
    if (this.state.patients.length !== 0) {
      console.log("Patienten: ", this.state.patients);
      let patSortList = [...this.state.patients];
      for (var i = 0; i < this.state.patients.length; i++) {
        for (var j = i + 1; j < this.state.patients.length - 1; j++) {
          if (
            this.state.patients[i].code === this.state.patients[j].code &&
            i !== j
          ) {
            console.log("Doppelt: ", this.state.patients[i].code);
            if (
              this.state.patients[i].created > this.state.patients[j].created
            ) {
              patSortList[j] = { duplicate: true };
              console.log(
                "Duplikat j: ",
                j,
                "i:",
                i,
                this.state.patients[j].created
              );
            } else {
              patSortList[i] = { duplicate: true };
              console.log(
                "Duplikat i: ",
                i,
                "j",
                j,
                this.state.patients[i].created
              );
            }
            console.log("Duplikatseintrag: ", patSortList[j]);
          }
        }
      }
      this.setState({ patients: patSortList });
      console.log("Sort List: ", patSortList);

      console.log("Liste mit neuem Attribut: ", patSortList);

      for (var k = 0; k < patSortList.length; k++) {
        if (patSortList[k].duplicate) {
          console.log("Duplikat an Stelle : ", k);
          patSortList.splice(k, 1);
        }
      }
      console.log("PatList: ", patSortList);
      this.setState({ patients: patSortList });
      console.log("Liste ohne Duplikate: ", this.state.patients);
    } else {
    }
    this.countPatients();
  }

  /**Method counts patients for every clinician and save them in clinicians list as attribute "patientCount". */
  countPatients() {
    countPatCnt++;
    console.log("Angepasste Patientenliste: ", this.state.patients);
    // if (
    //   this.state.patients.length !== 0 &&
    //   this.state.clinicians.length !== 0
    // ) {
    if (countPatCnt === 2) {
      countPatCnt = 0;
      console.log("Anzahl: ", this.state.countData);
      var clinPatList = [...this.state.clinicians];

      for (var i = 0; i < this.state.clinicians.length; i++) {
        var index = i;
        var clinician = this.state.clinicians[i];

        if (
          this.state.patients.length === 0 &&
          i === this.state.clinicians.length - 1
        ) {
          this.setState({ isLoading: false });
          this.closeLoadingModal();
        }
        var cnt = 0;

        for (var j = 0; j < this.state.patients.length; j++) {
          if (
            this.state.clinicians[i].username ===
            this.state.patients[j].clinician_username
          ) {
            cnt++;
          }
        }
        clinPatList[i] = {
          ...this.state.clinicians[i],
          password: "******",

          edit: (
            <DropdownButton
              key={index}
              variant="outline-secondary"
              id="dropdown-basic-button"
              title={
                <i className="mdi">
                  <MdEdit />
                </i>
              }
            >
              <Dropdown.Item id={index} onClick={this.openEditClinicianModal}>
                <FiEdit /> Mitarbeitenden-ID ändern
              </Dropdown.Item>
              <Dropdown.Item id={index} onClick={this.resetPassword}>
                <BiLock /> Passwort zurücksetzen
              </Dropdown.Item>
              <Dropdown.Item id={index} onClick={this.openShowPatientListMOdal}>
                <CgUserList /> Patient*innenliste anzeigen
              </Dropdown.Item>
              <Dropdown.Item
                id={index}
                onClick={this.openTransferPatientsModal}
              >
                <BsPeople /> Patient*innen transferieren
              </Dropdown.Item>

              {clinician.enabled ? (
                <Dropdown.Item
                  id={index}
                  name="disable"
                  onClick={this.disableEnableClinician}
                >
                  <BiBlock /> Mitarbeitende deaktivieren
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  id={index}
                  name="enable"
                  onClick={this.disableEnableClinician}
                >
                  <BsCheckCircle /> Mitarbeitende aktivieren
                </Dropdown.Item>
              )}
            </DropdownButton>
          ),
          patientCount: cnt,
        };
      }
      this.setState({ clinicians: clinPatList });
      console.log("Mitarbeiter: ", clinPatList);
    } else if (
      (this.state.patients.length === 0 &&
        this.state.clinicians.length === 0) ||
      (this.state.countData === 2 &&
        (this.state.clinicians.length === 0 ||
          this.state.patients.length === 0))
    ) {
      console.log("Anzahl: ", this.state.countData);
      this.setState({ isLoading: false });
      this.closeLoadingModal();
    }
  }
  /**Method loads reports and stws for every patient from backend. */
  loadReportsAndStws(patient, index) {
    console.log("loadReportsAndStws()");
    console.log("Patient: ", patient.code);

    try {
      getClinicianReportsForPatient(patient.code)
        .then((reportList) => {
          reportCnt++;
          console.log("Patient: ", patient.code, "Report: ", reportList);
          this.setState({ reports: reportList.report_entries });
          this.loadReports(index);
        })
        .catch((error) => {
          reportGetPatientReportsError(error);
        });
    } catch (error) {}

    try {
      clinicianGetSurveyTimeWindows(patient.code)
        .then((data) => {
          reportCnt++;
          console.log("STWS: ", data);
          this.setState({ stws: data.stws });
          this.loadReports(index);
        })
        .catch((error) => {
          reportGetStwsError(error);
        });
    } catch (error) {}
  }

  /**
   * Sets the surveyStatus-list for the selected patient on default-state.
   */
  loadReports(index) {
    console.log("loadReports()");
    if (reportCnt === 2) {
      var patientCode = this.state.patients[index].code;
      let status = [...this.state.surveyStatus];

      status[0] = {
        patientCode: patientCode,
        reports: [
          {
            type: "PRE",
            status: "Ausstehend",
          },
          {
            type: "POST",
            status: "Ausstehend",
          },
          {
            type: "FOLLOW_UP_1",
            status: "Ausstehend",
          },
          {
            type: "FOLLOW_UP_2",
            status: "Ausstehend",
          },
        ],
      };

      this.setState({ surveyStatus: status });
      console.log("Status: ", this.state.surveyStatus);
      reportCnt = 0;
      this.surveyState();
    } else {
      // this.setState({ isLoading: false });
      // this.closeLoadingModal();
    }
  }
  /**Method sets survey state for the selected patient and for every stw-type in surveyStatus-list.
   * States can be "Erledigt" || "Ausstehend" || "Nicht verfügbar".
   * Default is "Ausstehend". If the type is in reports-list then "Erledigt".
   * If the stw not exist or the end-date is in the past then "Nicht verfügbar".
   */
  surveyState() {
    console.log("surveyState()");
    console.log("Reports: ", this.state.reports);
    for (var j = 0; j < this.state.reports.length; j++) {
      console.log("REPORT: ", this.state.reports[j]);

      let status = [...this.state.surveyStatus];

      switch (this.state.reports[j].stw_type) {
        case "PRE":
          console.log("PRE");
          status[0].reports[0] = {
            ...this.state.surveyStatus[0].reports[0],
            status: "Erledigt",
          };

          break;
        case "POST":
          status[0].reports[1] = {
            ...this.state.surveyStatus[0].reports[1],
            status: "Erledigt",
          };
          break;
        case "FOLLOW_UP_1":
          status[0].reports[2] = {
            ...this.state.surveyStatus[0].reports[2],
            status: "Erledigt",
          };
          break;
        case "FOLLOW_UP_2":
          status[0].reports[3] = {
            ...this.state.surveyStatus[0].reports[3],
            status: "Erledigt",
          };
          break;
        default:
          break;
      }
      this.setState({ surveyStatus: status });
    }
    console.log(
      "Reports: ",
      this.state.surveyStatus[0].reports,
      "STWS: ",
      this.state.stws
    );
    if (this.state.stws.length !== this.state.surveyStatus[0].reports.length) {
      var arr = [];
      for (var k = 0; k < this.state.stws.length; k++) {
        arr.push(this.state.stws[k].type);
      }
      let status = [...this.state.surveyStatus];
      if (!arr.includes("PRE")) {
        status[0].reports[0] = {
          ...this.state.surveyStatus[0].reports[0],
          status: "Nicht verfügbar",
        };
      }
      if (!arr.includes("POST")) {
        status[0].reports[1] = {
          ...this.state.surveyStatus[0].reports[1],
          status: "Nicht verfügbar",
        };
      }
      if (!arr.includes("FOLLOW_UP_1")) {
        status[0].reports[2] = {
          ...this.state.surveyStatus[0].reports[2],
          status: "Nicht verfügbar",
        };
      }
      if (!arr.includes("FOLLOW_UP_2")) {
        status[0].reports[3] = {
          ...this.state.surveyStatus[0].reports[3],
          status: "Nicht verfügbar",
        };
      }
      this.setState({ surveyStatus: status });
    }
    for (var n = 0; n < this.state.stws.length; n++) {
      let status = [...this.state.surveyStatus];

      for (var m = 0; m < this.state.surveyStatus[0].reports.length; m++) {
        if (
          this.state.stws[n].type ===
            this.state.surveyStatus[0].reports[m].type &&
          this.state.surveyStatus[0].reports[m].status !== "Erledigt"
        ) {
          if (this.state.stws[n].end < this.state.today) {
            status[0].reports[m] = {
              ...this.state.surveyStatus[0].reports[m],
              status: "Nicht verfügbar",
            };
          }
          this.setState({ surveyStatus: status });
        }
      }
    }
  }

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <div className="page-wrapper-new">
          <NavigationBar>
            {this.state.clinic_id}
            {this.state.clinic_name}
            {this.state.username}
            {this.state.user_type}
            {this.state.provisionLevel}
          </NavigationBar>
          <div className="wrapper">
            <SidebarAdmin
              state={this.state}
              handleAccordion={this.handleAccordion}
            />
            <div className="content-wrapper">
              <div className="content">
                <Accordion
                  className="card-default"
                  activeKey={this.state.accordionPatients}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      onClick={() => this.handleAccordion("patients")}
                    >
                      Übersicht Patient*innen
                    </Accordion.Header>
                    <Accordion.Body>
                      <Button className="custom-btn" onClick={this.openModal}>
                        Patient*in anlegen
                      </Button>
                      <Button
                        className="custom-btn"
                        onClick={this.openBulkModal}
                      >
                        Sammelimport
                      </Button>

                      <BootstrapTable
                        keyField="id"
                        hover
                        bordered
                        filter={filterFactory()}
                        columns={[
                          { dataField: "edit", text: "" },
                          {
                            dataField: "code",
                            text: "Reha-ID",
                            sort: true,
                            filter: textFilter({ placeholder: "Suche..." }),
                          },
                          {
                            dataField: "clinician_username",
                            text: "Mitarbeitende",
                            sort: true,
                            filter: textFilter({ placeholder: "Suche..." }),
                          },
                          {
                            dataField: "date_of_birth",
                            text: "Geburtsdatum",
                            sort: true,
                            sortValue: (cell, row) => {
                              var dateSplit = cell.split(".");
                              var newDate = new Date(
                                dateSplit[2] +
                                  "-" +
                                  dateSplit[1] +
                                  "-" +
                                  dateSplit[0]
                              );
                              return (cell = newDate);
                            },
                            filter: dateFilter(),

                            filterValue: (cell, row) => {
                              var dateSplit = cell.split(".");
                              var newDate = new Date(
                                dateSplit[2] +
                                  "-" +
                                  dateSplit[1] +
                                  "-" +
                                  dateSplit[0]
                              );
                              return (cell = newDate);
                            },
                          },
                          {
                            dataField: "gender",
                            text: "Geschlecht",
                            sort: true,
                            filter: selectFilter({
                              options: {
                                Weiblich: "Weiblich",
                                Männlich: "Männlich",
                                Divers: "Divers",
                              },
                              placeholder: "Auswählen...",
                            }),
                          },
                          {
                            dataField: "admission",
                            text: "Aufnahmedatum",
                            sort: true,
                            sortValue: (cell, row) => {
                              var dateSplit = cell.split(".");
                              var newDate = new Date(
                                dateSplit[2] +
                                  "-" +
                                  dateSplit[1] +
                                  "-" +
                                  dateSplit[0]
                              );
                              return (cell = newDate);
                            },
                            filter: dateFilter(),
                            filterValue: (cell, row) => {
                              var dateSplit = cell.split(".");
                              var newDate = new Date(
                                dateSplit[2] +
                                  "-" +
                                  dateSplit[1] +
                                  "-" +
                                  dateSplit[0]
                              );
                              return (cell = newDate);
                            },
                          },
                          {
                            dataField: "discharge",
                            text: "Entlassdatum",
                            sort: true,

                            sortValue: (cell, row) => {
                              var dateSplit = cell.split(".");
                              var newDate = new Date(
                                dateSplit[2] +
                                  "-" +
                                  dateSplit[1] +
                                  "-" +
                                  dateSplit[0]
                              );
                              return (cell = newDate);
                            },
                            filter: dateFilter(),
                            filterValue: (cell, row) => {
                              var dateSplit = cell.split(".");
                              var newDate = new Date(
                                dateSplit[2] +
                                  "-" +
                                  dateSplit[1] +
                                  "-" +
                                  dateSplit[0]
                              );
                              return (cell = newDate);
                            },
                          },
                          { dataField: "state", text: "Befragungsstatus" },
                        ]}
                        data={this.state.patients}
                        headerClasses={"tablehead-fixed"}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  className="card-default"
                  activeKey={this.state.accordionEmployees}
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header
                      onClick={() => this.handleAccordion("employees")}
                    >
                      Übersicht Mitarbeitende
                    </Accordion.Header>
                    <Accordion.Body>
                      <Button onClick={this.openClinicianModal}>
                        Mitarbeitende anlegen
                      </Button>

                      <BootstrapTable
                        keyField="username"
                        hover
                        bordered
                        columns={[
                          { dataField: "edit", text: "" },
                          {
                            dataField: "username",
                            text: "Mitarbeitenden-ID",
                            sort: true,
                          },
                          { dataField: "password", text: "Passwort" },
                          {
                            dataField: "patientCount",
                            text: "Zugewiesene Patient*innen",
                            sort: true,
                          },
                        ]}
                        data={this.state.clinicians}
                        headerClasses={"tablehead-fixed"}
                        rowStyle={(row, index) => {
                          const style = {};
                          if (row.enabled === false) {
                            style.color = "grey";
                          }
                          return style;
                        }}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion
                  className="card-default"
                  activeKey={this.state.accordionClinic}
                >
                  <Accordion.Item eventKey="2">
                    <Accordion.Header
                      onClick={() => this.handleAccordion("clinic")}
                    >
                      Klinik
                    </Accordion.Header>
                    <Accordion.Body>
                      Ihre Klinik ist die <em>{this.state.clinic_name}</em>.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Modal
                  backdrop="static"
                  show={this.state.modalSurveyStateOpen}
                  onHide={this.closeSurveyStateModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>
                      Befragungsstatus von <em>{this.state.currentPatient}</em>
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    {this.state.surveyStatus &&
                      this.state.surveyStatus.length > 0 &&
                      this.state.surveyStatus.map((status, idx) => {
                        return (
                          <div key={status.patientCode}>
                            Aufnahme: {status.reports[0].status} <br />
                            Entlassung: {status.reports[1].status} <br />
                            Nachsorge 1: {status.reports[2].status} <br />
                            Nachsorge 2: {status.reports[3].status} <br />
                          </div>
                        );
                      })}
                  </ModalBody>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalEditClinicianOpen}
                  onHide={this.closeEditClinicianModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Mitarbeitenden-ID ändern</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleEditClinician}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5">
                          Alte Mitarbeitenden-ID:
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            className="form-control-plain"
                            plaintext
                            readOnly
                            defaultValue={this.state.clinician_username}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5">
                          Neue Mitarbeitenden-ID:{" "}
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            className="form-control"
                            required
                            type="username"
                            onChange={(text) => {
                              this.handleClinicianUsername(text);
                            }}
                          />
                        </Col>
                      </Form.Group>
                      <Button type="submit">Speichern</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeEditClinicianModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalEditPatientOpen}
                  onHide={this.closeEditPatientModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>
                      Patient*in <em>{this.state.code}</em> bearbeiten
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleEditPatient}>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="formGridBirthday">
                            <Form.Label>Geburtsdatum</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.editBirthday}
                              value={this.state.editBirthday}
                              onChange={(date) => {
                                this.handleEditBirthdate(date);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Geschlecht</Form.Label>
                            <Form.Select
                              className="form-control"
                              required
                              value={this.state.gender}
                              onChange={(event) => {
                                this.handleGender(event);
                              }}
                            >
                              {this.state.gender === "MALE" ? (
                                <option>Männlich</option>
                              ) : this.state.gender === "FEMALE" ? (
                                <option>Weiblich</option>
                              ) : (
                                <option>Divers</option>
                              )}

                              {this.state.genderList &&
                                this.state.genderList.length > 0 &&
                                this.state.genderList.map((gender, index) => {
                                  return gender.value !== this.state.gender ? (
                                    <option
                                      key={gender.value}
                                      value={gender.value}
                                    >
                                      {gender.name}
                                    </option>
                                  ) : null;
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Diagnose(n)</Form.Label>
                            <Button
                              className="form-control"
                              variant="outline-secondary"
                              onClick={this.openDiagnosisModal}
                            >
                              Auswählen...
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <Form.Group>
                            <Form.Label>Aufnahmedatum ändern</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              selected={this.state.editAdmission}
                              onChange={(date) => {
                                this.handleEditAdmissionDate(date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Entlassdatum ändern</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              selected={this.state.editDischarge}
                              minDate={this.state.editAdmission}
                              onChange={(date) => {
                                this.handleEditDischargeDate(date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button type="submit">Speichern</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeEditPatientModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  size="lg"
                  show={this.state.modalDiagnosisOpen}
                  onHide={this.closeDiagnosisModal}
                >
                  <ModalHeader>Wählen Sie die Diagnose(n) aus:</ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleEditDiagnosesList}>
                      {this.state.diagnoses &&
                        this.state.diagnoses.length > 0 &&
                        this.state.diagnoses.map((diagnosis, index) => {
                          return this.state.diagnosisPatient.some(
                            (diagPatient) => {
                              return (
                                diagnosis.description ===
                                diagPatient.description
                              );
                            }
                          ) ? (
                            <Form.Check
                              key={diagnosis.id}
                              type="checkbox"
                              id={diagnosis.id}
                              data-name={diagnosis.description}
                              label={diagnosis.description}
                              value={diagnosis.id}
                              defaultChecked
                            />
                          ) : (
                            <Form.Check
                              key={diagnosis.id}
                              type="checkbox"
                              id={diagnosis.id}
                              data-name={diagnosis.description}
                              label={diagnosis.description}
                              value={diagnosis.id}
                            />
                          );
                        })}
                      <Button type="submit">Speichern</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="danger" onClick={this.closeDiagnosisModal}>
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalShowPatientListOpen}
                  onHide={this.closeShowPatientListModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>
                      Patient*innenliste von{" "}
                      <em>{this.state.clinician_username}</em>
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate>
                      {this.state.patientList &&
                        this.state.patientList.length > 0 &&
                        this.state.patientList.map((patient, index) => {
                          return <div>{patient}</div>;
                        })}
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeShowPatientListModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalTransferPatientsOpen}
                  onHide={this.closeTransferPatientsModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Patient*innen transferieren</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.transferPatients}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                          Mitarbeitende aktuell
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            className="form-control-plain"
                            plaintext
                            readOnly
                            defaultValue={this.state.clinician_from_username}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                          Transferieren an
                        </Form.Label>
                        <Col sm="8">
                          <Form.Select
                            className="form-control"
                            required
                            value={this.state.value}
                            onChange={(event) => {
                              this.handleClinician(event);
                            }}
                          >
                            <option>Auswählen...</option>
                            {this.state.clinicians &&
                              this.state.clinicians.length > 0 &&
                              this.state.clinicians.map((clinician, index) => {
                                return clinician.username !==
                                  this.state.clinician_from_username ? (
                                  <option
                                    disabled={!clinician.enabled}
                                    key={clinician.id}
                                    value={clinician.username}
                                  >
                                    {clinician.username}
                                  </option>
                                ) : null;
                              })}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Button type="submit">Transferieren</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeTransferPatientsModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalAssignClinicianOpen}
                  onHide={this.closeAssignClinicianModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Neue*n Mitarbeitende*n zuweisen</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleClinicianAssign}>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5">
                          Patient*in
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            className="form-control-plain"
                            plaintext
                            readOnly
                            defaultValue={this.state.code}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="5">
                          Mitarbeitende ändern:
                        </Form.Label>
                        <Col sm="7">
                          <Form.Select
                            className="form-control"
                            required
                            value={this.state.value}
                            onChange={(event) => {
                              this.handleClinician(event);
                            }}
                          >
                            <option>
                              {this.state.clinician_from_username}
                            </option>
                            {this.state.clinicians &&
                              this.state.clinicians.length > 0 &&
                              this.state.clinicians.map((clinician, index) => {
                                return clinician.username !==
                                  this.state.clinician_from_username ? (
                                  <option
                                    disabled={!clinician.enabled}
                                    key={clinician.id}
                                    value={clinician.username}
                                  >
                                    {clinician.username}
                                  </option>
                                ) : null;
                              })}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Button type="submit">Mitarbeitende ändern</Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="danger"
                      onClick={this.closeAssignClinicianModal}
                    >
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalOpen}
                  onHide={this.closeModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Neue Patient*innen anlegen</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      className="mb-3"
                      noValidate
                      onSubmit={this.handleSubmit}
                    >
                      <Row className="mb-3">
                        <Col>
                          <Form.Group controlId="formGridCode">
                            <Form.Label>Reha-ID</Form.Label>
                            <Form.Control
                              className="form-control"
                              required
                              type="code"
                              placeholder="Reha-ID"
                              value={this.state.code}
                              onChange={(text) => {
                                this.handleCode(text);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="formGridBirthday">
                            <Form.Label>Geburtsdatum</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.birthday}
                              value={this.state.birthday}
                              onChange={(date) => {
                                this.handleBirthdate(date);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Geschlecht</Form.Label>
                            <Form.Select
                              className="form-control"
                              required
                              value={this.state.gender}
                              onChange={(event) => {
                                this.handleGender(event);
                              }}
                            >
                              <option>Auswählen...</option>
                              {this.state.genderList &&
                                this.state.genderList.length > 0 &&
                                this.state.genderList.map((gender, index) => {
                                  return (
                                    <option
                                      key={gender.value}
                                      value={gender.value}
                                    >
                                      {gender.name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group>
                            <Form.Label>Mitarbeitende zuweisen</Form.Label>
                            <Form.Select
                              className="form-control"
                              required
                              value={this.state.clinician}
                              onChange={(event) => {
                                this.handleClinician(event);
                              }}
                            >
                              <option>Auswählen...</option>
                              {this.state.clinicians &&
                                this.state.clinicians.length > 0 &&
                                this.state.clinicians.map(
                                  (clinician, index) => {
                                    return (
                                      <option
                                        disabled={!clinician.enabled}
                                        key={clinician.id}
                                        value={clinician.username}
                                      >
                                        {clinician.username}
                                      </option>
                                    );
                                  }
                                )}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Diagnose(n) </Form.Label>
                            <Form.Select
                              className="form-control"
                              required
                              value={this.state.diagnosis}
                              onChange={(event) => {
                                this.handleDiagnosis(event);
                              }}
                            >
                              <option key={"diag0"}>Auswählen...</option>
                              {this.state.diagnoses &&
                                this.state.diagnoses.length > 0 &&
                                this.state.diagnoses.map((diagnoses) => {
                                  return (
                                    <option
                                      key={diagnoses.id}
                                      value={diagnoses.id}
                                    >
                                      {diagnoses.description}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <Form.Group>
                            <Form.Label>Aufnahmedatum festlegen</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              selected={this.state.admission}
                              onChange={(date) => {
                                this.handleAdmissionDate(date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Entlassdatum festlegen</Form.Label>
                            <DatePicker
                              className="form-control"
                              type="date"
                              selected={this.state.discharge}
                              minDate={this.state.admission}
                              onChange={(date) => {
                                this.handleDischargeDate(date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        onClick={() =>
                          this.setState({ editPatientButton: "btn1" })
                        }
                        className="custom-btn"
                        variant="primary"
                        type="submit"
                        name="btn1"
                      >
                        Patient*in anlegen
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({ editPatientButton: "btn2" })
                        }
                        className="custom-btn"
                        variant="primary"
                        type="submit"
                        name="btn2"
                      >
                        Patient*in anlegen + weitere Patient*innen anlegen
                      </Button>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="danger" onClick={this.closeModal}>
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  // size="lg"
                  show={this.state.modalBulkOpen}
                  onHide={this.closeBulkModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Sammelimport Patient*innen</ModalTitle>{" "}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      noValidate
                      onSubmit={
                        this.state.bulkValidation === false
                          ? this.validateBulkImport
                          : this.handleBulkImport
                      }
                    >
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Datei hochladen</Form.Label>
                        <Form.Control
                          type="file"
                          required
                          ref={this.fileInput}
                          onClick={() =>
                            this.setState({ bulkValidation: false })
                          }
                        ></Form.Control>
                      </Form.Group>
                      {this.state.bulkValidation === false ? (
                        <Button type="submit" disabled={this.state.validation}>
                          {" "}
                          {this.state.validation ? (
                            <div>
                              <Spinner animation="border" /> Liste wird geprüft{" "}
                            </div>
                          ) : (
                            <div>
                              <GoChecklist /> Liste überprüfen{" "}
                            </div>
                          )}
                        </Button>
                      ) : (
                        <div>
                          <Button
                            className="custom-btn"
                            onClick={() =>
                              this.setState({ bulkImportButton: "validation" })
                            }
                            type="submit"
                            disabled={this.state.validation}
                          >
                            {this.state.validation ? (
                              <div>
                                <Spinner animation="border" /> Liste wird
                                geprüft{" "}
                              </div>
                            ) : (
                              <div>
                                <GoChecklist /> Liste überprüfen{" "}
                              </div>
                            )}
                          </Button>
                          <Button
                            className="custom-btn"
                            disabled={this.state.importing}
                            onClick={() =>
                              this.setState({ bulkImportButton: "upload" })
                            }
                            type="submit"
                          >
                            {this.state.importing ? (
                              <div>
                                <Spinner animation="border" />
                                Liste wird importiert
                              </div>
                            ) : (
                              <div>
                                <FiUpload /> Liste importieren
                              </div>
                            )}
                          </Button>
                        </div>
                      )}
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="danger" onClick={this.closeBulkModal}>
                      Abbrechen
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalValidOpen}
                  onHide={this.closeValidModal}
                  size="xl"
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Daten überprüfen</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    {this.state.bulkData.valid === true
                      ? "Es wurden keine Fehler gefunden. Sie können die Patienten importieren."
                      : "In der Übersicht werden die fehlerhaften Einträge rot markiert. Diese Einträge müssen Sie in Ihrer Datei anpassen, bevor Sie diese nochmals hochladen. Ein Datum muss im Format TT.MM.JJJJ vorliegen."}

                    <Table hover>
                      <thead>
                        <tr>
                          <th className="table-without-top">Code</th>
                          <th className="table-without-top">Geburtsdatum</th>
                          <th className="table-without-top">Geschlecht</th>
                          <th className="table-without-top">ID Diagnose</th>
                          <th className="table-without-top">Mitarbeitende</th>
                          <th className="table-without-top">Aufnahmedatum</th>
                          <th className="table-without-top">Entlassdatum</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.bulkData.patients &&
                          this.state.bulkData.patients.length > 0 &&
                          this.state.bulkData.patients.map((patient, index) => {
                            return (
                              <tr key={patient.items[0].value}>
                                <td
                                  className={
                                    patient.items[0].valid === false
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[0].value}
                                </td>
                                <td
                                  className={
                                    patient.items[1].valid === false
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[1].value}
                                </td>
                                <td
                                  className={
                                    patient.items[2].valid === false
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[2].value}
                                </td>
                                <td
                                  className={
                                    patient.items[3].valid === false
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[3].value}
                                </td>
                                <td
                                  className={
                                    patient.items[4].valid === false
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[4].value}
                                </td>
                                <td
                                  className={
                                    patient.items[5].valid === false
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[5].value}
                                </td>
                                <td
                                  className={
                                    patient.items[6].valid === false
                                      ? "notValid table-without-bottom"
                                      : "table-without-bottom"
                                  }
                                >
                                  {patient.items[6].value}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>

                    <Button variant="primary" onClick={this.closeValidModal}>
                      Schließen
                    </Button>
                  </ModalBody>
                </Modal>

                <Modal
                  show={this.state.modalClinicianOpen}
                  onHide={this.closeClinicianModal}
                >
                  <ModalHeader closeButton>
                    <ModalTitle>Mitarbeitende anlegen</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <Form noValidate onSubmit={this.handleClinicianRegist}>
                      <Form.Group>
                        <Form.Label>Mitarbeitenden-ID</Form.Label>
                        <Form.Control
                          className="form-control"
                          required
                          type="username"
                          placeholder="Mitarbeitenden-ID"
                          onChange={(text) => {
                            this.handleUsername(text);
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Button className="custom-btn" type="submit">
                        Mitarbeitende anlegen
                      </Button>
                    </Form>
                  </ModalBody>
                </Modal>

                <Modal
                  backdrop="static"
                  show={this.state.modalLoadingOpen}
                  onHide={this.closeLoadingModal}
                >
                  <ModalBody>
                    <div className="loadingDiv">
                      <Spinner className="loadingSpinner" animation="border" />
                    </div>
                    <br />
                    Ihre Daten werden geladen. Dies kann einen Moment dauern.
                  </ModalBody>
                </Modal>
              </div>
            </div>
            <Footer>
              {this.state.clinic_id}
              {this.state.clinic_name}
              {this.state.username}
              {this.state.user_type}
              {this.state.provisionLevel}
            </Footer>
          </div>
        </div>
      </div>
    );
  }
}
export default HomeClinicAdmin;
