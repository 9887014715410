/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import {
//   BsBellFill,
//   BsExclamationCircleFill,
//   BsFillPersonFill,
// } from "react-icons/bs";
import { IoMdInformationCircle } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import { PubSub } from "../../helper/pubSub";

const SidebarClinician = (props) => {
  const { handleAccordion } = props;
  // const { accordionMyPatients } = props;
  const { state } = props;
  const location = useLocation();
  const informationPaths = [
    "/clinician/information",
    "/impressum",
    "/privacypolicy",
    "/contact",
  ]; //all paths belonging to information section. For these paths the menu stays open
  let info = false;

  if (informationPaths.includes(location.pathname)) {
    info = true;
  }

  const [display, setDisplay] = useState({
    patient: true,
    clinic: false,
    info,
  });

  const closeAllAccordions = () => {
    console.log("Called closeAllAccordions");
    setDisplay((prevState) => ({
      ...prevState,
      patient: false,
      clinic: false,
      info: false,
    }));
    handleAccordion("closeAll");
  };

  const handlePatientItem = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patient: !display.patient,
      clinic: false,
      info: false,
    }));
    handleAccordion("myPatients");
  };

  const handleClinicItem = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patient: false,
      clinic: !display.clinic,
      info: false,
    }));
    handleAccordion("clinics");
  };

  const handleInfoItem = () => {
    setDisplay((prevState) => ({
      ...prevState,
      patient: false,
      clinic: false,
      info: !display.info,
    }));
    handleAccordion("info");
  };

  useEffect(() => {
    setDisplay((prevState) => ({
      ...prevState,
      patient: state.accordionMyPatients === "0",
      clinic: state.accordionMyClinics === "1",
      // info: false,
    }));
  }, [state]);

  useEffect(() => {
    //runs only once when component is initialized because of the empty array as second argument
    PubSub.getInstance().subscribeToTopic("close-sidebar-submenus", () => {
      /* eslint-disable react-hooks/exhaustive-deps */
      closeAllAccordions();
    });
  }, []);

  const renderPatientItem = () => (
    <li
      className={display.patient ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handlePatientItem}
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        data-target="#dashboard"
        aria-expanded="false"
        aria-controls="dashboard"
      >
        <i className="mdi">
          {/* <BsBellFill /> */}
          <img
            className="rounded-circle user-role"
            src="../img/user/patient.png"
            width="25px"
            alt="P"
          />
        </i>
        <span className="nav-text">Patient*innen</span> <b className="caret" />
      </a>
    </li>
  );

  const renderClinicItem = () => (
    <li
      className={display.clinic ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleClinicItem}
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        aria-expanded="false"
      >
        <i className="mdi">
          {" "}
          {/* <BsFillPersonFill /> */}
          <img
            className="rounded-circle user-role"
            src="../img/user/hospital.png"
            width="25px"
            alt="K"
          />
        </i>
        <span className="nav-text">Klinik</span> <b className="caret" />
      </a>
    </li>
  );

  const renderInformationSubMenu = () => (
    <ul
      id="documentation"
      data-parent="#sidebar-menu"
      className={display.info ? "show" : "collapse"}
    >
      <div className="sub-menu p-0">
        <li>
          <Link
            to={{
              pathname: "/clinician/information",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">RehaCAT</span>
          </Link>
          {/* <a className="sidenav-item-link" href="#/">
            <span className="nav-text">RehaCAT</span>
          </a> */}
        </li>

        <li>
          <Link
            to={{
              pathname: "/impressum",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Impressum</span>
          </Link>
          {/* <a className="sidenav-item-link" href="/impressum">
            <span className="nav-text">Impressum</span>
          </a> */}
        </li>
        <li>
          <Link
            to={{
              pathname: "/privacypolicy",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Datenschutz</span>
          </Link>
          {/* <a className="sidenav-item-link" href="/privacypolicy">
            <span className="nav-text">Datenschutz</span>
          </a> */}
        </li>
        <li>
          <Link
            to={{
              pathname: "/contact",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Kontakt</span>
          </Link>
          {/* <a className="sidenav-item-link" href="/contact">
            <span className="nav-text">Kontakt</span>
           <span className="badge badge-success">Mail</span>
          </a> */}
        </li>
      </div>
    </ul>
  );

  const renderInformation = () => (
    <li
      className={display.info ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleInfoItem}
      id="clinic-stuff-sidebar-information"
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        data-target="#documentation"
        aria-expanded="false"
        aria-controls="documentation"
      >
        <i className="mdi">
          {/* <BsExclamationCircleFill /> */}
          <IoMdInformationCircle />
        </i>
        <span className="nav-text">Informationen</span> <b className="caret" />
      </a>
      {renderInformationSubMenu()}
    </li>
  );

  return (
    <div>
      <div id="sidebar" className="sidebar sidebar-with-footer">
        <div data-simplebar height="100%">
          <ul className="nav sidebar-inner" id="sidebar-menu">
            {renderPatientItem()}
            {renderClinicItem()}
            <hr className="separator mb-0" />
            {renderInformation()}
          </ul>
        </div>
        <div className="sidebar-footer sidebar-footer-content">
          <a
            href="https://www.uni-ulm.de/in/abt-klinische-psychologie-und-psychotherapie/"
            target="blank"
          >
            <img src="../img/Logo_uulm.png" width="160px" alt="Logo Uni Ulm" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default SidebarClinician;
