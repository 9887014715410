import React, { Component, Fragment } from "react";
import RehacatModal from "./helper/RehactModal";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Start from "./components/Start";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Impressum from "./components/Impressum";
import Contact from "./components/Contact";
import HomePatient from "./components/componentsPatient/HomePatient";
import RunSurvey from "./components/componentsPatient/RunSurvey";
import PatientReport from "./components/componentsPatient/PatientReport";
import HomeClinician from "./components/componentsClinician/HomeClinician";
import HomeClinicAdmin from "./components/componentsClinicAdmin/HomeClinicAdmin";
import LoginPatient from "./components/componentsPatient/LoginPatient";
import LoginClinician from "./components/componentsClinician/LoginClinician";
import LoginClinicAdmin from "./components/componentsClinicAdmin/LoginClinicAdmin";
import AuthService from "./services/AuthService";
import Registration from "./components/componentsClinicAdmin/Registration";
import ClinicianReport from "./components/componentsClinician/ClinicianReport";
import InformationPagePatient from "./components/componentsPatient/InformationPagePatient";
import InformationPageClinician from "./components/componentsClinician/InformationPageClinician";
import InformationPageAdmin from "./components/componentsClinicAdmin/InformationPageAdmin";

let isLoggedIn;
declare var window;

const Page404 = () => <h1> 404 Not Found </h1>;

/**
 * The basic class with all paths.
 */
class App extends Component {
  /**
   * React render method.
   * Some paths can only be opened when the user is logged in.
   */
  render() {
    console.log("Using " + process.env.NODE_ENV + " build.");
    let logLevel = "info";
    if (window.APP_CONFIG.REACT_APP_REHACAT_LOG_LEVEL) {
      logLevel = window.APP_CONFIG.REACT_APP_REHACAT_LOG_LEVEL;
    }
    if (process.env.NODE_ENV === "production") {
      switch (logLevel) {
        case "debug":
          break;
        case "info":
          console.debug = function () {};
          console.log = function () {};
          break;
        case "warning":
          console.debug = function () {};
          console.log = function () {};
          console.info = function () {};
          break;
        case "error":
          console.log = function () {};
          console.warn = function () {};
          console.debug = function () {};
          console.info = function () {};
          break;
        default:
          console.debug = function () {};
          console.log = function () {};
          break;
      }
    }

    return (
      <Fragment>
        <RehacatModal topic="alert" />
        <Router>
          <Switch>
            <Route exact path="/" component={Start} />{" "}
            <Route
              path="/loginpatient"
              render={(props) => <LoginPatient {...props} />}
            />
            <Route
              path="/loginclinician"
              render={(props) => <LoginClinician {...props} />}
            />
            <Route
              path="/loginadmin"
              render={(props) => <LoginClinicAdmin {...props} />}
            />
            <Route path="/regist" component={Registration} />{" "}
            <Route path="/privacypolicy" component={PrivacyPolicy} />{" "}
            <Route path="/impressum" component={Impressum} />{" "}
            <Route path="/contact" component={Contact} />{" "}
            <Route
              exact
              path="/patients"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();
                return isLoggedIn ? (
                  <HomePatient {...props} />
                ) : (
                  <Redirect to="/loginpatient" />
                );
              }}
            />{" "}
            <Route
              path="/patients/survey"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();
                return isLoggedIn ? (
                  <RunSurvey {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/loginpatient",
                      state: { redirectTo: "/patients/survey" },
                    }}
                  />
                );
              }}
            />{" "}
            <Route
              path="/patients/report"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();

                return isLoggedIn ? (
                  <PatientReport {...props} />
                ) : (
                  <Redirect to="/loginpatient" />
                );
              }}
            />{" "}
            <Route
              path="/clinician/patient-report"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();

                return isLoggedIn ? (
                  <PatientReport {...props} />
                ) : (
                  <Redirect to="/loginclinician" />
                );
              }}
            />{" "}
            <Route
              path="/patients/information"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();
                return isLoggedIn ? (
                  <InformationPagePatient {...props} />
                ) : (
                  <Redirect to="/loginpatient" />
                );
              }}
            />
            <Route
              exact
              path="/clinician"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();
                return isLoggedIn ? (
                  <HomeClinician {...props} />
                ) : (
                  <Redirect to="/loginclinician" />
                );
              }}
            />{" "}
            <Route
              path="/clinician/clinician-report"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();
                return isLoggedIn ? (
                  <ClinicianReport {...props} />
                ) : (
                  <Redirect to="/loginclinician" />
                );
              }}
            />{" "}
            <Route
              path="/clinician/information"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();
                return isLoggedIn ? (
                  <InformationPageClinician {...props} />
                ) : (
                  <Redirect to="/loginclinician" />
                );
              }}
            />
            <Route
              exact
              path="/admin"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();
                return isLoggedIn ? (
                  <HomeClinicAdmin {...props} />
                ) : (
                  <Redirect to="/loginadmin" />
                );
              }}
            />{" "}
            <Route
              path="/admin/information"
              render={(props) => {
                isLoggedIn = AuthService.isLoggedIn();
                return isLoggedIn ? (
                  <InformationPageAdmin {...props} />
                ) : (
                  <Redirect to="/loginadmin" />
                );
              }}
            />
            <Route component={Page404} />{" "}
          </Switch>{" "}
        </Router>{" "}
        {/* </Layout> */} {/* <Footer /> */}{" "}
      </Fragment>
    );
  }
}

export default App;
