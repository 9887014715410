import React from "react";
import { Component } from "react";
import { Button, Card, Row } from "react-bootstrap";
// import AuthService from "../../services/AuthService";
import NavigationBar from "../navigation/NavigationBar";
import SidebarPatient from "../sidebars/SidebarPatient";
import { FiDownload } from "react-icons/fi";
import Footer from "../Footer";
import TimeoutSession from "../../helper/TimeoutSession";
// import { reportGetProvisionLevelError } from "../../helper/alertBoxes";

/**Information page of patient. */
class InformationPagePatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic_id: "",
      clinic_name: "",
      username: "",
      user_type: "",
      provisionLevel: "",
      accordionCurrent: "-1",
      accordionInformation: "-1",
      accordionSurvey: "-1",
      accordionReport: "-1",
    };
  }

  /**Method checks the provisionlevel and set the states.
   */
  componentDidMount() {
    this.setState({
      clinic_name: this.props.location.state.clinic_name,
      clinic_id: this.props.location.state.clinic_id,
      username: this.props.location.state.username,
      user_type: this.props.location.state.user_type,
      provisionLevel: this.props.location.state.provisionLevel,
    });
  }

  /**When clicking on sidebar, check which menu item is selected.
   * Open on dashboard the right accordion.
   */
  handleAccordion = (input) => {
    var accCurrent = "-1";
    var accInfo = "-1";
    var accSurvey = "-1";
    var accReports = "-1";
    switch (input) {
      case "current":
        accCurrent = "0";
        break;
      case "info":
        accInfo = "1";
        break;
      case "survey":
        accSurvey = "2";
        break;
      case "report":
        accReports = "3";
        break;
      default:
        break;
    }
    if (input !== "general") {
      this.props.history.push({
        pathname: "/patients",
        state: {
          clinic_id: this.state.clinic_id,
          clinic_name: this.state.clinic_name,
          username: this.state.username,
          provisionLevel: this.state.provisionLevel,
          accordionCurrent: accCurrent,
          accordionInformation: accInfo,
          accordionSurvey: accSurvey,
          accordionReport: accReports,
        },
      });
    }
  };

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <NavigationBar>
          {this.state.clinic_id}
          {this.state.clinic_name}
          {this.state.username}
          {this.state.user_type}
          {this.state.provisionLevel}
        </NavigationBar>
        <div className="page-wrapper-new">
          <div className="wrapper">
            <SidebarPatient
              handleAccordion={this.handleAccordion}
              state={this.state}
            />
            <div className="content-wrapper">
              <div className="content">
                <h3>Informationen zu RehaCAT</h3>
                <Row>
                  <Card className="card-default card-custom">
                    <Card.Body>
                      <Card.Title>Was ist RehaCAT?</Card.Title>
                      <Card.Text>
                        RehaCAT ist ein von der Abteilung Klinische Psychologie
                        und Psychotherapie der Universität Ulm entwickeltes
                        Computer-adaptives und Web-basiertes Erst- und
                        Verlaufsdiagnostiksystem zur Erfassung der funktionalen
                        Gesundheit in der orthopädischen und kardiologischen
                        Rehabilitation.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  {this.state.provisionLevel === "PLUS" ? (
                    <Card className="card-default card-custom">
                      <Card.Body>
                        <Card.Title>Anleitung</Card.Title>
                        <Card.Text>
                          Hier finden Sie eine Anleitung zur Benutzung der
                          RehaCAT-Plattform.
                        </Card.Text>

                        <a
                          href={`${process.env.PUBLIC_URL}/files/RehaCAT+_Anleitung.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="primary">
                            <FiDownload /> Anleitung herunterladen
                          </Button>
                        </a>
                      </Card.Body>
                    </Card>
                  ) : (
                    <Card className="card-default card-custom">
                      <Card.Body>
                        <Card.Title>Anleitung</Card.Title>
                        <Card.Text>
                          Hier finden Sie eine Anleitung zur Benutzung der
                          RehaCAT-Plattform.
                        </Card.Text>

                        <a
                          href={`${process.env.PUBLIC_URL}/files/RehaCAT_Anleitung.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="primary">
                            <FiDownload /> Anleitung herunterladen
                          </Button>
                        </a>
                      </Card.Body>
                    </Card>
                  )}
                  {this.state.provisionLevel === "PLUS" ? (
                    <Card className="card-default card-custom">
                      <Card.Body>
                        <Card.Title>
                          Hilfreiche Anlaufstellen bei psychischer Belastung
                        </Card.Title>
                        <Card.Text>
                          Hier finden Sie eine Sammlung an hilfreichen
                          Anlaufstellen bis hin zu Selbsthilfeangeboten.
                        </Card.Text>

                        <a
                          href={`${process.env.PUBLIC_URL}/files/Hilfreiche_Anlaufstellen.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button variant="primary">
                            <FiDownload /> Liste herunterladen
                          </Button>
                        </a>
                      </Card.Body>
                    </Card>
                  ) : null}{" "}
                  <Card className="card-default card-custom">
                    <Card.Body>
                      <Card.Title>
                        Studieninformationen für Patient*innen
                      </Card.Title>
                      <Card.Text>
                        Für Patient*innen, die an der Studie teilnehmen, können
                        hier die Informationen zur Studie und zum Datenschutz
                        heruntergeladen werden.
                      </Card.Text>

                      <a
                        href={`${process.env.PUBLIC_URL}/files/Studieninformation.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="primary">
                          <FiDownload /> Studieninformationen herunterladen
                        </Button>
                      </a>
                    </Card.Body>
                  </Card>
                </Row>
              </div>
            </div>
            <Footer>
              {this.state.clinic_id}
              {this.state.clinic_name}
              {this.state.username}
              {this.state.user_type}
              {this.state.provisionLevel}
            </Footer>
          </div>
        </div>
      </div>
    );
  }
}
export default InformationPagePatient;
