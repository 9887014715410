/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Component } from "react";
import { Button, Card, Row, Tab, Tabs } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { IoMdOpen } from "react-icons/io";
import TimeoutSession from "../../helper/TimeoutSession";
// import AuthService from "../../services/AuthService";
import Footer from "../Footer";
import NavigationBar from "../navigation/NavigationBar";
import SidebarClinician from "../sidebars/SidebarClinician";

/**Information page of clinician. */
class InformationPageClinician extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic_id: "",
      clinic_name: "",
      username: "",
      user_type: "",
      provisionLevel: "",
      accordionMyPatients: "-1",
      accordionMyClinics: "-1",
    };
  }

  /**Method checks the provisionlevel and set the states.
   */
  componentDidMount() {
    // try {
    //   AuthService.getContent("/api/auth/clinics/provision-level")
    //     .then((response) => {
    //       console.info("Provision-Level: ", response.data);
    //       this.setState({ provisionLevel: response.data });
    //     })
    //     .catch((error) => {
    //       console.warn("Provision-Level Fail: ", error);
    //     });
    // } catch (error) {}

    this.setState({
      clinic_name: this.props.location.state.clinic_name,
      clinic_id: this.props.location.state.clinic_id,
      username: this.props.location.state.username,
      user_type: this.props.location.state.user_type,
      provisionLevel: this.props.location.state.provisionLevel,
    });
  }

  /**When clicking on sidebar, check which menu item is selected.
   * Open on dashboard the right accordion.
   */
  handleAccordion = (input) => {
    var accPatients = "-1";
    var accClinics = "-1";
    switch (input) {
      case "myPatients":
        accPatients = "0";
        break;
      case "clinics":
        accClinics = "1";
        break;
      default:
        break;
    }
    if (input !== "info") {
      this.props.history.push({
        pathname: "/clinician",
        state: {
          clinic_id: this.state.clinic_id,
          clinic_name: this.state.clinic_name,
          username: this.state.username,
          provisionLevel: this.state.provisionLevel,
          accordionMyPatients: accPatients,
          accordionMyClinics: accClinics,
        },
      });
    }
  };

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <NavigationBar>
          {this.state.clinic_id}
          {this.state.clinic_name}
          {this.state.username}
          {this.state.user_type}
          {this.state.provisionLevel}
        </NavigationBar>
        <div className="page-wrapper-new">
          <div className="wrapper">
            <SidebarClinician
              handleAccordion={this.handleAccordion}
              state={this.state}
            />
            <div className="content-wrapper">
              <div className="content">
                <h3>Informationen zu RehaCAT</h3>
                <Tabs defaultAktiveKey="clinician" className="mb-3">
                  <Tab
                    eventKey="clinician"
                    title={
                      <a>
                        <i className="mdi">
                          <img
                            className="rounded-circle "
                            src="../img/user/clinician.png"
                            width="25px"
                            alt="Clinician"
                          />
                        </i>
                        <span className=""> Für Mitarbeitende</span>
                      </a>
                    }
                  >
                    <Row>
                      {this.state.provisionLevel === "PLUS" ? (
                        <Card className="card-default card-custom">
                          <Card.Body>
                            <Card.Title>Schulungsmaterial</Card.Title>
                            <Card.Text>
                              Hier finden Sie die Folien der
                              Schulungspräsentation.
                            </Card.Text>

                            <a
                              href={`${process.env.PUBLIC_URL}/files/RehaCAT+_Schulungspräsentation_p.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="primary">
                                <FiDownload /> Schulungsmaterial herunterladen
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      ) : (
                        <Card className="card-default card-custom">
                          <Card.Body>
                            <Card.Title>Schulungsmaterial</Card.Title>
                            <Card.Text>
                              Hier finden Sie die Folien der
                              Schulungspräsentation.
                            </Card.Text>

                            <a
                              href={`${process.env.PUBLIC_URL}/files/RehaCAT+_Schulungspräsentation_b.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="primary">
                                <FiDownload /> Schulungsmaterial herunterladen
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      )}
                      {this.state.provisionLevel === "PLUS" ? (
                        <Card className="card-default card-custom">
                          <Card.Body>
                            <Card.Title>Testmanual</Card.Title>
                            <Card.Text>
                              Hier finden Sie ein Handbuch mit Informationen zur
                              Verwendung und Bedienung von RehaCAT.
                            </Card.Text>

                            <a
                              href={`${process.env.PUBLIC_URL}/files/Testmanual_p.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="primary">
                                <FiDownload /> Testmanual herunterladen
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      ) : (
                        <Card className="card-default card-custom">
                          <Card.Body>
                            <Card.Title>Testmanual</Card.Title>
                            <Card.Text>
                              Hier finden Sie ein Handbuch mit Informationen zur
                              Verwendung und Bedienung von RehaCAT.
                            </Card.Text>

                            <a
                              href={`${process.env.PUBLIC_URL}/files/Testmanual_b.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="primary">
                                <FiDownload /> Testmanual herunterladen
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      )}
                      <Card className="card-default card-custom">
                        <Card.Body>
                          <Card.Title>FAQs</Card.Title>
                          <Card.Text>
                            Hier finden Sie Antworten auf häufige Fragen des
                            Klinikpersonals.
                          </Card.Text>

                          <a
                            href="https://rehacat.de/de/information/fur-klinikmitarbeiter/"
                            target="blank"
                          >
                            <Button variant="primary">
                              <IoMdOpen /> Link öffnen
                            </Button>
                          </a>
                        </Card.Body>
                      </Card>
                      {this.state.provisionLevel === "PLUS" ? (
                        <Card className="card-default card-custom">
                          <Card.Body>
                            <Card.Title>Kurzinformation</Card.Title>
                            <Card.Text>
                              Hier finden Sie Kurzinformationen zur psychischen
                              Komorbidität, Depression und Angst.
                            </Card.Text>

                            <a
                              href={`${process.env.PUBLIC_URL}/files/OnePager_Psychische_Komorbiditaet_bei_psychischen_Belastungen.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="primary" className="custom-btn">
                                <FiDownload /> Psychische Komorbidität
                                herunterladen
                              </Button>
                            </a>
                            <a
                              href={`${process.env.PUBLIC_URL}/files/OnePager_Depression.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="primary" className="custom-btn">
                                <FiDownload /> Depression herunterladen
                              </Button>
                            </a>
                            <a
                              href={`${process.env.PUBLIC_URL}/files/OnePager_Angst.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="primary" className="custom-btn">
                                <FiDownload /> Angst herunterladen
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      ) : null}
                    </Row>
                  </Tab>
                  <Tab
                    eventKey="patient"
                    title={
                      <a>
                        <i className="mdi">
                          <img
                            className="rounded-circle "
                            src="../img/user/patient.png"
                            width="25px"
                            alt="Patient"
                          />
                        </i>
                        <span className=""> Für Patient*innen</span>
                      </a>
                    }
                  >
                    <Row>
                      <Card className="card-default card-custom">
                        <Card.Body>
                          <Card.Title>
                            Studieninformationen für Patient*innen
                          </Card.Title>
                          <Card.Text>
                            Für Patient*innen die an der Studie teilnehmen
                            können hier Informationen zur Studie und zum
                            Datenschutz heruntergeladen werden.
                          </Card.Text>

                          <a
                            href={`${process.env.PUBLIC_URL}/files/Studieninformation.pdf`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button variant="primary">
                              <FiDownload /> Studieninformation herunterladen
                            </Button>
                          </a>
                        </Card.Body>
                      </Card>
                      {this.state.provisionLevel === "PLUS" ? (
                        <Card className="card-default card-custom">
                          <Card.Body>
                            <Card.Title>Hilfreiche Anlaufstellen</Card.Title>
                            <Card.Text>
                              Hier finden Sie eine Sammlung an hilfreichen
                              Anlaufstellen bis hin zu Selbsthilfeangeboten.
                            </Card.Text>

                            <a
                              href={`${process.env.PUBLIC_URL}/files/Hilfreiche_Anlaufstellen.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="primary">
                                <FiDownload /> Liste herunterladen
                              </Button>
                            </a>
                          </Card.Body>
                        </Card>
                      ) : null}
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <Footer>
              {this.state.clinic_id}
              {this.state.clinic_name}
              {this.state.username}
              {this.state.user_type}
              {this.state.provisionLevel}
            </Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default InformationPageClinician;
