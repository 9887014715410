/* eslint-disable no-loop-func */
// @ts-nocheck
import {
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import NavigationBar from "../navigation/NavigationBar";
import {
  getProvisionLevel,
  clinicianGetPatients,
  getViewedReportIds,
  getClinicianReportsForPatient,
  getClinicianReportsForPatients,
  getSeverities,
  clinicianGetSurveyTimeWindows,
} from "../../services/getFunctions";
import AuthService from "../../services/AuthService";
import SidebarClinician from "../sidebars/SidebarClinician";
import ErrorBoundary from "../../ErrorBoundary";
import { MdEdit } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import Footer from "../Footer";
import TimeoutSession from "../../helper/TimeoutSession";
import {
  reportGetStwsError,
  showAlertModal,
  reportGetPatientReportsError,
} from "../../helper/alertBoxes";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  dateFilter,
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import { clinicianAssignClinician } from "../../services/postFunctions";

/**Clinician dashboard component */
var reportCnt = 0;
class HomeClinician extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "clinician",
      provisionLevel: "",
      patients: [],
      clinic_id: "",
      clinic_name: "",
      username: "",
      reports: [],
      reportsParallel: [],
      stwsParallel: [],
      severitiesParallel: [],
      stws: [],
      severity: [],
      severities: [],
      accordionMyPatients: "-1",
      accordionMyClinics: "-1",
      surveyStatus: [],
      viewedReports: [],
      viewedList: [],
      surveySessionIds: [], // type: WrappedSurveySessionIds[]
      today: new Date().toISOString().split("T")[0],
      survey_session_id: "",
      modalLoadingOpen: true,
      modalSurveyStateOpen: false,
      modalResultOpen: false,
      isLoading: true,
      modalAssignClinicianOpen: false,
      progressBarStatus: 0,
      currentPatient: "",
      patientSortList: [],
    };
  }

  /**Method for loading the patient list and the provision level from backend API. */
  componentDidMount() {
    console.log("Heute: ", new Date());
    this.openLoadingModal();
    console.log(
      "States: ",
      this.state.username,
      this.state.clinic_id,
      this.state.clinic_name
    );
    console.log("Props: ", this.props);
    if (this.props.location.state === undefined) {
      console.log("Props Location Fail");
      ErrorBoundary.getDerivedStateFromError(Error);
    } else {
      this.setState({
        clinic_id: this.props.location.state.clinic_id,
        clinic_name: this.props.location.state.clinic_name,
        username: this.props.location.state.username,
        provisionLevel: this.props.location.state.provisionLevel,
      });
      if (this.props.location.state.accordionMyClinics) {
        this.setState({
          accordionMyClinics: this.props.location.state.accordionMyClinics,
          accordionMyPatients: this.props.location.state.accordionMyPatients,
        });
      }
    }

    if (this.state.provisionLevel === "") {
      try {
        getProvisionLevel()
          .then((provisionLevel) => {
            console.info("Provision-Level: ", provisionLevel);
            this.setState({ provisionLevel: provisionLevel });
          })
          .catch((error) => {
            console.error("Provision-Level Fail: ", error);
          });
      } catch (error) {}
    }

    try {
      clinicianGetPatients()
        .then((patientList) => {
          console.log("Patientenliste: ", patientList);
          this.setState({ patients: patientList.list_patient_dto });
          console.log("Patient-Length: ", this.state.patients.length);
          this.customizePatientList();
        })
        .catch((error) => {
          console.error("Patientenliste Fail: ", error);
        });
    } catch (error) {}
    try {
      getViewedReportIds()
        .then((viewedReportIds) => {
          console.log("Gesehene Berichte: ", viewedReportIds);
          this.setState({ viewedReports: viewedReportIds });
        })
        .catch((error) => {
          console.error("Viewed Reports Fail: ", error);
        });
    } catch (error) {}
  }

  /**Set modalLoadingOpen on true, if the modal is opened. */
  openLoadingModal = () => {
    this.setState({ modalLoadingOpen: true });
  };

  /**Set modalLoadingOpen on false, if the modal is closed. */
  closeLoadingModal = () => {
    console.log("Modal schließen");
    this.setState({ modalLoadingOpen: false });
  };

  /**Set modalAssignClinicianOpen on true, if the modal is opened.
   * Set the current patient states.
   */
  openAssignClinicianModal = (event) => {
    event.preventDefault();
    console.log("Patient mit Index: ", event.target.id);
    var code = event.target.id;
    var patient;
    // var index = event.target.id;
    for (var i = 0; i < this.state.patients.length; i++) {
      if (this.state.patients[i].code === code) {
        patient = this.state.patients[i];
      }
    }
    console.log("Patient: ", patient);
    // var patient = this.state.patients[index];
    this.setState({
      modalAssignClinicianOpen: true,
      patient_id: patient.id,
      code: patient.code,
      clinician_from_id: patient.clinician_id,
      clinician_from_username: patient.clinician_username,
    });
  };

  openSurveyStateModal = (event) => {
    event.preventDefault();
    var id = event.target.id;
    var patient;
    var index;
    this.setState({
      modalSurveyStateOpen: true,
      modalResultOpen: false,
    });
    console.log("Current: ", this.state.currentPatient);
    if (this.state.currentPatient.id !== id) {
      for (var i = 0; this.state.patients.length; i++) {
        if (id === this.state.patients[i].id) {
          patient = this.state.patients[i];
          index = i;
          break;
        }
      }
      this.setState({ currentPatient: { code: patient.code, id: id } });
    }

    if (
      this.state.surveyStatus.length === 0 ||
      this.state.surveyStatus[0].patientCode !== patient
    ) {
      this.loadReportsAndStws(patient, index);
    }

    console.log("Event: ", event.target);
  };

  openResultModal = (event) => {
    event.preventDefault();
    var index;
    var id = event.target.id;
    var patient;
    this.setState({
      modalResultOpen: true,
      modalSurveyStateOpen: false,
    });
    console.log("Current: ", this.state.currentPatient);
    if (this.state.currentPatient.id !== id) {
      for (var i = 0; this.state.patients.length; i++) {
        if (id === this.state.patients[i].id) {
          patient = this.state.patients[i];
          index = i;
          break;
        }
      }
      this.setState({ currentPatient: { code: patient.code, id: id } });
    }

    if (
      this.state.surveyStatus.length === 0 ||
      this.state.surveyStatus[0].patientCode !== patient
    ) {
      this.loadReportsAndStws(patient, index);
    }
  };

  closeSurveyStateModal = () =>
    this.setState({
      modalSurveyStateOpen: false,
      surveyStatus: [],
      currentPatient: "",
    });
  closeResultModal = () =>
    this.setState({
      modalResultOpen: false,
      surveyStatus: [],
      currentPatient: "",
    });

  closeAssignClinicianModal = () =>
    this.setState({ modalAssignClinicianOpen: false, code: "" });

  /**Method to update the clinician state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the selected clinician
   */
  handleClinician(text) {
    this.setState({ clinician: text.target.value });
  }

  /**Method for handle clinician assign.
   * The input data are stringified to JSON.
   * Then send JSON to backend api.
   */
  handleClinicianAssign = (event) => {
    event.preventDefault();

    var assignmentData = {
      patient_id: this.state.patient_id,
      clinician_from: this.state.clinician_from_id,
      clinician_to: this.state.clinician,
    };

    try {
      clinicianAssignClinician(assignmentData)
        .then((response) => {
          showAlertModal(
            "Der*die Patient*in wurde erfolgreich einem neuen Mitarbeitenden zugewiesen."
          );
          this.closeAssignClinicianModal();
          this.componentDidMount();
        })
        .catch((error) => {
          showAlertModal(
            "Irgendetwas ist schief gelaufen. Der*die Patient*in wurde keinem neuen Mitarbeitenden zugewiesen."
          );
          console.error("Assign Clinician Fail: ", error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**The patient list is prepared for the bootstrap table with all needed attributes.*/
  customizePatientList() {
    if (this.state.patients.length !== 0) {
      let patSortList = [...this.state.patients];
      var patient;
      var gender;
      for (var i = 0; i < this.state.patients.length; i++) {
        patient = this.state.patients[i];
        if (patient.gender === "FEMALE") {
          gender = "Weiblich";
        } else if (patient.gender === "MALE") {
          gender = "Männlich";
        } else if (patient.gender === "DIVERSE") {
          gender = "Divers";
        }
        patSortList[i] = {
          ...this.state.patients[i],
          gender: gender,
          edit: (
            <DropdownButton
              key={patient.code}
              variant="outline-secondary"
              id="dropdown-basic-button"
              title={
                <i className="mdi">
                  <MdEdit />
                </i>
              }
            >
              <Dropdown.Item
                id={patient.code}
                onClick={this.openAssignClinicianModal}
              >
                <BsPeople /> Anderen Mitarbeitenden zuweisen
              </Dropdown.Item>
            </DropdownButton>
          ),
          state: (
            <Button id={patient.id} onClick={this.openSurveyStateModal}>
              Anzeigen
            </Button>
          ),
          result: (
            <Button id={patient.id} onClick={this.openResultModal}>
              Anzeigen
            </Button>
          ),
        };
      }
      this.setState({ patients: patSortList });
    }
    this.deleteDuplicates();
  }

  /**Method deletes duplicate patient entries with the same patient code. */
  deleteDuplicates() {
    if (this.state.patients.length !== 0) {
      console.log("Patienten: ", this.state.patients);
      let patList = [...this.state.patients];
      for (var i = 0; i < this.state.patients.length; i++) {
        for (var j = i + 1; j < this.state.patients.length - 1; j++) {
          if (
            this.state.patients[i].code === this.state.patients[j].code &&
            i !== j
          ) {
            console.log("Doppelt: ", this.state.patients[i].code);
            if (
              this.state.patients[i].created > this.state.patients[j].created
            ) {
              patList[j] = { duplicate: true };

              console.log("Duplikat j", this.state.patients[j].created);
            } else {
              patList[i] = { duplicate: true };

              console.log("Duplikat i.", this.state.patients[i].created);
            }
          }
        }
      }
      this.setState({ patients: patList });
      console.log("Sort List: ", patList);

      console.log("Liste mit neuem Attribut: ", patList);

      for (var k = 0; k < patList.length; k++) {
        if (patList[k].duplicate) {
          console.log("Duplikat an Stelle : ", k);

          patList.splice(k, 1);
        }
      }
      console.log("PatList: ", patList);
      this.setState({ patients: patList });
      console.log("Liste ohne Duplikate: ", this.state.patients);
    }

    this.loadSurveySessionID();
  }

  /**Method loads reports from backend and saves the patient_code with the responding survey-session-ids in state surveySessionIds. */
  loadSurveySessionID() {
    console.log("loadSurveySessionID()");
    console.log("Patientenliste: ", this.state.patients);
    try {
      getClinicianReportsForPatients(
        this.state.patients.map((patient) => patient.code)
      )
        .then((responses) => {
          console.log("Survey Session IDs: ", responses);
          this.setState({ surveySessionIds: responses });
          this.checkViewed();
        })
        .catch((error) => {
          reportGetPatientReportsError(error);
        });
    } catch (error) {}
  }

  /**Method checks, if all reports of a patient are viewed or not. */
  checkViewed() {
    var viewedList = [];
    if (this.state.surveySessionIds.length !== 0) {
      for (var i = 0; i < this.state.surveySessionIds.length; i++) {
        if (i === this.state.surveySessionIds.length - 1) {
          this.closeLoadingModal();
        }
        var viewed;
        var viewedCnt = 0;
        var reportCount =
          this.state.surveySessionIds[i].survey_session_id_list.length;
        var patient = this.state.surveySessionIds[i].patient_code;
        if (reportCount !== 0) {
          for (
            var j = 0;
            j < this.state.surveySessionIds[i].survey_session_id_list.length;
            j++
          ) {
            var survey_session_id =
              this.state.surveySessionIds[i].survey_session_id_list[j];
            if (this.state.viewedReports.includes(survey_session_id)) {
              viewedCnt++;
            }
          }
          if (viewedCnt === reportCount) {
            viewed = "Alle Reports gelesen";
          } else {
            viewed = "Ungelesener Report";
          }
        } else {
          viewed = "Nicht verfügbar";
        }
        viewedList.push({ code: patient, viewed: viewed });
      }
      var patList = [...this.state.patients];
      for (var l = 0; l < this.state.patients.length; l++) {
        for (var k = 0; k < viewedList.length; k++) {
          var viewedP = viewedList[k].viewed;
          if (this.state.patients[l].code === viewedList[k].code) {
            patList[l] = {
              ...this.state.patients[l],
              viewed: viewedP,
            };
          }
        }
      }
      this.setState({ patients: patList, patientSortList: patList });
      console.log("Liste mit viewed: ", patList);
    } else this.closeLoadingModal();

    this.setState({ viewedList: viewedList });
    console.log("Viewed List: ", this.state.viewedList);
  }

  /**Method loads reports and stws for every patient from backend. */
  loadReportsAndStws(patient, index) {
    console.log("loadReportsAndStws()");

    try {
      getClinicianReportsForPatient(patient.code)
        .then((reports) => {
          reportCnt++;
          console.log("Patient: ", patient.code, "Report: ", reports);
          this.setState({ reports: reports.report_entries });
          this.loadReports(index);
        })
        .catch((error) => {
          reportGetPatientReportsError(error);
        });
    } catch (error) {}

    try {
      clinicianGetSurveyTimeWindows(patient.code)
        .then((data) => {
          reportCnt++;
          console.log("STWS: ", data);
          this.setState({ stws: data.stws });
          this.loadReports(index);
        })
        .catch((error) => {
          reportGetStwsError(error);
        });
    } catch (error) {}
  }

  /**
   * Sets the surveyStatus-list for every patient on default-state.
   */
  loadReports(index) {
    console.log("loadReports()");

    if (reportCnt === 2) {
      var patientCode = this.state.patients[index].code;
      let status = [...this.state.surveyStatus];

      status[0] = {
        patientCode: patientCode,
        session_id_list: [],
        viewed: "Nicht verfügbar",
        reports: [
          {
            type: "PRE",
            status: "Ausstehend",
            result: "Nicht verfügbar",
            className: "",
            survey_session_id: "",
          },
          {
            type: "POST",
            status: "Ausstehend",
            result: "Nicht verfügbar",
            className: "",
            survey_session_id: "",
          },
          {
            type: "FOLLOW_UP_1",
            status: "Ausstehend",
            result: "Nicht verfügbar",
            className: "",
            survey_session_id: "",
          },
          {
            type: "FOLLOW_UP_2",
            status: "Ausstehend",
            result: "Nicht verfügbar",
            className: "",
            survey_session_id: "",
          },
        ],
      };

      this.setState({ surveyStatus: status });
      // console.log("Status: ", this.state.surveyStatus);

      reportCnt = 0;
      console.log("Status: ", this.state.surveyStatus);
      this.surveyState();
      this.getSeverity(index);
    } else {
    }
  }

  /**Method loads severity status for each existing report from backend.
   * Sets severity list with patientCode and status of individual reports.
   */
  getSeverity(index) {
    console.log("getSeverity()");

    var reportList = [];

    var patientCode = this.state.patients[index].code;
    console.log("Patient: ", patientCode);
    for (var j = 0; j < this.state.reports.length; j++) {
      if (this.state.reports[j]) {
        var stw_type = this.state.reports[j].stw_type;
        var survey_session_id = this.state.reports[j].survey_session_id;
        reportList.push([
          {
            patient_code: patientCode,
            stw_type: stw_type,
            survey_session_id: survey_session_id,
          },
        ]);
      }
    }
    // }
    console.log("ReportList: ", reportList);

    try {
      getSeverities(
        reportList.map((reportListEntry) => {
          return reportListEntry[0].survey_session_id;
        })
      )
        .then((severities) => {
          console.log("Severities parallel: ", severities);

          //the following is done like this for legacy reasons since I'm refactoring the server communication first but the state expects an array of
          //objects that look like this:
          // {
          //   patient_code: patient,
          //   stw_type: stw_type,
          //   survey_session_id: survey_session_id,
          //   result: response.data,
          // }
          //This has to definitely be changed later!
          let severitiesParallel = severities.map((severity, index) => {
            return [
              {
                //notice that this is an Array! I don't know why it was done like that but for now I'll keep it because it is out of the scope of the current refactoring session
                ...reportList[index][0],
                result: severity,
              },
            ];
          });

          console.log("Severities parallel (wirklich!): ", severitiesParallel);
          this.setState({ severitiesParallel: severitiesParallel });
          this.resultState(index);
        })
        .catch((error) => {});
    } catch (e) {}
    // }
  }

  /**Updates the surveyStatus-list result.
   * Result can be "Unbekannt" || "Unauffällig" || "Auffällig" || "Kritisch".
   */
  resultState(index) {
    console.log("resultState()");
    // for (var i = 0; i < this.state.patients.length; i++) {
    var patientCode = this.state.patients[index].code;
    let status = [...this.state.surveyStatus];
    var type;
    var className = "";
    for (var j = 0; j < this.state.severitiesParallel.length; j++) {
      if (patientCode === this.state.severitiesParallel[j][0].patient_code) {
        var resultStatus = this.state.severitiesParallel[j][0].result;
        switch (this.state.severitiesParallel[j][0].stw_type) {
          case "PRE":
            type = 0;
            break;
          case "POST":
            type = 1;
            break;
          case "FOLLOW_UP_1":
            type = 2;
            break;
          case "FOLLOW_UP_2":
            type = 3;
            break;
          default:
            break;
        }
        switch (resultStatus) {
          case "UNKNOWN":
            resultStatus = "Unbekannt";
            if (this.state.provisionLevel === "PLUS") {
              className = "result-unknown";
            } else className = "result-basic";

            break;
          case "LOW":
            resultStatus = "Unauffällig";
            if (this.state.provisionLevel === "PLUS") {
              className = "result-low";
            } else className = "result-basic";

            break;
          case "MEDIUM":
            resultStatus = "Auffällig";
            if (this.state.provisionLevel === "PLUS") {
              className = "result-medium";
            } else className = "result-basic";

            break;
          case "HIGH":
            resultStatus = "Kritisch";
            if (this.state.provisionLevel === "PLUS") {
              className = "result-high";
            } else className = "result-basic";

            break;
          default:
            break;
        }

        if (status[0].reports[type].status === "Erledigt") {
          status[0].reports[type] = {
            ...this.state.surveyStatus[0].reports[type],
            result: resultStatus,
            className: className,
          };
        } else if (status[0].reports[type + 1].status === "Erledigt") {
          status[0].reports[type + 1] = {
            ...this.state.surveyStatus[0].reports[type + 1],
            result: resultStatus,
            className: className,
          };
        } else if (status[0].reports[type + 2].status === "Erledigt") {
          status[0].reports[type + 2] = {
            ...this.state.surveyStatus[0].reports[type + 2],
            result: resultStatus,
            className: className,
          };
        } else if (status[0].reports[type + 3].status === "Erledigt") {
          status[0].reports[type + 3] = {
            ...this.state.surveyStatus[0].reports[type + 3],
            result: resultStatus,
            className: className,
          };
        }
        this.setState({ surveyStatus: status });
      }
    }
    // }
    this.viewedReport();
  }

  /**Method sets survey state for every patient and for every stw-type in surveyStatus-list.
   * States can be "Erledigt" || "Ausstehend" || "Nicht verfügbar".
   * Default is "Ausstehend". If the type is in reports-list then "Erledigt".
   * If the stw not exist or the end-date is in the past then "Nicht verfügbar".
   */
  surveyState() {
    console.log("surveyState()");

    for (var i = 0; i < this.state.reports.length; i++) {
      let status = [...this.state.surveyStatus];
      var session_id = this.state.reports[i].survey_session_id;

      status[0] = {
        ...this.state.surveyStatus[0],
        session_id_list: [
          ...this.state.surveyStatus[0].session_id_list,
          session_id,
        ],
      };

      switch (this.state.reports[i].stw_type) {
        case "PRE":
          status[0].reports[0] = {
            ...this.state.surveyStatus[0].reports[0],
            status: "Erledigt",
            survey_session_id: session_id,
          };

          break;
        case "POST":
          status[0].reports[1] = {
            ...this.state.surveyStatus[0].reports[1],
            status: "Erledigt",
            survey_session_id: session_id,
          };
          break;
        case "FOLLOW_UP_1":
          status[0].reports[2] = {
            ...this.state.surveyStatus[0].reports[2],
            status: "Erledigt",
            survey_session_id: session_id,
          };
          break;
        case "FOLLOW_UP_2":
          status[0].reports[3] = {
            ...this.state.surveyStatus[0].reports[3],
            status: "Erledigt",
            survey_session_id: session_id,
          };
          break;
        default:
          break;
      }
      this.setState({ surveyStatus: status });
    }

    console.log(
      "Reports: ",
      this.state.surveyStatus[0].reports,
      "STWS: ",
      this.state.stws
    );
    if (this.state.stws.length !== this.state.surveyStatus[0].reports.length) {
      var arr = [];
      for (var k = 0; k < this.state.stws.length; k++) {
        arr.push(this.state.stws[k].type);
      }
      let status = [...this.state.surveyStatus];
      if (!arr.includes("PRE")) {
        status[0].reports[0] = {
          ...this.state.surveyStatus[0].reports[0],
          status: "Nicht verfügbar",
        };
      }
      if (!arr.includes("POST")) {
        status[0].reports[1] = {
          ...this.state.surveyStatus[0].reports[1],
          status: "Nicht verfügbar",
        };
      }
      if (!arr.includes("FOLLOW_UP_1")) {
        status[0].reports[2] = {
          ...this.state.surveyStatus[0].reports[2],
          status: "Nicht verfügbar",
        };
      }
      if (!arr.includes("FOLLOW_UP_2")) {
        status[0].reports[3] = {
          ...this.state.surveyStatus[0].reports[3],
          status: "Nicht verfügbar",
        };
      }
      this.setState({ surveyStatus: status });
    }

    for (var n = 0; n < this.state.stws.length; n++) {
      let status = [...this.state.surveyStatus];

      for (var m = 0; m < this.state.surveyStatus[0].reports.length; m++) {
        if (
          this.state.stws[n].type ===
            this.state.surveyStatus[0].reports[m].type &&
          this.state.surveyStatus[0].reports[m].status !== "Erledigt"
        ) {
          if (this.state.stws[n].end < this.state.today) {
            status[0].reports[m] = {
              ...this.state.surveyStatus[0].reports[m],
              status: "Nicht verfügbar",
            };
          }
          this.setState({ surveyStatus: status });
        }
      }
    }
  }

  /**Update viewed-report state for every patient.
   * If all available reports are in viewed-list, then viewed: "Alle Report gelesen", else "Ungelesener Report."
   */
  viewedReport() {
    console.log("viewedReport()");
    let status = [...this.state.surveyStatus];
    if (this.state.surveyStatus[0].session_id_list.length !== 0) {
      for (var l = 0; l < this.state.surveyStatus[0].reports.length; l++) {
        var session_id =
          this.state.surveyStatus[0].reports[l].survey_session_id;
        if (session_id !== "") {
          console.log("Viewed: ", this.state.viewedReports, "ID: ", session_id);
          if (this.state.viewedReports.includes(session_id)) {
            console.log("Session-ID enthalten in viewed: ", session_id);
            var classNameOld = this.state.surveyStatus[0].reports[l].className;
            console.log("ClassNameOld: ", classNameOld);
            status[0].reports[l] = {
              ...this.state.surveyStatus[0].reports[l],
              className: classNameOld + " result-seen",
            };
          }
        }
      }
      this.setState({ surveyStatus: status });
      // console.log("Nochmals aktualisiert: ", this.state.surveyStatus);
    }
  }

  handleAccordion = (input) => {
    if (input === "myPatients") {
      this.state.accordionMyPatients === "-1"
        ? this.setState({ accordionMyPatients: "0" })
        : this.setState({ accordionMyPatients: "-1" });
      this.setState({ accordionMyClinics: "-1" });
    } else if (input === "clinics") {
      this.state.accordionMyClinics === "-1"
        ? this.setState({ accordionMyClinics: "1" })
        : this.setState({ accordionMyClinics: "-1" });
      this.setState({ accordionMyPatients: "-1" });
    } else if (input === "info") {
      this.setState({ accordionMyPatients: "-1", accordionMyClinics: "-1" });
    } else if (input === "closeAll") {
      this.setState({
        accordionMyPatients: "-1",
        accordionMyClinics: "-1",
      });
    }
  };

  /**Open clinician report. */
  openClinicianReport = (event) => {
    event.preventDefault();
    console.log("Event: ", event.target);
    if (event.target.id !== "") {
      var patient_code = event.target.parentElement.id;
      var patients = this.state.patients;
      var stw_type;
      var started_time;
      console.log("Patient: ", patient_code);
      for (var i = 0; i < patients.length; i++) {
        if (patients[i].code === patient_code) {
          var birthday = patients[i].date_of_birth;
          var gender = patients[i].gender;
        }
      }
      var survey_session_id = event.target.id;

      for (var j = 0; j < this.state.reports.length; j++) {
        // for (var k = 0; k < this.state.reportsParallel[j].reports.length; k++) {
        if (
          survey_session_id ===
          this.state.reports[j].survey_session_id.toString()
        ) {
          stw_type = this.state.reports[j].stw_type;
          started_time = this.state.reports[j].started_time;
        }
        // }
      }

      var started_time_date = started_time.split("T");
      var started_time_split = started_time_date[0].split("-");
      var started_time_format =
        started_time_split[2] +
        "." +
        started_time_split[1] +
        "." +
        started_time_split[0];
      console.log("Datum: ", started_time_format);

      this.props.history.push({
        pathname: "/clinician/clinician-report",
        state: {
          clinic_id: this.state.value,
          clinic_name: this.state.clinic_name,
          username: this.state.username,
          survey_session_id: event.target.id,
          patient_code: patient_code,
          birthday: birthday,
          gender: gender,
          stw_type: stw_type,
          started_time: started_time_format,
          provisionLevel: this.state.provisionLevel,
        },
      });
    }
  };

  /**React render method. */
  render() {
    return (
      <ErrorBoundary>
        <div className="header-fixed" id="body">
          <TimeoutSession />
          <NavigationBar>
            {this.state.clinic_id}
            {this.state.clinic_name}
            {this.state.username}
            {this.state.user_type}
            {this.state.provisionLevel}
          </NavigationBar>
          <div className="page-wrapper-new">
            <div className="wrapper">
              <div className="content-wrapper">
                <SidebarClinician
                  handleAccordion={this.handleAccordion}
                  state={this.state}
                />

                <div className="content">
                  <Accordion activeKey={this.state.accordionMyPatients}>
                    <Accordion.Item eventKey="0" className="card-default">
                      <Accordion.Header
                        onClick={() => this.handleAccordion("myPatients")}
                      >
                        Meine Patient*innen
                      </Accordion.Header>
                      <Accordion.Body>
                        <BootstrapTable
                          keyField="code"
                          hover
                          bordered
                          filter={filterFactory()}
                          columns={[
                            { dataField: "edit", text: "" },
                            {
                              dataField: "code",
                              text: "Reha-ID",
                              sort: true,
                              filter: textFilter({ placeholder: "Suche..." }),
                            },
                            {
                              dataField: "date_of_birth",
                              text: "Geburtsdatum",
                              sort: true,
                              sortValue: (cell, row) => {
                                var dateSplit = cell.split(".");
                                var newDate = new Date(
                                  dateSplit[2] +
                                    "-" +
                                    dateSplit[1] +
                                    "-" +
                                    dateSplit[0]
                                );
                                return (cell = newDate);
                              },
                              filter: dateFilter(),
                              filterValue: (cell, row) => {
                                var dateSplit = cell.split(".");
                                var newDate = new Date(
                                  dateSplit[2] +
                                    "-" +
                                    dateSplit[1] +
                                    "-" +
                                    dateSplit[0]
                                );
                                return (cell = newDate);
                              },
                            },
                            {
                              dataField: "gender",
                              text: "Geschlecht",
                              sort: true,
                              filter: selectFilter({
                                options: {
                                  Weiblich: "Weiblich",
                                  Männlich: "Männlich",
                                  Divers: "Divers",
                                },
                                placeholder: "Auswählen...",
                              }),
                            },
                            {
                              dataField: "admission",
                              text: "Aufnahmedatum",
                              sort: true,
                              sortValue: (cell, row) => {
                                var dateSplit = cell.split(".");
                                var newDate = new Date(
                                  dateSplit[2] +
                                    "-" +
                                    dateSplit[1] +
                                    "-" +
                                    dateSplit[0]
                                );
                                return (cell = newDate);
                              },
                              filter: dateFilter(),
                              filterValue: (cell, row) => {
                                var dateSplit = cell.split(".");
                                var newDate = new Date(
                                  dateSplit[2] +
                                    "-" +
                                    dateSplit[1] +
                                    "-" +
                                    dateSplit[0]
                                );
                                return (cell = newDate);
                              },
                            },
                            {
                              dataField: "discharge",
                              text: "Entlassdatum",
                              sort: true,
                              sortValue: (cell, row) => {
                                var dateSplit = cell.split(".");
                                var newDate = new Date(
                                  dateSplit[2] +
                                    "-" +
                                    dateSplit[1] +
                                    "-" +
                                    dateSplit[0]
                                );
                                return (cell = newDate);
                              },
                              filter: dateFilter(),
                              filterValue: (cell, row) => {
                                var dateSplit = cell.split(".");
                                var newDate = new Date(
                                  dateSplit[2] +
                                    "-" +
                                    dateSplit[1] +
                                    "-" +
                                    dateSplit[0]
                                );
                                return (cell = newDate);
                              },
                            },
                            this.state.provisionLevel === "PLUS"
                              ? {
                                  dataField: "state",
                                  text: "Befragungsstatus",
                                }
                              : {},

                            { dataField: "result", text: "Ergebnis" },
                            this.state.provisionLevel === "PLUS"
                              ? {
                                  dataField: "viewed",
                                  text: "Reports gesehen",
                                  sort: true,
                                  filter: selectFilter({
                                    options: {
                                      "Nicht verfügbar": "Nicht verfügbar",
                                      "Ungelesener Report":
                                        "Ungelesener Report",
                                      "Alle Reports gelesen":
                                        "Alle Reports gelesen",
                                    },
                                    placeholder: "Auswählen...",
                                  }),
                                }
                              : {},
                          ]}
                          data={this.state.patientSortList}
                          headerClasses={"tablehead-fixed"}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion
                    defaultActiveKey="0"
                    activeKey={this.state.accordionMyClinics}
                    onClick={() => this.handleAccordion("clinics")}
                  >
                    <Accordion.Item eventKey="1" className="card-default">
                      <Accordion.Header>Klinik</Accordion.Header>
                      <Accordion.Body>
                        Ihre Klinik ist die <em>{this.state.clinic_name}</em>.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Modal
                    backdrop="static"
                    show={this.state.modalSurveyStateOpen}
                    onHide={this.closeSurveyStateModal}
                  >
                    <ModalHeader closeButton>
                      <ModalTitle>
                        Befragungsstatus von{" "}
                        <em>{this.state.currentPatient.code}</em>
                      </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                      {this.state.surveyStatus &&
                        this.state.surveyStatus.length > 0 &&
                        this.state.surveyStatus.map((status, idx) => {
                          let fu2Enabled =
                            window.APP_CONFIG.REACT_APP_REHACAT_FU2_ENABLED ??
                            true;
                          return (
                            <div key={idx}>
                              <div>Aufnahme: {status.reports[0].status}</div>
                              <div>Entlassung: {status.reports[1].status}</div>
                              <div>Nachsorge 1: {status.reports[2].status}</div>

                              {fu2Enabled ? (
                                <div>
                                  Nachsorge 2: {status.reports[3].status}
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        id={this.state.currentPatient.id}
                        onClick={this.openResultModal}
                      >
                        Ergebnis anzeigen
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    backdrop="static"
                    show={this.state.modalResultOpen}
                    onHide={this.closeResultModal}
                  >
                    <ModalHeader closeButton>
                      <ModalTitle>
                        Ergebnis von <em>{this.state.currentPatient.code}</em>
                      </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                      {this.state.surveyStatus &&
                        this.state.surveyStatus.length > 0 &&
                        this.state.surveyStatus.map((status, idx) => {
                          let fu2Enabled =
                            window.APP_CONFIG.REACT_APP_REHACAT_FU2_ENABLED ??
                            true;
                          return (
                            <div key={idx} id={status.patientCode}>
                              <div
                                id={status.reports[0].survey_session_id}
                                className={status.reports[0].className}
                                onClick={this.openClinicianReport}
                              >
                                Aufnahme: {status.reports[0].result}
                              </div>
                              <div
                                id={status.reports[1].survey_session_id}
                                className={status.reports[1].className}
                                onClick={this.openClinicianReport}
                              >
                                Entlassung: {status.reports[1].result}
                              </div>

                              <div
                                id={status.reports[2].survey_session_id}
                                className={status.reports[2].className}
                                onClick={this.openClinicianReport}
                              >
                                Nachsorge 1: {status.reports[2].result}
                              </div>
                              {fu2Enabled ? (
                                <div
                                  id={status.reports[3].survey_session_id}
                                  className={status.reports[3].className}
                                  onClick={this.openClinicianReport}
                                >
                                  Nachsorge 2: {status.reports[3].result}
                                </div>
                              ) : null}

                              <br />
                            </div>
                          );
                        })}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        id={this.state.currentPatient.id}
                        onClick={this.openSurveyStateModal}
                      >
                        Befragungsstatus anzeigen
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    backdrop="static"
                    show={this.state.modalAssignClinicianOpen}
                    onHide={this.closeAssignClinicianModal}
                  >
                    <ModalHeader closeButton>
                      <ModalTitle>Anderen Mitarbeitenden zuweisen</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                      <Form noValidate onSubmit={this.handleClinicianAssign}>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="5">
                            Patient*in
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              className="form-control-plain"
                              plaintext
                              readOnly
                              defaultValue={this.state.code}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="5">
                            Mitarbeitende ändern:
                          </Form.Label>
                          <Col sm="7">
                            <Form.Control
                              className="form-control"
                              required
                              type="username"
                              placeholder="Name der Mitarbeitenden"
                              // value={this.state.value}
                              onChange={(text) => {
                                this.handleClinician(text);
                              }}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Button type="submit">Mitarbeitende ändern</Button>
                      </Form>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        variant="danger"
                        onClick={this.closeAssignClinicianModal}
                      >
                        Abbrechen
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    backdrop="static"
                    show={this.state.modalLoadingOpen}
                    onHide={this.closeLoadingModal}
                  >
                    <ModalBody>
                      <div className="loadingDiv">
                        <Spinner
                          className="loadingSpinner"
                          animation="border"
                        />
                      </div>
                      <br />
                      {/* <ProgressBar
                        animated
                        now={this.state.progressBarStatus}
                      /> */}
                      Ihre Daten werden geladen. Dies kann einen Moment dauern.
                    </ModalBody>
                  </Modal>
                </div>
              </div>
              <Footer>
                {this.state.clinic_id}
                {this.state.clinic_name}
                {this.state.username}
                {this.state.user_type}
                {this.state.provisionLevel}
              </Footer>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
export default HomeClinician;
