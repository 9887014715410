import React from "react";
import { Component } from "react";
// import { reportGetProvisionLevelError } from "../helper/alertBoxes";
import TimeoutSession from "../helper/TimeoutSession";
// import AuthService from "../services/AuthService";
import Footer from "./Footer";
import NavigationBar from "./navigation/NavigationBar";
import SidebarAdmin from "./sidebars/SidebarAdmin";
import SidebarClinician from "./sidebars/SidebarClinician";
import SidebarPatient from "./sidebars/SidebarPatient";

/**This component contains the content of the contact page. */
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic_id: "",
      clinic_name: "",
      username: "",
      user_type: "",
      provisionLevel: "",
      accordionCurrent: "-1",
      accordionInformation: "-1",
      accordionSurvey: "-1",
      accordionReport: "-1",
      accordionMyPatients: "-1",
      accordionMyClinics: "-1",
      accordionPatients: "-1",
      accordionEmployees: "-1",
      accordionClinic: "-1",
    };
  }

  /**Sets user states from children. */
  componentDidMount() {
    // try {
    //   AuthService.getContent("/api/auth/clinics/provision-level")
    //     .then((response) => {
    //       console.info("Provision-Level: ", response.data);
    //       this.setState({ provisionLevel: response.data });
    //     })
    //     .catch((error) => {
    //       reportGetProvisionLevelError(error);
    //     });
    // } catch (error) {}
    this.setState({
      clinic_name: this.props.location.state.clinic_name,
      clinic_id: this.props.location.state.clinic_id,
      username: this.props.location.state.username,
      user_type: this.props.location.state.user_type,
      provisionLevel: this.props.location.state.provisionLevel,
    });
  }

  /**When clicking on sidebar, check which user_type the user has and which menu item is selected.
   * Open on dashboard the right accordion.
   */
  handleAccordion = (input) => {
    switch (this.state.user_type) {
      case "patient":
        var accCurrent = "-1";
        var accInfo = "-1";
        var accSurvey = "-1";
        var accReports = "-1";
        switch (input) {
          case "current":
            accCurrent = "0";
            break;
          case "info":
            accInfo = "1";
            break;
          case "survey":
            accSurvey = "2";
            break;
          case "report":
            accReports = "3";
            break;
          default:
            break;
        }
        if (input !== "general") {
          this.props.history.push({
            pathname: "/patients",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionCurrent: accCurrent,
              accordionInformation: accInfo,
              accordionSurvey: accSurvey,
              accordionReport: accReports,
            },
          });
        }

        break;
      case "clinician":
        var accPatients = "-1";
        var accClinics = "-1";
        switch (input) {
          case "myPatients":
            accPatients = "0";
            break;
          case "clinics":
            accClinics = "1";
            break;
          default:
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/clinician",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionMyPatients: accPatients,
              accordionMyClinics: accClinics,
            },
          });
        }

        break;
      case "admin":
        var accPatient = "-1";
        var accEmployees = "-1";
        var accClinic = "-1";
        switch (input) {
          case "patients":
            accPatient = "0";
            break;
          case "employees":
            accEmployees = "1";
            break;
          case "clinic":
            accClinic = "2";
            break;
          default:
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/admin",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionPatients: accPatient,
              accordionEmployees: accEmployees,
              accordionClinic: accClinic,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  /**React render method. */
  render() {
    return (
      <div className="header-fixed" id="body">
        <TimeoutSession />
        <NavigationBar>
          {this.state.clinic_id}
          {this.state.clinic_name}
          {this.state.username}
          {this.state.user_type}
          {this.state.provisionLevel}
        </NavigationBar>
        <div className="page-wrapper-new">
          <div className="wrapper">
            {this.state.user_type === "patient" ? (
              <SidebarPatient
                handleAccordion={this.handleAccordion}
                state={this.state}
              />
            ) : this.state.user_type === "clinician" ? (
              <SidebarClinician
                handleAccordion={this.handleAccordion}
                state={this.state}
              />
            ) : (
              <SidebarAdmin
                handleAccordion={this.handleAccordion}
                state={this.state}
              />
            )}
            <div className="content-wrapper">
              <div className="content">
                <h3>Kontakt</h3>
                {this.state.user_type === "patient" ? (
                  <p>
                    Bitte wenden Sie sich an Ihr zuständiges Klinikpersonal.
                  </p>
                ) : this.state.user_type === "clinician" ? (
                  <p>Bitte wenden Sie sich an Ihre*n Klinikadministrator*in.</p>
                ) : (
                  <p>
                    Bitte wenden Sie sich an{" "}
                    <a href="mailto:rehacat.technik@uni-ulm.de">
                      rehacat.technik@uni-ulm.de
                    </a>
                    .{" "}
                  </p>
                )}
              </div>
            </div>
            <Footer>
              {this.state.clinic_id}
              {this.state.clinic_name}
              {this.state.username}
              {this.state.user_type}
              {this.state.provisionLevel}
            </Footer>
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
