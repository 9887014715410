/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  BsBellFill,
  BsFiles,
  BsFillPersonFill,
  BsQuestionSquareFill,
} from "react-icons/bs";
import { IoMdInformationCircle, IoIosDocument } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { PubSub } from "../../helper/pubSub";
import "./Sidebar.css";

const SidebarPatient = (props) => {
  const { handleAccordion } = props;
  const { state } = props;
  const location = useLocation();
  const generalPaths = ["/impressum", "/privacypolicy", "/contact"]; //all paths belonging to general section. For these paths the menu stays open
  let general = false;

  if (generalPaths.includes(location.pathname)) {
    general = true;
  }

  const [display, setDisplay] = useState({
    current: true,
    profile: false,
    survey: false,
    report: false,
    general,
  });

  PubSub.getInstance().subscribeToTopic(
    "open-sidebar-submenu",
    ({ menuName }) => {
      if (menuName === "current") {
        handleCurrent();
      }
    }
  );

  const handleCurrent = () => {
    setDisplay((prevState) => ({
      ...prevState,
      current: !display.current,
      information: false,
      survey: false,
      report: false,
      general: false,
    }));
    handleAccordion("current");
  };

  const handleProfile = () => {
    setDisplay((prevState) => ({
      ...prevState,
      current: false,
      profile: !display.profile,
      survey: false,
      report: false,
      general: false,
    }));
    handleAccordion("info");
  };

  const handleSurvey = () => {
    setDisplay((prevState) => ({
      ...prevState,
      current: false,
      profile: false,
      survey: !display.survey,
      report: false,
      general: false,
    }));
    handleAccordion("survey");
  };

  const handleReport = () => {
    setDisplay((prevState) => ({
      ...prevState,
      current: false,
      profile: false,
      survey: false,
      report: !display.report,
      general: false,
    }));
    handleAccordion("report");
  };

  const handleGeneral = () => {
    setDisplay((prevState) => ({
      ...prevState,
      current: false,
      profile: false,
      survey: false,
      report: false,
      general: !display.general,
    }));
    handleAccordion("general");
  };

  useEffect(() => {
    setDisplay((prevState) => ({
      ...prevState,
      current: state.accordionCurrent === "0",
      profile: state.accordionInformation === "1",
      survey: state.accordionSurvey === "2",
      report: state.accordionReport === "3",
    }));
  }, [state]);

  const renderCurrent = () => (
    <li
      className={display.current ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleCurrent}
    >
      <a className="sidenav-item-link">
        <i className="mdi">
          <BsBellFill />
        </i>
        <span className="nav-text">Aktuelles</span> <b className="caret" />
      </a>
    </li>
  );

  const renderProfile = () => (
    <li
      className={display.profile ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleProfile}
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        aria-expanded="false"
      >
        <i className="mdi">
          {" "}
          <BsFillPersonFill />
        </i>
        <span className="nav-text">Profil</span> <b className="caret" />
      </a>
    </li>
  );

  const renderSurvey = () => (
    <li
      className={display.survey ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleSurvey}
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        data-target="#components"
        aria-expanded="false"
        aria-controls="components"
      >
        <i className="mdi">
          {" "}
          <BsQuestionSquareFill />
        </i>
        <span className="nav-text">Befragungen</span> <b className="caret" />
      </a>
    </li>
  );

  const renderReports = () => (
    <li
      className={display.report ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleReport}
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        aria-expanded="false"
      >
        <i className="mdi">
          {" "}
          <BsFiles />
        </i>
        <span className="nav-text">Berichte</span> <b className="caret" />
      </a>
    </li>
  );

  const renderInformation = () => (
    <li
      className={location.pathname === "/patients/information" ? "active" : ""}
    >
      <Link
        className="sidenav-item-link"
        to={{
          pathname: "/patients/information",
          state: {
            clinic_id: state.clinic_id,
            clinic_name: state.clinic_name,
            username: state.username,
            user_type: state.user_type,
            provisionLevel: state.provisionLevel,
          },
        }}
      >
        <i className="mdi">
          {" "}
          <IoMdInformationCircle />
        </i>
        <span className="nav-text">Informationen</span> <b className="caret" />
      </Link>
    </li>
  );

  const renderGeneralSubMenu = () => (
    <ul
      className={display.general ? "show" : "collapse"}
      id="documentation"
      data-parent="#sidebar-menu"
    >
      <div className="sub-menu p-0">
        <li className="">
          <Link
            to={{
              pathname: "/impressum",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Impressum</span>
          </Link>
        </li>
        <li className="">
          <Link
            to={{
              pathname: "/privacypolicy",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Datenschutz</span>
          </Link>
        </li>
        <li className="">
          <Link
            to={{
              pathname: "/contact",
              state: {
                clinic_id: state.clinic_id,
                clinic_name: state.clinic_name,
                username: state.username,
                user_type: state.user_type,
                provisionLevel: state.provisionLevel,
              },
            }}
          >
            <span className="nav-text">Kontakt</span>
          </Link>
        </li>
      </div>
    </ul>
  );

  const renderGeneral = () => (
    <li
      className={display.general ? "active" : ""}
      style={{ cursor: "pointer" }}
      onClick={handleGeneral}
      id="patient-sidebar-general"
    >
      <a
        className="sidenav-item-link"
        data-toggle="collapse"
        data-target="#documentation"
        aria-expanded="false"
        aria-controls="documentation"
      >
        <i className="mdi">
          <IoIosDocument />
        </i>
        <span className="nav-text">Allgemeines</span> <b className="caret" />
      </a>
      {renderGeneralSubMenu()}
    </li>
  );

  return (
    <div>
      <div id="sidebar" className="sidebar sidebar-with-footer">
        <div data-simplebar height="100%">
          <ul className="nav sidebar-inner" id="sidebar-menu">
            {renderCurrent()}
            {renderProfile()}
            {renderSurvey()}
            {state.provisionLevel === "PLUS" ? renderReports() : null}
            <hr className="separator mb-0" />
            {renderInformation()}
            {renderGeneral()}
          </ul>
        </div>

        <div className="sidebar-footer sidebar-footer-content">
          <a
            href="https://www.uni-ulm.de/in/abt-klinische-psychologie-und-psychotherapie"
            target="blank"
          >
            <img src="../img/Logo_uulm.png" width="160px" alt="Logo Uni Ulm" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarPatient;
