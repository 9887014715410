import axios from "axios";
import { showAlertModal } from "../helper/alertBoxes";

declare var window;
axios.defaults.baseURL = window.APP_CONFIG.REACT_APP_BACKEND_URL;

const loginPageUrls = {
  patient: "/loginpatient",
  clinician: "/loginclinician",
  admin: "/loginadmin",
};

/**
 * Service class for communication between backend and frontend.
 */
class AuthService {
  /**Login method sends POST-Request to backend api.
   * @param loginData Login data
   * @param role {string} patient || clinician || admin
   */
  async login(loginData: LoginData, role: Role): Promise<SignInResult> {
    //userRole = user;
    if (!["patient", "clinician", "admin"].includes(role)) {
      throw new Error("login() called with invalid role");
    }
    const loginApiUrls = {
      admin: "/api/clinic-admins/login",
      clinician: "/api/clinicians/login",
      patient: "/api/patients/login",
    };
    let url = loginApiUrls[role];
    let loginDataJson = JSON.stringify(loginData);
    sessionStorage.setItem("usr", role);
    console.log("Nutzer: ", role);
    console.log("JSON", loginData);

    let response;
    let signInResult: SignInResult = "error";
    try {
      response = await axios.post(url, loginDataJson, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.status === 200) {
        signInResult = "success";
      }
    } catch (err: any) {
      if (err?.response?.status === 401) {
        signInResult = "credentials_wrong";
      } else if (err?.response?.status === 403) {
        signInResult = "no_permissions";
      } else {
        signInResult = "error";
      }
    }

    console.log("AuthResponse: ", response);

    if (signInResult === "success") {
      sessionStorage.setItem("auth", "true");
    }

    return signInResult;
  }

  /**
   * Returns true if the user is logged in, else false
   * @returns
   */
  isLoggedIn(): boolean {
    return sessionStorage.getItem("auth") === "true";
  }

  /**Method sends POST-request to backend api.
   * @param json {string} Stringified data
   * @param url {string} Backend api url
   */
  async regist(json, url) {
    return await axios
      .post(url, json, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("AuthResponse: ", response);
        return response;
      });
  }

  /**Logout Method sends DELETE-request to backend api. After the DELETE-Request the user would be redirect to the right login.
   */
  async logout() {
    const apiUrl = "/api/refresh-tokens";
    try {
      await axios.delete(apiUrl, {
        withCredentials: true,
      });
      let userRole = sessionStorage.getItem("usr");
      let loginPageUrl =
        loginPageUrls[userRole ?? "randomStringToSatisfyTypescript"];

      if (loginPageUrl) {
        window.location.replace(loginPageUrl);
      } else {
        window.location.replace("/");
        showAlertModal("Der Nutzer konnte nicht identifiziert werden.");
      }
      sessionStorage.removeItem("auth");
      sessionStorage.removeItem("usr");
    } catch (err) {
      console.log(
        "Error when deleting the refresh token: ",
        err,
        "This may be totally fine if the token already got deleted"
      );
      return;
    }
  }
}

export default new AuthService();

type Role = "clinician" | "patient" | "admin";

interface LoginData {
  clinic_id: string;
  username: string;
  password: string;
}

export type SignInResult =
  | "success"
  | "credentials_wrong"
  | "no_permissions"
  | "error";
