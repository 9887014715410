import React from "react";
import { Component } from "react";
import { Modal, ModalBody, ModalTitle, Nav, Navbar } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { Link } from "react-router-dom";
import AuthService from "../services/AuthService";
// import { reportGetProvisionLevelError } from "../helper/alertBoxes";

/**Footer component */
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      provisionLevel: "",
      modalContactOpen: false,
    };
  }

  /**Checks wheter the user is logged in or not.
   * If the user is logged in, the states would be set.
   */
  componentDidMount() {
    var loggedIn = AuthService.isLoggedIn();
    this.setState({
      isLoggedIn: loggedIn,
    });

    console.log("Provisionlevel: ", this.props.children);

    // if (this.props.location.state === undefined)
    if (loggedIn) {
      // try {
      //   AuthService.getContent("/api/auth/clinics/provision-level")
      //     .then((response) => {
      //       console.info("Provision-Level: ", response.data);
      //       this.setState({ provisionLevel: response.data });
      //     })
      //     .catch((error) => {
      //       reportGetProvisionLevelError(error);
      //     });
      // } catch (error) {}
    }
  }

  openContactModal = () => {
    this.setState({ modalContactOpen: true });
  };

  closeContactModal = () => this.setState({ modalContactOpen: false });

  /**React render method */
  render() {
    return (
      <>
        <Navbar className="footer-custom nav-custom" fixed="bottom">
          <NavbarCollapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Item>
                {this.state.isLoggedIn ? (
                  <Link
                    className="footer-link"
                    to={{
                      pathname: "/impressum",
                      state: {
                        clinic_name: this.props.children[1],
                        clinic_id: this.props.children[0],
                        username: this.props.children[2],
                        user_type: this.props.children[3],
                        provisionLevel: this.props.children[4],
                      },
                    }}
                  >
                    <span className="nav-text">Impressum</span>
                  </Link>
                ) : (
                  <Nav.Link href="/impressum">Impressum</Nav.Link>
                )}
              </Nav.Item>
              <Nav.Item>
                {this.state.isLoggedIn ? (
                  <Link
                    className="footer-link"
                    to={{
                      pathname: "/privacypolicy",
                      state: {
                        clinic_name: this.props.children[1],
                        clinic_id: this.props.children[0],
                        username: this.props.children[2],
                        user_type: this.props.children[3],
                        provisionLevel: this.props.children[4],
                      },
                    }}
                  >
                    <span className="nav-text">Datenschutz</span>
                  </Link>
                ) : (
                  <Nav.Link href="/privacypolicy">Datenschutz</Nav.Link>
                )}
              </Nav.Item>
              <Nav.Item>
                {this.state.isLoggedIn ? (
                  <Link
                    className="footer-link"
                    to={{
                      pathname: "/contact",
                      state: {
                        clinic_name: this.props.children[1],
                        clinic_id: this.props.children[0],
                        username: this.props.children[2],
                        user_type: this.props.children[3],
                        provisionLevel: this.props.children[4],
                      },
                    }}
                  >
                    <span className="nav-text">Kontakt</span>
                  </Link>
                ) : (
                  <Nav.Link onClick={this.openContactModal}>Kontakt</Nav.Link>
                  // <Nav.Link href="/contact">Kontakt</Nav.Link>
                )}
              </Nav.Item>
            </Nav>
          </NavbarCollapse>
        </Navbar>

        <Modal
          show={this.state.modalContactOpen}
          onHide={this.closeContactModal}
        >
          <ModalHeader closeButton>
            <ModalTitle>Kontakt</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {this.props.children === "patient" ? (
              <p>Bitte wenden Sie sich an Ihr zuständiges Klinikpersonal.</p>
            ) : this.props.children === "clinician" ? (
              <p>Bitte wenden Sie sich an Ihre*n Klinikadministrator*in.</p>
            ) : (
              <p>
                Bitte wenden Sie sich an{" "}
                <a href="mailto:rehacat.technik@uni-ulm.de">
                  rehacat.technik@uni-ulm.de
                </a>
                .{" "}
              </p>
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default Footer;
