import axios from "axios";
import { showAlertModal } from "../helper/alertBoxes";

const loginPageUrls = {
  patient: "/loginpatient",
  clinician: "/loginclinician",
  admin: "/loginadmin",
};

/**
 * All functions that are directly related to session management should go here.
 * This includes creating a session by logging in, refreshing the token if it expired
 * and ending the session by logging out.
 */

/**
 * The purpose of this function is to abstract away the complexity of refreshing a token
 * when necessary and then retrying the request that failed because of the invalid token
 *
 * This function takes a function to execute as an argument. If that function throws
 * an axios error with a status code of 403 (which stands for an invalid token) it calls
 * the refresh endpoint to refresh the token and then it calls the given function again ONCE.
 * If the function throws the error again the error is passed along. Other errors are always passed along.
 * In case of a success the result of the function is returned
 * @param fn
 */
export async function retryIfTokenInvalid<Type>(fn: () => Type): Promise<Type> {
  let currentNumTries = 0;
  const maxNumTries = 1;
  while (true) {
    let result;
    let haveTriesLeft = currentNumTries < maxNumTries;
    currentNumTries++;
    try {
      result = await fn();
    } catch (err: any) {
      if (err?.response?.status === 403 && haveTriesLeft) {
        console.log("The token has expired. Refreshing it...");
        await refreshToken();
        continue;
      } else {
        throw err;
      }
    }

    return result;
  }
}

async function refreshToken() {
  let response;
  const url = "/api/refresh-tokens";
  try {
    response = await axios.get(url, {
      withCredentials: true,
    });
    console.log("Refresh-Response: ", response);
  } catch (error) {
    sessionStorage.removeItem("auth");
    let userRole = sessionStorage.getItem("usr");
    showAlertModal("Bitte melden Sie sich erneut an."); //we should probably find a better place to show this dialog since this is the wrong abstraction level for it

    let loginPageUrl =
      loginPageUrls[userRole ?? "randomStringToSatisfyTypescript"];

    if (loginPageUrl) {
      window.location.replace(loginPageUrl);
    }
    sessionStorage.removeItem("usr");
  }
}
