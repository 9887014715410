import React from "react";
import { Component } from "react";
import AuthService from "../services/AuthService";
import NavigationBar from "./navigation/NavigationBar";
import SidebarPatient from "./sidebars/SidebarPatient";
import SidebarAdmin from "./sidebars/SidebarAdmin";
import SidebarClinician from "./sidebars/SidebarClinician";
import Footer from "./Footer";
import { Button } from "react-bootstrap";
import TimeoutSession from "../helper/TimeoutSession";
// import { reportGetProvisionLevelError } from "../helper/alertBoxes";

/**This component contains the content of the impressum */
class Impressum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic_id: "",
      clinic_name: "",
      username: "",
      user_type: "",
      provisionLevel: "",
      isLoggedIn: false,
      accordionCurrent: "-1",
      accordionInformation: "-1",
      accordionSurvey: "-1",
      accordionReport: "-1",
      accordionMyPatients: "-1",
      accordionMyClinics: "-1",
      accordionPatients: "-1",
      accordionEmployees: "-1",
      accordionClinic: "-1",
    };
  }

  /**Checks wheter the user is logged in or not.
   * If the user is logged in, the states would be set.
   */
  componentDidMount() {
    window.scrollTo(0, 0);
    var loggedIn = AuthService.isLoggedIn();
    this.setState({ isLoggedIn: loggedIn });
    if (loggedIn) {
      console.log("Klinikname: ", this.props.location.state.clinic_name);
      this.setState({
        clinic_name: this.props.location.state.clinic_name,
        clinic_id: this.props.location.state.clinic_id,
        username: this.props.location.state.username,
        user_type: this.props.location.state.user_type,
        provisionLevel: this.props.location.state.provisionLevel,
      });

      // if (this.state.provisionLevel === "") {
      //   try {
      //     AuthService.getContent("/api/auth/clinics/provision-level")
      //       .then((response) => {
      //         console.info("Provision-Level: ", response.data);
      //         this.setState({ provisionLevel: response.data });
      //       })
      //       .catch((error) => {
      //         reportGetProvisionLevelError(error);
      //       });
      //   } catch (error) {}
      // }
    }
  }

  /**When clicking on sidebar, check which user_type the user has and which menu item is selected.
   * Open on dashboard the right accordion.
   */
  handleAccordion = (input) => {
    switch (this.state.user_type) {
      case "patient":
        var accCurrent = "-1";
        var accInfo = "-1";
        var accSurvey = "-1";
        var accReports = "-1";
        switch (input) {
          case "current":
            accCurrent = "0";
            break;
          case "info":
            accInfo = "1";
            break;
          case "survey":
            accSurvey = "2";
            break;
          case "report":
            accReports = "3";
            break;
          default:
            break;
        }
        if (input !== "general") {
          this.props.history.push({
            pathname: "/patients",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionCurrent: accCurrent,
              accordionInformation: accInfo,
              accordionSurvey: accSurvey,
              accordionReport: accReports,
            },
          });
        }

        break;
      case "clinician":
        var accPatients = "-1";
        var accClinics = "-1";
        switch (input) {
          case "myPatients":
            accPatients = "0";
            break;
          case "clinics":
            accClinics = "1";
            break;
          default:
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/clinician",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionMyPatients: accPatients,
              accordionMyClinics: accClinics,
            },
          });
        }

        break;
      case "admin":
        var accPatient = "-1";
        var accEmployees = "-1";
        var accClinic = "-1";
        switch (input) {
          case "patients":
            accPatient = "0";
            break;
          case "employees":
            accEmployees = "1";
            break;
          case "clinic":
            accClinic = "2";
            break;
          default:
            break;
        }
        if (input !== "info") {
          this.props.history.push({
            pathname: "/admin",
            state: {
              clinic_id: this.state.clinic_id,
              clinic_name: this.state.clinic_name,
              username: this.state.username,
              provisionLevel: this.state.provisionLevel,
              accordionPatients: accPatient,
              accordionEmployees: accEmployees,
              accordionClinic: accClinic,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  /**React render method */
  render() {
    return (
      <div>
        <div className={this.state.isLoggedIn ? "header-fixed" : ""} id="body">
          {this.state.isLoggedIn ? (
            <>
              <TimeoutSession />
              <NavigationBar>
                {this.state.clinic_id}
                {this.state.clinic_name}
                {this.state.username}
                {this.state.user_type}
                {this.state.provisionLevel}
              </NavigationBar>
            </>
          ) : null}
          <div className={this.state.isLoggedIn ? "page-wrapper-new" : ""}>
            <div className={this.state.isLoggedIn ? "wrapper" : ""}>
              {this.state.isLoggedIn ? (
                this.state.user_type === "patient" ? (
                  <SidebarPatient
                    handleAccordion={this.handleAccordion}
                    state={this.state}
                  />
                ) : this.state.user_type === "clinician" ? (
                  <SidebarClinician
                    handleAccordion={this.handleAccordion}
                    state={this.state}
                  />
                ) : (
                  <SidebarAdmin
                    handleAccordion={this.handleAccordion}
                    state={this.state}
                  />
                )
              ) : null}
              <div className="content-wrapper">
                <div
                  className={
                    this.state.isLoggedIn
                      ? "content"
                      : "content-without-sidebar content-without-navbar"
                  }
                >
                  {!this.state.isLoggedIn ? (
                    <Button
                      variant="primary"
                      id="backToLogin"
                      onClick={() => this.props.history.goBack()}
                    >
                      Zurück
                    </Button>
                  ) : null}
                  <h3>Impressum</h3>

                  <h5>Herausgeber</h5>
                  <p>
                    <em>
                      Universität Ulm <br /> 89069 Ulm
                      <br />
                      Telefon +49 (0)731/50-10
                      <br />
                      Telefax +49 (0)731/50-22038 <br />
                    </em>
                    Umsatzsteueridentifikationsnummer DE173703203
                  </p>

                  <h5>Vertretung</h5>
                  <p>
                    Die Universität Ulm ist eine Körperschaft des öffentlichen
                    Rechts. Sie wird durch den Präsidenten Prof. Dr.-Ing.
                    Michael Weber oder durch den Kanzler Dieter Kaufmann
                    vertreten.
                  </p>
                  <h5>Adresse des Präsidenten</h5>
                  <p>
                    <em>
                      Universität Ulm <br />
                      Präsident Prof. Dr.-Ing. Michael Weber
                      <br />
                      Helmholtzstr. 16
                      <br />
                      89081 Ulm
                    </em>
                  </p>
                  <h5>Adresse des Kanzlers</h5>
                  <p>
                    <em>
                      Universität Ulm
                      <br />
                      Kanzler Dieter Kaufmann
                      <br />
                      Helmholtzstr. 16
                      <br />
                      89081 Ulm
                    </em>
                  </p>
                  <h5>Bankverbindung</h5>
                  <p>
                    <em>
                      Kasse der Universität Ulm
                      <br />
                      Bundesbank Ulm
                      <br />
                      BIC (SWIFT-Code): MARKDEF1630
                      <br />
                      IBAN: DE72 6300 0000 0063 0015 05
                    </em>
                  </p>
                  <h5>Inhalt</h5>
                  <p>
                    Die inhaltliche Verantwortung und die Verantwortung im Sinne
                    des Presserechts liegen bei der jeweiligen
                    informationseinstellenden Einrichtung: <br />
                    <em>
                      <br />
                      Abteilung für Klinische Psychologie und Psychotherapie
                      <br />
                      Prof. Dr. Harald Baumeister
                      <br />
                      Lise-Meitner-Str. 16
                      <br />
                      89081 Ulm
                      <br />
                      Telefon: +49 731 50-32800
                      <br />
                      Fax: +49 731 50-32809
                    </em>
                  </p>
                  <h5>Zuständige Aufsichtsbehörde</h5>
                  <p>
                    <em>
                      Ministerium für Wissenschaft, Forschung und Kunst
                      Baden-Württemberg <br />
                      Königstraße 46 <br />
                      70173 Stuttgart
                    </em>
                  </p>
                  <h5>Copyright</h5>
                  <p>© Universität Ulm</p>
                  <p>
                    Alle auf dieser Website veröffentlichten Inhalte (Layout,
                    Texte, Bilder, Grafiken usw.) unterliegen dem Urheberrecht.
                    Jede vom Urheberrechtsgesetz nicht zugelassene Verwertung
                    bedarf vorheriger ausdrücklicher, schriftlicher Zustimmung
                    der Universität Ulm. Dies gilt insbesondere für
                    Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung,
                    Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken
                    oder anderen elektronischen Medien und Systemen. Fotokopien
                    und Downloads von Web-Seiten für den privaten,
                    wissenschaftlichen und nicht kommerziellen Gebrauch dürfen
                    hergestellt werden.
                  </p>
                  <p>
                    Die Universität Ulm erlaubt ausdrücklich und begrüßt das
                    Zitieren der Dokumente und Webseiten sowie das Setzen von
                    Links auf ihre Website.
                  </p>
                  <p>
                    Die Universität Ulm ist bestrebt, in allen Publikationen die
                    Urheberrechte der verwendeten Grafiken, Tondokumente,
                    Videosequenzen und Texte zu beachten, von ihr selbst
                    erstellte Grafiken, Tondokumente, Videosequenzen und Texte
                    zu nutzen oder auf lizenzfreie Grafiken, Tondokumente,
                    Videosequenzen und Texte zurückzugreifen.
                  </p>
                  <p>
                    Alle innerhalb des Internetangebotes genannten und ggf.
                    durch Dritte geschützten Marken und Warenzeichen unterliegen
                    uneingeschränkt den Bestimmungen des jeweils gültigen
                    Kennzeichenrechts und den Besitzrechten der jeweiligen
                    eingetragenen Eigentümer:innen. Allein aufgrund der bloßen
                    Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen
                    nicht durch Rechte Dritter geschützt sind!
                  </p>
                  <h5>Geschlechtergerechte Schreibweise</h5>
                  <p>
                    Bei der Erstellung der Seiten wurde versucht auf eine
                    geschlechtergerechte Schreibweise zu achten. Trotzdem ist es
                    möglich, dass manche Textstellen nicht geschlechtergerecht
                    ausgedrückt wurden. Wir weisen an dieser Stelle ausdrücklich
                    darauf hin, dass bei der Befragung alle Studienteilnehmende
                    angesprochen werden.
                  </p>
                  <h5>Haftungsausschluss</h5>
                  <p>
                    <u>Haftungsausschluss bei eigenen Inhalten</u>{" "}
                  </p>
                  <p>
                    Die Inhalte dieser Website werden mit größtmöglicher
                    Sorgfalt recherchiert und implementiert. Fehler im
                    Bearbeitungsvorgang sind dennoch nicht auszuschließen.
                    Hinweise und Korrekturen senden Sie bitte an:
                  </p>
                  <p>
                    <em>
                      Universität Ulm
                      <br />
                      Abteilung für Klinische Psychologie und Psychotherapie
                      <br />
                      Prof. Dr. Harald Baumeister
                      <br />
                      Lise-Meitner-Str. 16
                      <br />
                      89081 Ulm
                      <br />
                      <a href="mailto:rehacat@uni-ulm.de">rehacat@uni-ulm.de</a>
                    </em>
                  </p>
                  <p>
                    Eine Haftung für die Richtigkeit, Vollständigkeit und
                    Aktualität dieser Webseiten kann trotz sorgfältiger Prüfung
                    nicht übernommen werden. Die Universität Ulm übernimmt
                    insbesondere keinerlei Haftung für eventuelle Schäden oder
                    Konsequenzen, die durch die direkte oder indirekte Nutzung
                    der angebotenen Inhalte entstehen.
                  </p>
                  <p>
                    <u>Haftungsausschluss bei Links zu externen Inhalten</u>
                  </p>
                  <p>
                    Die Universität Ulm ist als Inhaltsanbieter für die eigenen
                    Inhalte, die sie zur Nutzung bereithält, nach den
                    allgemeinen Gesetzen verantwortlich. Von diesen eigenen
                    Inhalten sind Querverweise („externe Links“) auf die von
                    anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden.
                    Diese fremden Inhalte stammen weder von der Universität Ulm,
                    noch hat die Universität Ulm die Möglichkeit, den Inhalt von
                    Seiten Dritter zu beeinflussen.
                  </p>
                  <p>
                    Die Inhalte fremder Seiten, auf die die Universität Ulm
                    mittels Links hinweist, spiegeln nicht die Meinung der
                    Universität Ulm wider, sondern dienen lediglich der
                    Information und der Darstellung von Zusammenhängen. Die
                    Universität macht sich diese weitervermittelten Inhalte
                    nicht zu eigen und übernimmt auch keine Verantwortung für
                    diese fremden Inhalte.
                  </p>
                  <p>
                    Diese Feststellungen gelten für alle innerhalb des eigenen
                    Internetangebotes gesetzten Links und Verweise sowie für
                    Fremdeinträge in Gästebüchern, Diskussionsforen und
                    Mailinglisten, die von der Universität Ulm eingerichtet
                    wurden. Für illegale, fehlerhafte oder unvollständige
                    Inhalte und insbesondere für Schäden, die aus der Nutzung
                    oder Nichtnutzung solcherart dargebotener Informationen
                    entstehen, haftet allein die:der Anbieter:in der Seite, auf
                    welche verwiesen wurde.
                  </p>
                  <h5>Konzeption, Realisierung und Gestaltung</h5>
                  <p>
                    <em>
                      Universität Ulm <br />
                      Abteilung für Klinische Psychologie und Psychotherapie{" "}
                      <br />
                      Prof. Dr. Harald Baumeister <br />
                      Lise-Meitner-Str. 16 <br />
                      89081 Ulm <br />
                      Prof. Dr. Harald Baumeister <br />
                      <a href="mailto:rehacat@uni-ulm.de">rehacat@uni-ulm.de</a>
                    </em>
                  </p>
                </div>
              </div>
              {this.state.isLoggedIn ? (
                <Footer>
                  {this.state.clinic_id}
                  {this.state.clinic_name}
                  {this.state.username}
                  {this.state.user_type}
                  {this.state.provisionLevel}
                </Footer>
              ) : (
                <Footer />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Impressum;
