import { Tooltip } from "react-bootstrap";
import React, { Component } from "react";
import { Card, Container, Form, OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { getClinics } from "../../services/getFunctions";
import AuthService from "../../services/AuthService";
import { RiInformationFill } from "react-icons/ri";
import {
  reportGetClinicianListError,
  reportRegistAdminError,
  showAlertModal,
} from "../../helper/alertBoxes";

/**
 * Component for admin registration.
 */
class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      username: "",
      password: "",
      isInvalid: true,
      unauthorized: true,
      clinics: [],
    };
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleClinicId = this.handleClinicId.bind(this);
    this.handleRegist = this.handleRegist.bind(this);
  }
  /**Method loads clinic list from backend. */
  componentDidMount() {
    try {
      getClinics()
        .then((clinics) => {
          console.log("Klinik-Liste: ", clinics);
          this.setState({
            clinics: clinics.clinics,
          });
        })
        .catch((error) => {
          reportGetClinicianListError(error);
        });
    } catch (error) {}
  }

  /**Method to update the clinic id state, when it is changed.
   * @param event {ChangeEvent} onChange delivers the event with the selected option
   */
  handleClinicId(event) {
    this.setState({ value: event.target.value });
    console.log("Klinik-ID: ", this.state.value);
  }

  /**Method to update the username state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handleUsername(text) {
    console.log("Username", this.state.username, " ausgefüllt");
    this.setState({ username: text.target.value });
  }

  /**Method to update the password state, when it is changed.
   * @param text {ChangeEvent} onChange delivers the event with the changed input
   */
  handlePassword(text) {
    console.log("Passwort", this.state.password, " ausgefüllt");
    this.setState({ password: text.target.value });
  }

  /**Handle method for admin registration. */
  handleRegist = (event) => {
    event.preventDefault();
    console.log("Button aktiviert");
    if (!this.state.username) {
      console.info("Kein Username");

      return;
    }

    if (!this.state.value || this.state.value === "") {
      console.info("Keine Klinik");
      return;
    }

    var json = JSON.stringify({
      clinic_id: this.state.value,
      username: this.state.username,
    });

    try {
      AuthService.regist(json, "/api/clinic-admins/registration")
        .then((response) => {
          this.setState({ unauthorized: false });
          console.log("JSON: ", json);
          console.log("Response:", response.status);
          console.log(response.data);
          this.setState({ password: response.data });
          showAlertModal(
            "Ihr Passwort lautet: " +
              response.data +
              "\nIhr Account muss noch freigeschaltet werden."
          );
          //this.props.history.push("/admin");
        })
        .catch((error) => {
          reportRegistAdminError(error);
        });
    } catch (e) {
      console.error("Catch", e);
      return;
    }
  };

  /**React render method. */
  render() {
    return (
      <Container className="container d-flex align-items-center justify-content-center vh-100">
        <Card className="card login col-lg-6 col-md-10">
          <Card.Header className="card-header d-flex justify-content-center">
            <img
              src="img/rehacat.png"
              alt="Rehacat Logo"
              width="200px"
              height="auto"
            />
          </Card.Header>
          <Card.Body className="card-body p-5">
            <Card.Title className="text-dark mb-5">
              Registrierung Administrator
            </Card.Title>
            <Form noValidate validated onSubmit={this.handleRegist}>
              <Form.Group controlId="formGroupCode">
                <Form.Label className="">
                  Benutzername{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip className="tooltip">
                        Der Benutzername darf keine Leer- oder Sonderzeichen
                        enthalten.
                      </Tooltip>
                    }
                  >
                    <span className="mdi">
                      <RiInformationFill />
                    </span>
                  </OverlayTrigger>
                </Form.Label>

                <Form.Control
                  className="form-control"
                  required
                  type="username"
                  placeholder="Benutzername"
                  onChange={(text) => {
                    this.handleUsername(text);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte geben Sie Ihren Benutzernamen ein.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formGroupCode">
                <Form.Label className="row col-md-12">Klinikname </Form.Label>

                <Form.Select
                  className="form-control"
                  required
                  defaultValue=""
                  onChange={(event) => {
                    this.handleClinicId(event);
                  }}
                >
                  {this.state.clinics &&
                    this.state.clinics.length > 0 &&
                    [
                      {
                        name: "Bitte auswählen",
                        id: "",
                      },
                      ...this.state.clinics,
                    ].map((clinic, index) => {
                      return (
                        <option key={index} value={clinic.id}>
                          {clinic.name}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Bitte geben Sie den Kliniknamen ein.
                </Form.Control.Feedback>
              </Form.Group>

              <div className="d-grid gap-2">
                <Button className="" variant="primary" type="submit">
                  Registrieren
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default Registration;
